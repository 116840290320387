.tile {
  padding: 0 20px;
  display: block;
  min-height: 262px;
  border: 1px solid @color-border_light;
  text-decoration: none;
  border-radius: @border-radius-base;
  transition: all .3s;

  &:hover {
    text-decoration: none;
    transition: none;
    .theme("default", {
      background: @themeBrand-tabs-tab-hover-bgcolor;
      border-color: @themeBrand-color-corp-skyBlue;
    });
    .theme("black", {
      background: @themeBlack-tabs-tab-hover-bgcolor;
      border-color: @themeBlack-color-corp-skyBlue;
    });
    .theme("classic", {
      border-color: @themeClassic-color-corp-skyBlue;
    });

    i.fa {
      transition: none;
      .theme("default", {
        color: @themeBrand-color-corp-skyBlue;
      });
      .theme("black", {
        color: @themeBlack-color-corp-skyBlue;
      });
      .theme("classic", {
        color: @themeClassic-color-corp-skyBlue;
      });
    }
  }

  i.fa {
    margin: 68px 0 17px 0;
    display: block;
    font-size: @fontSize-base + 42;
    color: @btn-default-border;
    line-height: 1;
    transition: color .3s;
  }

  h4 {
    font-size: @fontSize-base + 3;
    color: @color-link;
    font-weight: normal;
  }
}
