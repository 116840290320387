.schemePicker {
  @brand: darken(#4FC8EC, 10%); /* brand @color-corp-skyBlue */
  @classic: #00adef; /* classic @color-corp-skyBlue */
  
  padding: 0;
  
  li {
    margin-right: .75em;
    display: inline-block;
    list-style-type: none;
    
    .schemePicker-element {
      position: relative;
      display: block;
      width: 56px;
      height: 28px;
    }
    
    .schemePicker-element_brand {
      background-color: @brand;
    }
    
    .schemePicker-element_classic {
      background-color: @classic;
    }
    
    .schemePicker-element_bw {
      background-color: desaturate(@classic, 100%);
    }
    
    .badge {
      position: absolute;
      left: auto;
      right: 0;
      top: -9px;
    }
  }
}