@font-face {
  font-family: 'mvsc';
  src: url('@{assets-path}fonts/mvsc/mvsc.eot');
  src: url('@{assets-path}fonts/mvsc/mvsc.eot') format('embedded-opentype'), url('@{assets-path}fonts/mvsc/mvsc.woff') format('woff'), url('@{assets-path}fonts/mvsc/mvsc.ttf') format('truetype'), url('@{assets-path}fonts/mvsc/mvsc.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'text-security-disc';
  src: url('@{assets-path}fonts/text-security/text-security-disc.eot');
  src: url('@{assets-path}fonts/text-security/text-security-disc.eot?#iefix') format('embedded-opentype'),
      url('@{assets-path}fonts/text-security/text-security-disc.woff') format('woff'),
      url('@{assets-path}fonts/text-security/text-security-disc.ttf') format('truetype'),
      url('@{assets-path}fonts/text-security/text-security-disc.svg#text-security') format('svg');
}

@font-face {
	font-family: Gotham;
	src:url('@{assets-path}fonts/gotham/GothamSSm-Book_Web.woff2') format('woff2'),
		url('@{assets-path}fonts/gotham/GothamSSm-Book_Web.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro';
	src:url('@{assets-path}fonts/gotham/GothaProLig.woff2') format('woff2'),
		url('@{assets-path}fonts/gotham/GothaProLig.woff') format('woff');
	font-weight: 400;
	font-style: normal;
}


.fa.far {
  font-weight: 400;
}

.fa {
  &.fa-chrome,
  &.fa-firefox,
  &.fa-opera,
  &.fa-internet-explorer,
  &.fa-safari {
    font-family: "Font Awesome 5 Brands";
    font-weight: 400;
  }
}


[class^="fa-mvsc"], [class*=" fa-mvsc"] {
  font-family: mvsc;
}

.fa-mvsc-check:before {
	content: "\e600";
}
.fa-mvsc-ca:before {
	content: "\e601";
}
.fa-mvsc-vin:before {
	content: "\e602";
}

.fa-mvsc-copy:before {
	content: "\e603";
}

.fa-mvsc-autoefile:before {
	content: "\e604";
}

.fa-mvsc-voided:before {
	content: "\e605";
}

.fa-mvsc-filters:before {
	content: "\e606";
}

.fa-mvsc-ok:before {
  content: "\e607";
}

.fa-mvsc-plus {
  -webkit-font-smoothing : none;
}

.fa-mvsc-or:before {
  content: "\e900";
}

.fa-mvsc-delete:before {
  content: "\e901";
}

.fa-mvsc-plus:before {
  content: "\e902";
}

.fa-mvsc-register:before {
  content: "\e903";
}

.fa-mvsc-location:before {
  content: "\e904";
}

.fa-mvsc-wallet:before {
  content: "\e905";
}

.fa-mvsc-incomplete:before {
  content: "\e906";
}

.fa-mvsc-received:before {
  content: "\e907";
}

.fa-mvsc-fedex:before {
  content: "\e908";
}

.fa-mvsc-audit:before {
  content: "\e909";
}

.fa-mvsc-plate:before {
  content: "\e90a";
}

.fa-mvsc-dollar:before {
  content: "\e90b";
}


.fa-mvsc-updateInsurance:before {
  content: "\e910";
}

.fa-mvsc-updateSmog:before {
  content: "\e90c";
}

.fa-mvsc-shipToLocation:before {
  content: "\e90d";
}

.fa-mvsc-plateSticker:before {
  content: "\e90e";
}

.fa-mvsc-rdf:before {
  content: "\e90f";
}

.fa-mvsc-reconciled:before {
  content: "\e911";
}

.fa-mvsc-iconFailed:before {
  content: "\e912";
}

.fa-mvsc-docFailed:before {
  content: "\e913";
}

.fa-mvsc-clearRdf:before {
  content: "\e914";
}

.fa-mvsc-cars:before {
  content: "\e915";
}

// !TODO this is a temporary solution while Font Awsom is not updated to 5 PRO
.fa-file-import:before {
  content: "";
  display: block;
  width: 13px;
  height: 13px;
  background: url('@{assets-path}img/icon-file-import_temp.svg');
  left: -1px;
  bottom: -1px;
  position: relative;
}



