// fly
.pageWrapper_overview #fly-container {
    min-height: calc(100vh - @solutionNavHeight - @headerHeight - @footerHeight);
}

#fly-container {
    position: relative;
    min-height: calc(100vh - @solutionTabHeight - @solutionNavHeight - @headerHeight - @footerHeight);

    &.fly-container_collapsed {
        #fly-sideBarParent {
            overflow: hidden;
            width: 0;
        }

        #fly-sideBar {
            width: 0 !important;
            opacity: 0;

            #fly-sideBar-InnerWrapper {
                //margin-top: 75px;
                transform: translateY(150px);
            }
        }

        #fly-contentArea {
            width: 100%;
        }
    }

    #fly-actionsBar+.row,
    mvsc-fly-actions-bar #fly-actionsBar+.row,
    mvsc-fly-actions-bar+.row,
    mvsc-fly-actions-bar2+.row {
        padding-top: 41px;
    }

    &>h1 {
        margin-top: 3px;
        margin-bottom: 26px;
    }

    &.container {

        .blog {
            margin: -20px;
        }
    }
}

#fly-sideBarParent {
    position: relative;
    // overflow: hidden;
    padding-left: 0;
    transition: width 500ms ease-in-out;

    #fly-sideBar {
        // overflow: hidden;
        margin-left: 15px;
        padding-top: 0;
        opacity: 1;
        transition: width 500ms ease-in-out, opacity 350ms ease-in-out;
        //transition: opacity 350ms ease-in-out;

        &.fly-sideBar_flying {
            position: fixed;
            top: 133px;
        }

        #fly-sideBar-InnerWrapper {
            transition: transform 350ms ease-in-out;
        }
    }
}

#fly-contentArea {
    background-color: #fff;
    transition: width 500ms ease-in-out;
}

#fly-actionsBar {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    &.fly-actionsBar_flying {
        position: fixed;
        padding-left: @margin-app;
        padding-right: @margin-app;

        // header has @zindex-navbar-fixed,
        // so we need to position actionsBar on a lower level
        // because of dropdowns in header.
        // also we apply z-index only when it is time to fly
        z-index: @zindex-navbar-fixed - 1;
    }

    &>.container {
        position: relative;
        padding: 0 0 7px 0; // 7px

        &>.container {
            height: 61px;
            padding-bottom: 0;
            background-color: #fff;
        }
    }

    #fly-actionsBar-shadow-overflow {
        position: absolute;
        z-index: 0;
        left: 0;
        right: 0;
        bottom: 0;

        #fly-actionsBar-shadow {
            position: relative;
            overflow: hidden;
            height: 9px;
            opacity: 0;

            &:after {
                position: absolute;
                top: -7px;
                bottom: 0;
                left: -10px;
                right: -10px;
                content: '';
                height: 9px;
                box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.2);
            }
        }
    }

    .list-inline {
        margin-bottom: 0;

        .fa-inbox-in,
        .fa-undo {
            font-size: 16px;
            transform: translateY(2px);
        }
    }

    // Negative margin for forecast in actionsBar context in schedule
    .forecastContainer {
        margin-top: -8px;
        margin-right: 115px;
        margin-bottom: -5px;
    }

    // Collapsable column with filters trigger
    .col-xs-2 {
        transition: width 500ms;
    }
}

#fly-plus {
    display: none;
    position: absolute;
    top: 44px;
    left: 100%;
    right: 0;

    .animation(fadeIn .25s);

    a {
        position: relative;
        display: block;
        width: 14px;
        height: 14px;
        border-radius: 1px;

        .theme("default", {
            background: @themeBrand-color-corp-skyBlue;
        });

        .theme("black", {
            background: @themeBlack-color-corp-skyBlue;
        });

        .theme("classic", {
            background: @themeClassic-color-corp-skyBlue;
        });

        &:before,
        &:after {
            position: absolute;
            left: 50%;
            top: 50%;
            display: block;
            width: 6px;
            height: 2px;
            content: '';
            background: #fff;
            .animation(rotateIn .8s);
        }

        &:before {
            margin-left: -3px;
            margin-top: -1px;
        }

        &:after {
            margin-left: -1px;
            margin-top: -3px;
            width: 2px;
            height: 6px;
        }
    }

    &.fly-plus_show {
        display: block;
    }

    &.fly-plus_rotateOut {
        a {
            &:before,
            &:after {
                .animation(rotateOut 1.2s);
            }
        }
    }
}

// Hidden container inserted in DOM by js
// we need it for calculating ethalon col-xs-2 width at any moment
#fly-ethalonContainer {
    height: 0;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
}
