// infobar
.infobar-wrapperFixed {
  position: fixed; 
  top: 50%; 
  right: 40px; 
  transform: translateY(-50%);
  
  &_noMarginRight {
    right: 2px;
  }
}

.infobar-wrapperRelative {
  position: relative;
}

.infobar.nav {
  display: block;
  width: 40px;
  margin: 0;
  padding: 13px 0 9px 0;
  background: @white;
  text-align: center;
  box-shadow: 0 1px 6px rgba(0,0,0, 0.25);
  border-radius: 20px;
  box-sizing: border-box;
  transition: all 150ms ease-in-out;
  
  .infobar-item {
    @infobar-bg-regular:  darken(@btn-default-bg, 8%);
    @infobar-bg-success:  @color-status-success;
    @infobar-bg-danger:   @color-status-danger;
    
    a {
      position: relative;
      display: block;
      margin:0 0 4px 7px;
      width: 26px;
      height: 26px;
      cursor: pointer;
      transition: all 150ms ease-in-out;
    
      &:hover,  &:focus {
        background: none;
      }
    
      &:before {
        position: absolute;
        top: 7px;
        left: 7px;
        content: '';
        width: 12px;
        height: 12px;
        display: block;
        background: @infobar-bg-regular;
        border-radius: 50%;
        border-color: transparent;
        transition: all 150ms ease-in-out;
      }
      
      &:hover:before {
        background: darken(@btn-default-bg, 16%);
      }
    }

    &.infobar-item_success a:before {
      background: @infobar-bg-success;
    }
    
    &.infobar-item_danger a:before {
      background: @infobar-bg-danger;
    }
    
    &.active a:before {
      top: 4px;
      left: 4px;
      width: 18px;
      height: 18px;
      background: @white;
      border: 2px solid @color-status-info;
      //background: @infobar-bg-regular;
      //border: 2px solid @infobar-bg-regular;
      cursor: default;
    }
    
    &.active.infobar-item_success a:before {
      background: @infobar-bg-success;
      border-color: @infobar-bg-success;
    }
    
    &.active.infobar-item_danger a:before {
      background: @infobar-bg-danger;
      border-color: @infobar-bg-danger;
    }
    
  }
}

.infobar-btn {
  position: absolute;
  bottom: -60px;
  left: 0;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  padding: 0;
  margin-top: 13px;
  border-radius: 50%;
  font-size: 18px;
  box-shadow: 0 1px 6px rgba(0,0,0, 0.25);
  z-index: 1;
}

.infobar-wrapper {
  position: absolute;
  top: 0;
  right: -10px;
    
  .infobar {
    position: fixed;
  }
}

.infobar-animate-submit {
  animation: downhill_1 .3s ease-in-out 1 forwards;
}

.infobar-animate-fieldset {
  animation: shake2 linear 1s;
  animation-iteration-count: 1;
  transform-origin: 50% 50%;
}

.infobar-animate-formGroup {
  input,
  textarea,
  select { // select -- only for prototype's purposes 
    .animation(~'pulse .5s, makeYellow 3s');
  }
  
  .select2-container {
    .animation(pulse .5s);
    
    .select2-choice {
      .animation(makeYellow 3s);
    } 
  }
}

// basic adaptive

// adaptive infobar mixin
.infobar-adaptive {
  .infobar-wrapperFixed {
    position: absolute; 
    right: -13px;
    top: 0;
    bottom: 0;
    transform: none;
    
    width: 26px;
  }
  
  .infobar-wrapperRelative {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .infobar.nav {
    width: 26px;
    padding: 7px 0 3px 0;
    border-radius: 13px;
    transition: all 150ms ease-in-out;
    
    .infobar-item {
      a {
        margin:0 0 2px 4px;
        width: 18px;
        height: 18px;
        transition: all 150ms ease-in-out;

        &:before {
          position: absolute;
          top: 3px;
          left: 3px;
          content: '';
          width: 12px;
          height: 12px;
          transition: all 150ms ease-in-out;
        }
      }
      
      &.active a {
      
        &:before {
          top: 1px;
          left: 1px;
          width: 16px;
          height: 16px;
        }
      }
    }
  }
  
  .infobar-btn {
    bottom: -36px;
    width: 26px;
    height: 26px;
    line-height: 26px;
    font-size: 16px;
  }
}

@media all and (max-width: 1305px) {
  .infobar-adaptive();
}

//adaptive for 1600+ width
@media all and (max-width: 1680px) {
  .responsive {
    .infobar-adaptive();
  }
}