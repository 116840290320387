// keep the scroll on the body, since it affects
// the container positioning when there is scroll
//.modal-open {
//  overflow: auto !important;
//}

.modal {

    // fix the modals when in the intro
    &.introjs-fixParent {
        position: absolute;

        &.in .modal-dialog {
            transform: none !important;
        }
    }

    .modal-content {
        border: none;
    }

    &.bundles {
        opacity: 1;

        .modal {
            &-dialog {
                max-width: 100%;
                transform: none;
                margin: 0;
            }

            &-content {
                background: none;
                position: fixed;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;

                h1 {
                    .ui-select-modal {
                        display: inline-block;
                        min-width: 285px;
                        vertical-align: middle;

                        .ng-clear-wrapper {
                            display: none;
                        }

                        &.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
                            box-shadow: none;
                            border-color: #ccc;
                        }

                        .ng-arrow-wrapper {
                            position: absolute;
                        }
                    }
                }
            }
        }

        .nav-tabs_mvsc-wrapper {
            font-size: 16px;
            margin: 0 -20px;
            padding: 0 20px;
            text-align: center;
            border-bottom: 1px solid #e6e6e6;
            margin-bottom: 30px;

            .nav-item {
                display: inline-block;
                margin-bottom: -1px;

                .nav-link {
                    margin-right: 0;
                    padding: 10px 15px;
                    line-height: 1.42857143;
                    border: none;
                    border-top: 3px solid transparent;

                    &.active {
                        color: #555;
                        background-color: #fff;
                        border-top: 3px solid #21bae7;
                        border-left: 1px solid #e6e6e6;
                        border-right: 1px solid #e6e6e6;
                    }
                }
            }
        }

        mvsc-show-more3 {
            display: block;
            margin-bottom: 18px;
        }

        clear-link .form-control-reset {
            right: 0;
        }
    }
}

.modal_noscroll {
    // Hide the overflow-y for default modals
    // do not show scroll bar
    overflow-y: auto !important;
}

.modal-dialog {
    width: @modal-lg;
    margin: 20px auto;
}

.modal-header {
    padding: @modal-title-padding @modal-inner-padding 0;
    border-bottom-width: 0;

    .close {
        position: absolute;
        right: 15px;
        top: 5px;
        z-index: 1;

        font-size: 47px;
        font-weight: 300;
        margin-top: 0;

        svg {
            display: none;
        }
    }
}

.modal-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 1.42857143;
    margin-bottom: 20px;
}

.modal-footer {
    text-align: left;
    margin-top: 0;
    padding: @modal-title-padding @modal-inner-padding;
    border-top-color: @hr-border-modal;
}

.modal-lg {
    width: @modal-lg;
    //margin: 20px auto;
}

.modal-md {
    width: @modal-md;
    //margin: 20px auto;
}

.modal-sm {
    width: @modal-sm;
    //margin: 20px auto;

    .customizable-options {
        margin-bottom: 20px;

        .options-option_xl {
            margin-bottom: 20px;
        }
    }
}

.modal-500 {
  .modal-dialog {
    width: 500px;
  }
}

.modal-width_685 {
    .modal-dialog {
        width: 685px !important;
    }
}

.modal_840 {
    .modal-dialog {
        width: 840px;

        add-customer-tab {
            .modal-actions {
                padding-bottom: 15px;

                .btn {
                    padding: 10px 20px;
                }

                .cancel {

                    &::before {
                        display: none;
                    }
                }
            }
        }
    }
}

.modal-body {
    padding: @modal-title-padding @modal-inner-padding @modal-inner-padding @modal-inner-padding;

    hr {
        margin-left: -@modal-inner-padding;
        margin-right: -@modal-inner-padding;
    }
}

@modal-margin: 20px;
@modal-padding: 15px;

.fullScreenModal {

    .modal-dialog {
        min-width: 970px - @modal-margin * 2;
        max-width: 1170px - @modal-margin * 2;
        width: 100% !important; // important is required for IE8
        margin: @modal-margin auto;
    }

    .modal-body {
        // to make an equal padding for a content col
        padding: @modal-padding;
    }

    .modal-row {
        margin-left: -@modal-padding;
        margin-right: -@modal-padding;
    }

    .modal-row-sidebar {
        padding-right: 5px;
    }

    .modal-row-content {
        padding-right: @modal-padding;
        padding-left: 0;
    }

    // content with sidebar
    .modal-row-sidebar+.modal-row-content {
        padding-left: 0;
    }

    .modal-header {
        height: 62px;
        border-bottom-width: 0;
        padding-bottom: 0;
        background-color: @color-corp-lightGray;
        padding-left: @modal-padding;
        padding-right: @modal-padding;

        h3 {
            margin-top: 14px;
        }

        .nav-tabs {
            &>li:first-child {
                padding-left: @modal-padding;
            }

            h4 {
                border: 1px solid transparent;
            }
        }
    }

    .form-group_justify {
        .fa-exclamation-triangle {
            margin-left: 3px;
            margin-right: 4px;
        }
    }

    .modal-title {
        font-size: 21px;
        line-height: 42px;
    }

    .fullScreenModal-content-wrapper {
        // class to identify parent fullScreenModal-content slimScrollDiv
        margin-top: -@modal-padding;
        margin-bottom: -@modal-padding;
        margin-right: -@modal-padding;

    }

    .fullScreenModal-content {
        padding-right: @modal-padding;
        padding-left: @modal-padding; //15 px if not in sidebar
        padding-top: @modal-padding;
        padding-bottom: @modal-padding;
    }

    .fullScreenModal-sidebar {
        min-height: calc(100vh - 112px);
        max-height: calc(100vh - 112px);
        overflow: auto;
        margin-left: -(@modal-padding - 5px);

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgb(224, 227, 230);
            border-radius: 5px;
        }

        .nav {
            min-height: calc(100vh - 112px);

            a {
                padding-left: 9px;
            }
        }
    }

    .fullScreenModal-tabs {
        margin: 0 -15px;

        li {
            float: left;
        }

    }

    .scroll-modal {
        overflow-y: auto;
        padding-left: 15px;
        padding-right: 15px;
        margin-right: -15px;
    }

    .row-scroller {
        margin-left: 0;
        margin-right: 0;

        .modal-row-content {
            padding-right: 0;
        }
    }
}

.modal_json {

  .modal-dialog {
    min-width: 970px - @modal-margin * 2;
    max-width: 1170px - @modal-margin * 2;
    width: 100% !important;
    margin: @modal-margin auto;
  }

  .modal-body {
    padding: 23px 30px 30px;
  }

  .modal-header {
    height: 62px;
    padding: 23px 30px 0px;
    position: relative;

    &:after {
      content: "";
      display: block;
      height: 1px;
      position: absolute;
      left: 30px;
      right: 30px;
      bottom: 0;
      background-color: #E0E3E6;
    }

    .close {
      right: 18px;
    }

    h2 {
      margin: 0;
    }
}
}

.customscroll {
    padding: 0 !important;

    .fullScreenModal-content {
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgb(224, 227, 230);
            border-radius: 5px;
        }
    }

    >.row.modal-row {
        margin-left: 0;
        margin-right: 0;
    }

    .modal-row-content {
        padding-right: 0;
    }

    .scroller {
        height: 85vh;
        overflow-x: hidden;
        overflow-y: auto;
        padding-left: 15px;
        padding-right: 15px;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgb(224, 227, 230);
            border-radius: 5px;
        }
    }
}

//Base modal tabs style
.fullScreenModal-tabs {
    margin: 0 -@modal-padding;
    font-size: 16px;

    li {
        border-top: 3px solid transparent;

        a {
            margin-right: 0;
            padding-left: @modal-padding;
            padding-right: @modal-padding;
            border: none;
            border-radius: 0;
            line-height: 22px;
        }

        &.active {
            border-top: 3px solid;

            .theme("default", {
                border-top-color: @themeBrand-color-corp-lightBlue;
            });

            .theme("black", {
                border-top-color: @themeBlack-color-corp-lightBlue;
            });

            .theme("classic", {
                border-top-color: @themeClassic-color-corp-lightBlue;
            });
        }
    }

    .nav-item {
        border-top: none;

        margin-top: 3px;

        a {
            border: none;
        }

        a.active {
            border-top: 1px solid transparent;
            border-bottom: 1px solid transparent;
            border-left: 1px solid #ddd;
            border-right: 1px solid #ddd;

            &::before {
                content: '';
                position: absolute;
                left: -1px;
                bottom: 100%;
                height: 3px;
                width: calc(100% + 2px);

                .theme("default", {
                    background-color: @themeBrand-color-corp-lightBlue  !important;
                });

                .theme("black", {
                    background-color: @themeBlack-color-corp-lightBlue  !important;
                });

                .theme("classic", {
                    background-color: @themeClassic-color-corp-lightBlue  !important;
                });
            }
        }
    }
}

.modal_documentPreviewModal {

    .modal-dialog {
        margin-top: 0;
        margin-bottom: 25px;
    }

    .modal-content {
        border: 0;
    }

    .fullScreenModal-content {
        padding-bottom: 0;
    }

    .modal-body {
        padding: 15px 15px 0 0;
    }

    .modal-header {
        position: absolute;
        right: 20px;
        top: 15px;
        z-index: 2;
        min-height: 0;
        height: auto;
        padding: 0;
        background-color: transparent;
    }

    .entity {
        padding-left: 50px;
        padding-right: 50px;
    }

    .fullwidth+fieldset {
        margin-top: 20px;
    }

    .fullwidth {
        border-top: 1px solid @hr-border-modal;
        background-color: rgba(0, 0, 0, 0.015);
        margin-left: -70px;
        margin-right: -70px;
        margin-bottom: 0;
        margin-top: 0; // works in combination with .fullwidth + fieldset margin
        padding-left: 70px;
        padding-right: 70px;
        padding-top: 25px;
        padding-bottom: 25px;

    }

    .form-actions {
        padding-top: 25px;
        margin-bottom: 30px;
    }

    .form-actions.fullwidth {
        margin-bottom: 0px;
        padding-bottom: 30px;
    }

    // collapse margin and borders between .fullwidth sets
    .fullwidth+.fullwidth {
        border-top: none;
        padding-top: 10px;
        margin-top: 0;
    }

    label {
        margin-bottom: 3px;
    }

    .form-group {
        margin-bottom: 18px;
    }

    .entity-value {
        font-size: 14px;
    }
}

.modal_centered[style *="display: block"],
.modal_centered[style *="display: flex"],
.modal_centered_ngb.show {
    display: flex !important;
    flex-direction: column;
    justify-content: center;

    .modal-dialog {
        max-height: 100%;
    }
}

// TODO Review modal_confirmation
.modal_confirmation {
    z-index: @zindex-confirmation;

    .modal-dialog {
        width: 400px;
    }

    .modal-header {
        min-height: 0;
        padding: 0;
    }

    .modal-body {
        padding: 70px 50px 60px 50px;
    }

    .fa:not(.text-normal):not(.fa-calendar):not(.fa-times-circle) {
        margin-bottom: 20px;
        font-size: 55px;
        line-height: 1em;

        &:not(.text-success) {
            color: @pre-border-color;
        }
    }

    .alert .fa.text-normal {
        margin-right: 5px;
    }

    .form-group {
        margin-bottom: 20px;
    }

    .envelop-check {
        margin: -36px 0 -18px 0;
        transform: scale(0.5, 0.5);
    }

    h3,
    .modal-text {
        margin-bottom: 20px;
        font-size: @fontSize-base + 5;
        font-weight: 400;
    }

    small {
        display: block;
        margin-top: 20px;
    }

    &.modal_confirmation_noicon {
        .modal-dialog {
            width: 500px;
        }

        &_wide {
            .modal-dialog {
                width: 500px;
            }
        }

        .modal-body {
            padding: 50px;

            &.output_up {
                padding-top: 5px;
            }
        }

        h3,
        .modal-text {
            margin-bottom: 15px;
        }

        small {
            margin-top: -10px;
            margin-bottom: 15px;
        }
    }

    &.modal_messageTheDay {
        .modal-dialog {
            width: 900px;
        }
    }

    &.modal_640 {
        .modal-dialog {
            width: 640px;
        }
    }

    &.modal_840 {
        .modal-dialog {
            width: 840px;
        }
    }

    &.modal_980 {
        .modal-dialog {
            width: 640px;
        }
    }
}

.modal_sendToService {
    .modal-dialog {
        width: 900px;
    }
}

.modal_settings {

    .sendToServiceForm {
        padding-top: 3px !important;

        h2 {
            margin-bottom: 18px;
        }

        .checkbox,
        .radio {

            input+label,
            input~label {
                font-size: 13px !important;
            }
        }

        .radio {
            margin-bottom: 14px;

            +.radio {
                margin-top: 0;
            }

            &-spinner {
                display: inline-block;
                margin-left: 6px;
                transform: translateY(-1px);
            }
        }

        .separated {
            margin-bottom: 22px;
        }

        .preferences {
            margin-bottom: 17px;
        }

        .method {
            margin-left: 20px;

            mvsc-form-control2 .radio-inline label {
                font-size: 13px;
                line-height: 20px;
            }

            &-name {
                label {
                    font-size: 13px;
                }
            }

            &-group {
                &::after {
                    display: table;
                    content: "";
                    width: 100%;
                }

                .form-group {
                    margin-bottom: 0;

                    &.col-xs-5 {
                        width: 260px;
                        padding-right: 5px;
                    }
                }

                .radio-inline label {
                    font-size: 13px !important;
                }
            }
        }
    }

    .modal-dialog {
        width: 640px;
    }

    .modal-body {
        padding: 10px 50px 50px 50px;
    }

    .form-actions {
        padding-top: 26px;

        .form-actions__warning {
            top: 0;
            margin-top: -20px;
            margin-bottom: 20px;
        }
    }

    .preferences {
        margin-bottom: 37px;

        thead {
            th:not(:first-child) {
                padding-left: 15px;
            }
        }

        &+.form-actions {
            padding-top: 0;
        }
    }



    .well-sm {
        margin-bottom: 12px;
    }

    h2+.alert {
        margin-top: 18px;
    }
}

.modal_900 {
    .modal-dialog {
        width: 900px;
    }
}

.modal_validation {
    h2 {
        font-size: 27px;
        line-height: 37px;
        margin: 0 0 23px;
        padding: 30px 0 14px;
        border-bottom: 1px solid #E0E3E6;
    }

    h3 {
        font-weight: 400;
        font-size: 18px;
        line-height: 25px;
        margin-bottom: 17px;
    }

    p {
        margin-bottom: 12px;
        color: #999999;
    }

    .alert-warning {
        padding-right: 12px;
        padding-left: 41px;
        margin-bottom: 24px;
        position: relative;

        b {
            display: block;
            margin-bottom: 7px;
        }

        .fas {
            position: absolute;
            top: 15px;
            left: 17px;
        }
    }

    .form-actions {
        display: flex;
        align-items: center;
        border-top: 1px solid #E0E3E6;
        margin-top: 31px;
        padding-top: 40px !important;
    }

    .validation-radio-group {
        margin-bottom: 14px;
    }

    .radio label,
    .radio-inline label {
        margin-bottom: 12px;

        span {
            line-height: 1.1;
            position: relative;
            transform: none;
            top: 0;
            left: 0;
        }
    }
}

.modal_additionalFormFullscreen {
    .modal-header {
        min-height: 0;
        height: 0;
        padding-top: 0;
        padding-bottom: 0;

        background: transparent;

        h4 {
            display: none;
        }
    }

    h2 {
        margin-bottom: 23px;
        margin-top: 27px;
        padding-bottom: 19px;
    }

    .modal-body {
        padding-bottom: 42px;
    }

    .fullScreenModal-content-wrapper {
        margin-bottom: -42px;
        padding: 0 50px 0 35px;
    }


    .fullScreenModal-content {
        padding-bottom: 42px;
    }
}

.helpCenter {
    .modal-dialog {
        width: 100%;
        height: 100%;
        max-width: 100%;
        margin: 0;
    }
}

.modal_additionalFormCompact {

    h2 {
        margin-bottom: 25px;
        padding-bottom: 5px;
        //border-bottom: 1px solid @table-border-color;
    }

    .modal-dialog {
        width: 640px;
    }

    .modal-body {
        padding: 10px 50px 42px 50px;
    }

    .form-actions {
        margin-top: 25px;
        padding-top: 37px;

        &_borderTop {
            border-color: @table-border-color;
        }
    }
}

.modal_report {
    display: flex !important;
    flex-direction: column;
    justify-content: center;

    .modal {
        &-dialog {
            transform: none !important;
            transition: none !important;
            top: 0 !important;
            transform: none !important;
            width: 640px;
            margin: 0 auto;

            .scroller {
                // height: auto;
                // max-height: calc(100vh - 100px);
                padding: 15px 25px 35px;
                // overflow-y: auto;
                // overflow-x: hidden;
                // &::-webkit-scrollbar {
                //   width: 5px;
                // }
                // &::-webkit-scrollbar-thumb {
                //   background-color: rgb(224, 227, 230);
                //   border-radius: 5px;
                // }
            }
        }

        &-header {
            padding: 38px 50px 10px;

            .modal-title {
                border-bottom: 1px solid #E0E3E6;
                padding-bottom: 14px;
            }
        }

        &-body {
            padding: 0 25px 35px;
        }

        &-content {
            .customizable-options.null {
                margin-bottom: 0;
            }

            mvsc-report-filter-date-range-picker2 {
                display: block;

                .customizable-options__add-input {
                    width: 270px;
                    margin-bottom: 10px;
                }

                &::after {
                    display: table;
                    content: "";
                    width: 100%;
                }

                .customizable-options__add-control {
                    // margin-bottom: 21px;
                    margin-bottom: 1px;
                }
            }

            .form-group_noMargin {
                padding-top: 12px;
            }

            div:has(mvsc-retail-reports-il-inventory-outstanding-dialog-filter) .customizable-options {
                margin-bottom: 8px;
            }

            div:has(mvsc-retail-reports-il-inventory-outstanding-dialog-filter) + .customizable-options {
                margin-bottom: 7px;

                .form-group {
                    margin-bottom: 0;
                }
            }

            .form-group {
                &_top {
                    margin-top: 7px;
                }
            }

            mvsc-report-filter-multiple-lookup2 {
                display: block;

                .options-option {
                    margin-bottom: 10px;
                }
            }

            mvsc-form-control2 {
                .form-group {
                    margin-bottom: 5px;

                    .ng-select {
                        height: auto;

                        .ng-select-container {
                            height: auto;
                            min-height: 31px;
                            line-height: 0;

                            .ng-value-container {
                                padding: 0;
                                min-height: 29px;
                                align-items: flex-start;

                                .ng-value {
                                    font-size: 13px;
                                    line-height: 0;
                                    background-color: #eee;
                                    border: 1px solid #D8D8D8;
                                    margin: 4px 1px 0 4px;
                                    cursor: default;

                                    &-label {
                                        line-height: 17px;
                                    }

                                    &-icon {
                                        float: right;
                                        border-right: none;
                                        font-size: 0;
                                        line-height: 0;
                                        padding-bottom: 0;

                                        &::before {
                                            content: "\f00d";
                                            font-family: "Font Awesome 5 Pro";
                                            font-weight: normal;
                                            font-size: 11px;
                                            line-height: 18px;
                                        }

                                        &:hover {
                                            background: none;
                                        }
                                    }

                                    &:hover {
                                        border-color: #999;
                                    }
                                }

                                .ng-input {
                                    height: 29px;
                                    display: flex;
                                    align-items: center;
                                    padding: 0;

                                    >input {
                                        padding-left: 10px;
                                        margin: 0;

                                        &:hover {
                                            cursor: text;
                                        }
                                    }
                                }

                                .ng-placeholder {
                                    top: 6px;
                                    line-height: 18px;
                                    padding-left: 10px;
                                }
                            }

                            &:hover {
                                cursor: text;
                            }

                            &.ng-has-value {
                                background: #fff !important;
                            }
                        }

                        &.ng-select-bottom {
                            .ng-value-container {
                                .ng-placeholder {
                                    padding-left: 8px;
                                }
                            }
                        }

                        &.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
                            border-color: #D8D8D8;
                            box-shadow: none !important;
                        }

                        .ng-clear-wrapper,
                        .ng-arrow-wrapper,
                        .ng-select-container:after {
                            display: none;
                        }

                        // select dropdown
                        .ng-dropdown-panel {
                            .ng-dropdown-panel-items {
                                .ng-option {
                                    span {
                                        font-weight: normal;
                                    }

                                    &.ng-option {
                                        &-selected {
                                            background: none;
                                            color: #202020;
                                        }

                                        &-marked {
                                            background: #eee;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    .help-block {
                        margin-top: 7px;
                    }
                }
            }

            mvsc-report-export-file-format2 {
                display: block;

                .col-xs-6 {
                    width: auto;

                    .checkbox.checkbox_button {
                        margin-bottom: 0;

                        label {
                            padding-left: 41px;
                            padding-right: 41px;
                        }
                    }
                }
            }
        }

        &-footer {
            padding: 36px 0 5px;
            margin-left: 25px;
            margin-right: 25px;
        }
    }
}

.modal_inventory {
    .modal-dialog {
        width: auto;
    }
}

/* Modal over modal styles */
.modal_overModal {
    z-index: @zindex-confirmation;
}

.modal-backdrop_overModal {
    display: none;
}

.modal_overModal.in+.modal-backdrop_overModal {
    display: block;
    z-index: @zindex-confirmation - 1;
    opacity: .5;
}

.modal .assign-inventory.separated,
.modal .change-address-type.separated,
.modal .issue-speciality-plate.separated,
.modal .complete-mannualy-fieldset.separated {
    margin-bottom: 15px;
    margin-top: 15px;
    padding-top: 10px;
    border-color: @table-border-color;
    border-top: 1px solid @table-border-color;
}

.modal_wide {

    &.modal_wide_xl {

        .modal-dialog {
            width: 1010px;
        }
    }

    .modal-dialog {
        width: 950px;
    }

    &.modal_settings {

        .alert {
            margin: 20px 0 37px 0;
        }
    }
}

.modal-backdrop {
    transition: none !important;
}

.modal.modal_transition-width .modal-dialog {
    transition: width .2s, transform .3s;
}

.modal-header-pager {
    .horisontal-align;
    top: -15px;
    height: 30px;
    line-height: 30px;
    padding: 0 24px;
    border-radius: 15px;
    display: inline-block;
    color: @white;
    background: @color-status-info;
    z-index: 1;
}

.modal_processing {
    .modal-dialog {
        width: 540px;
    }

    h2 {
        margin-bottom: 40px;
    }

    .alert.alert-danger {
        margin-top: -15px !important;
        margin-bottom: 30px !important;
    }

    h4 {
        font-weight: 500;
        font-size: 14px;
        margin-top: 8px;
        margin-bottom: 0;
    }

    .table {
        margin-top: 11px;
        margin-bottom: 31px;
    }

    .table:last-child {
        margin-bottom: 0;
    }

    .mvscSpinner {
        position: relative;
        right: -12px;
        bottom: -5px;
        margin-bottom: -5px;
        margin-top: -5px;
    }

    .modal_processing__doc-actions {
        margin-top: 4px;

        &.col-xs-3 {
            padding-left: 1px;
        }

        .btn {
            margin-left: 2px;
        }
    }

}

.iframeModal {

    .modal-dialog {
        height: 100%;
    }

    .modal-content {
        position: absolute;
        top: 20px;
        left: 0;
        right: 0;
        bottom: 20px;
        padding-top: 60px;
    }

    .modal-header {
        position: absolute;
        height: 60px;
        top: 0;
        left: 0;
        right: 0;
        background: #FFF;
    }
}

.reviewModalScreen {

    .modal-content {
        background-color: inherit;
    }
}

//sub-fieldset
.modal {
    .sub-fieldset {
        margin-bottom: 20px;
    }
}

.fieldset.separated.preferences {
    padding-bottom: 15px;
}

//gallery
.modal {
    .carousel {
        .carousel-indicators {
            bottom: -50px !important;

            li {
                margin: 0 5px;
            }
        }

        .right.carousel-control,
        .left.carousel-control {
            background: none;

            &:after {
                position: absolute;
                top: 50%;
                font-family: "Font Awesome 5 Pro";
                font-size: 50px;
            }
        }

        .right.carousel-control:after {
            content: "\f105";
            right: -50px;
        }

        .left.carousel-control:after {
            content: "\f104";
            left: -50px;
        }
    }
}

.modal.pdf {

    // pdf fullscreen
    &.modal_pdf {
        background: #f0f0f0;
        height: 100%;
        max-width: 100%;
        margin: 0;
        z-index: 1070;

        .pdfViewer {
            background: #f0f0f0;
        }

        .modal-content {
            height: 100%;
            margin: 0 auto;
            box-shadow: none;
        }

        .modal-dialog {
            height: 100%;
            top: 0;
            transform: none;
            max-width: 100%;
            margin: 0;

            .pdf-viewer.pdf-viewer_lightbox {
                height: calc(100vh - 100px);
                min-height: 700px;
            }

            .pdf-viewer_actions {
                height: 100px;
                background-color: #fff;
                box-shadow: 0px -2px 8px rgba(0, 0, 0, 0.1);
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                z-index: 10;

                span {
                    font: 400 17px/20px 'Roboto';
                    color: #999;
                }

                .btn {
                    font: 500 17px/18px 'Roboto';

                    &-success,
                    &-default {
                        padding: 12px 35px
                    }

                    &-link {
                        font-weight: 500 !important;

                        &:hover,
                        &:focus {
                            text-decoration: none;
                        }
                    }
                }

                .mvscSpinner {
                    display: flex;
                    align-items: center;
                    top: 0;
                    margin-right: 0;

                    i {
                        font-style: normal;
                    }
                }
            }
        }
    }

    &.modal_noclose {
        .mfp-close.close {
            display: none;
        }
    }

    .modal-dialog {
        .vertical-align;
        transition: none !important;

        .pdf-viewer.pdf-viewer_lightbox {
            width: 100% !important;
        }
    }

    &.modal_navigation {
        background: #F0F0F0;

        .modal-content {
            background: #F0F0F0;
        }
    }

    .modal-dialog {
        .vertical-align;
        transition: none !important;

        .pdf-viewer.pdf-viewer_lightbox {
            width: 100% !important;
        }
    }

    .mfp-close.close {
        position: absolute;
        top: 0;
        right: 0;
        background-color: transparent;
        color: #7F7F7F;
        opacity: 1;
        z-index: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 17px;
        height: 52px;
        width: 52px;
        transition: background-color .2s;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            border-left: 1px solid #e0e3e6;
        }

        &::after {
            content: '';
            position: absolute;
            bottom: -1px;
            right: 0;
            left: 0;
            width: 100%;
            border-bottom: 1px solid #e0e3e6;
        }

        &:hover {
            background-color: #f8f8f8;
        }

        svg {
            fill: currentColor;
            height: 100%;
            width: 100%;
        }
    }
}

.modal.owners-lookup {
    .tab-pane {
        margin-top: 30px;
    }
}

.modal-terminal {
    small {
        font-size: 12px;
    }

    .checkbox {
        margin-top: 0;
    }
}

mvsc-confirmation5 {
    h3.modal-title {
        font-weight: 300;
        font-size: 29px;
        line-height: 1;
        padding: 44px 0 10px;
        margin: 0 50px 0;
        border-bottom: 1px solid #e0e3e6;
    }

    .modal-body.output_up {
        padding-top: 5px;
    }
}

.modal_reciprocity {

    &.mvscModal h1 {
        margin-top: 5px;
        margin-bottom: 0;
    }

    .modal {

        &-dialog,
        &-content {
            background: #f5f5f5;
            height: 100vh !important;
            overflow: hidden;
        }
    }

    .reciprocity {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: #f5f5f5;
        max-height: 100vh;
        overflow: hidden;

        .container {
            width: 1170px;
        }

        &-head {
            flex: 0 0 auto;
            background: #f5f5f5;
            border-bottom: 1px solid #e6e6e6;

            .container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 70px;
                padding: 0;

                &::before,
                &::after {
                    display: none;
                }
            }

            .close {
                font-size: 37px;
                transform: translateY(-3px);
            }
        }

        &-logo {
            display: block;
            width: 59px;
            height: 26px;
            background: url('@{assets-path}img/vitu-logo.svg') no-repeat;
        }

        &-body {
            flex: 1;
            height: 100%;
            overflow-y: auto;
        }

        &-content {
            background-color: #fff;
            width: 1172px;
            min-height: 100%;
            padding-top: 28px;
            padding-bottom: 20px;
            border-style: solid;
            border-width: 0 1px;
            border-color: #e6e6e6;
            margin-left: auto;
            margin-right: auto;
            position: relative;

            .spinner-containes {
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
                z-index: 1;
            }
        }
    }
}
