.pageActions {

  .list-inline {
    li:not(.dropdown-menu li) {
      padding-right: 2px;
      padding-left: 5px;
    }
  }

  .btn-white {
    
    .fa {
      color: darken(@color-pale, 10%);
      
      &:before {
        position: relative;
      }
    }
    
    .fa.fa-trash {
      font-size: @font-size-base + 3;
      
      &:before {
        bottom: -1px;
        margin: 0 2px;
      }
    }
    
    .fa.fa-location-arrow {
      margin: 0 1px;
      font-size: @font-size-base + 5; 
      
      &:before {
        bottom: -2px;
        left: -1px;
      }
    }
    
    .fa.fa-mvsc-received, 
    .fa.fa-mvsc-incomplete {
      font-size: @font-size-base + 3; 
      
      &:before {
        bottom: -2px;
        left: -1px;
      }
    }
    
    .fa.fa-mvsc-wallet {
      font-size: @font-size-base + 3; 
      
      &:before {
        bottom: -2px;
      }
    }
    
    .fa-mvsc-location:before {
      right: -1px;
      bottom: -2px;
      margin-right: 1px;
    }
    
    .fa-download:before {
      bottom: -2px;
    }
    
    .fa-calendar-check-o:before {
      margin: 0 1px;
      bottom: -1px;
    }
  
    .fa-mvsc-register:before, .fa-paste:before {
      bottom: -2px;
      right: -1px;
      margin: 0 1px;
    }
    
    .fa-paste:before {
      bottom: -1px;
    }
    
    .fa-dashboard {
      font-size: @font-size-base + 3;
      
      &:before {
        bottom: -1px;
      }
    }
    
    .fa-pencil {
      font-size: @font-size-base + 3;
      
      &:before {
        margin: 0 1px;
        bottom: -1px;
      }
    }
    
    .fa-dollar {
      font-size: @font-size-base + 3;
      
      &:before {
        margin: 0 4px 0 3px;
        bottom: -1px;
      }
    }
    
    .fa-mvsc-dollar {
      font-size: @font-size-base + 3;
      
      margin-top: -1px;
      
      &:before {
        margin: 0 0 0 0;
        bottom: -3px;
      }
    }
    
    .fa-file-text {
      
      &:before {
        bottom: -1px;
      }
    }
    
    .fa-mvsc-updateInsurance {
      font-size: @font-size-base + 3;
      
      margin-top: -1px;
      
      &:before {
        margin: 0 0px;
        bottom: -3px;
      }
    }
    
    .fa-mvsc-updateSmog {
      font-size: @font-size-base + 7;
      
      margin-top: -4px;
      
      &:before {
        margin: 0 -2px 0 -2px;
        bottom: -5px;
      }
    }
    
    .fa-mvsc-shipToLocation {
      font-size: @font-size-base + 3;
      margin-top: -2px;
      
      &:before {
        margin: 0 0 0 0;
        bottom: -3px;
      }
    }
    
    .fa-mvsc-plateSticker {
      font-size: @font-size-base + 5;
      margin-top: -3px;
      
      &:before {
        margin: 0 -1px 0 -1px;
        bottom: -4px;
      }
    }
    
    .fa-mvsc-rdf, .fa-mvsc-clearRdf {
      font-size: @font-size-base + 5;
      margin-top: -2px;
      
      &:before {
        margin: 0 -2px 0 -1px;
        bottom: -4px;
      }
    }
    
    .fa-mvsc-reconciled {
      font-size: @font-size-base + 2;
      margin-top: -2px;
      
      &:before {
        margin: 0 -1px 0 -1px;
        bottom: -2px;
      }
    }
    
    
    &[disabled] {
      opacity: 1;
      pointer-events: auto;
      cursor: default;
      
      border-color: fade(@btn-default-border, 65%); 
      
      .fa {
        color: @btn-default-border;
      }
    }
  }
}