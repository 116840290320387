.row {

  .range-column {
    position: relative;
  }

  .range-column:after {
    content: '';
    display: block;
    position: absolute;
    right: -19px;
    bottom: 15px;
    width: 8px;
    height: 1px;
    background: @color-muted;
  }
  
  &.row_tight {
    
    .range-column:after {
      right: -4px;
    }
  }
}