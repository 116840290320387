// mvsc-flexibleContext -- allows to change a label (and context) using label as a trigger
// we are going to use such a control in filters (keyword + date)
.mvsc-flexibleContext {
    position: relative;
}

// pretty same style we use for labels in mvsc-floatableLabel control
// going something to change? don't forget about consistency
.mvsc-flexibleContext-labels {
    position: absolute;
    left: 5px;
    top: -9px;
    z-index: 2;

    margin-bottom: 0;

    width: 90%;

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    font-size: @fontSize-base - 2 !important;
    line-height: 1em;
    padding: 2px 0;
}

.mvsc-flexibleContext-labels-wrapper {
    position: relative;
}

.mvsc-flexibleContext-labels-inner {
    position: relative;
    width: 100%;

    >.mvsc-flexibleContext-labels-label {
        display: none;
        position: relative;
        transition: .6s ease-in-out left;
    }

    >.mvsc-flexibleContext-labels-label span {
        display: inline-block;
        background: #fff;
        padding: 0 6px;
    }

    >.mvsc-flexibleContext-labels-label a {
        padding-bottom: 3px;
        line-height: 1;

        &:focus-visible {

            .theme("default", {
                box-shadow: 0 0 0 2px #21BAE7;
                border-radius: 2px
            });
        }
    }

    >.active,
    >.next,
    >.prev {
        display: block;
    }

    >.active {
        left: 0;
    }

    >.next,
    >.prev {
        position: absolute;
        top: 0;
        width: 100%;
    }

    >.next {
        left: 100%;
    }

    >.prev {
        left: -100%;
    }

    >.next.left {
        left: 0;
    }

    >.active.left {
        left: -100%;
    }

}
