.keyboard-shortcuts {
  font-size: @font-size-base + 1;
  
  &.fieldset {
    margin-bottom: 15px;
    margin-top: 15px;
    padding-top: 29px;
    border-top: 1px solid #e0e3e6;
  }
  
  .keyboard-shortcuts__disabled,
  .keyboard-shortcuts__disabled kbd  {
    color: fade(@gray-dark, 30%);
  }
  
  .keyboard-shortcuts__disabled kbd {
    background: fade(#f1f1f1, 70%);
  }
  
  .keyboard-shortcuts__kbd-wrapper {
    display: inline-block;
    width: 75px;
    margin-right: 5px;
  }
  
  
  h4 {
    margin-bottom: 13px;
    font-size: @font-size-base + 1;
    font-weight: 400;
  }
  
  p {
    margin-bottom: 8px;
  }
  
  kbd {
    display: inline-block;
    margin-right: 2px;
    
    &.keyboard-shortcuts__enter-key {
      margin-right: 27px;
    }
    
    &.keyboard-shortcuts__arrow-key {
      font-family: Arial;
    }
  }
}