.chart text {
    font-size: 11px;
}

.chart-tooltip {
    display: none;
    position: fixed;
    font-size: 0.75em;
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 2px;
    box-shadow: 0 1px 7px rgba(0, 0, 0, .2);
    width: 130px;
    height: 54px;
    text-align: center;
    padding: 9px 14px;

    &__title {
        color: #333;
        font-size: 14px;
        display: block;
        line-height: 1.2;
        margin-bottom: 4px;
    }

    &__desc {
        font-size: 11px;
        color: #999999;
    }

    & > .arrow,
    & > .arrow:after {
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }

    & > .arrow {
        border-width: 9px;
        left: 50%;
        margin-left: -9px;
        border-bottom-width: 0;
        border-top-color: #a5a5a5;
        border-top-color: rgba(0, 0, 0, 0.2);
        bottom: -9px;

        &:after {
            border-width: 8px;
            content: " ";
            bottom: 1px;
            margin-left: -8px;
            border-bottom-width: 0;
            border-top-color: #fff;
        }
    }

}