// Dropdown menu
.dropdown-menu {
  min-width: 104px;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: @zindex-dropdown;
  display: none;
  float: left;
  margin: 0px;
  list-style: none;
  text-shadow: none;
  background-color: @color-white;
  border: 1px solid #ddd;
  font-size: 1em;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);

  li {
    & > a,
    & > span a,
    & > span.dropdown-menu-element {
      display: block;
      padding: 6px 13px 6px 13px;
      clear: both;
      text-decoration: none;
      color: @color-base;
      font-weight: normal;
      line-height: 18px;
      white-space: nowrap;
    }

    & > span.dropdown-menu-element > a {
      white-space: normal;
    }

    & > a:focus,
    & > span a:focus,
    & > span.dropdown-menu-element:focus {
      .theme("default", {
        color: #fff;
        background-color: @themeBrand-color-corp-lightBlue;
      });
    }

    & > a:hover,
    & > span a:hover,
    & > span.dropdown-menu-element:hover,
    &.active > a,
    &.active > a:hover {
      text-decoration: none;
      background-image: none;
      color: @color-white;
      filter: none;
      .theme("default", {
        background-color: @themeBrand-color-corp-lightBlue;
      });
      .theme("black", {
        background-color: @themeBlack-color-corp-lightBlue;
      });
      .theme("classic", {
        background-color: @themeClassic-color-corp-lightBlue;
      });
    }

    & > span.dropdown-menu-element:hover {
      background-color: @table-bg-hover;
    }

    & > span.dropdown-menu-element:hover .dropdown-menu-element-title {
      text-decoration: none;
    }

    .fileTypeIco {
      position: relative;
      top: 1px;
    }
  }

  &.dropdown-menu_icons {
    & > li > a {
      position: relative;
      padding-left: 47px;
      padding-right: 50px;
    }

    & > li > a .fa {
      position: absolute;
      width: 18px;
      left: 16px;
      top: 7px;
      font-size: @fontSize-base + 3;
      .text-muted();
    }

    & > li > a:hover > .fa {
      color: @color-white;
    }
  }

  &.dropdown-menu_padding {
    padding: 8px 0;
  }

  li.disabled {
    a, a:hover {
      background: @dropdown-link-hover-bg;
      color: @gray-dark;
      cursor: default;

      .fa {
        top: 14px;
        color: @gray-light;
      }
    }

    a {
      padding-top: 14px;
      padding-bottom: 14px;
      margin-bottom: -8px;
      margin-top: -8px;
    }
  }
}

.dropdown-menu_arrow {
  &:before {
    position: absolute;
    top: -7px;
    right: 10px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid #ccc;
    border-left: 7px solid transparent;
    border-bottom-color: rgba(0, 0, 0, 0.15);
    content: '';
  }

  &:after {
    position: absolute;
    top: -6px;
    right: 10px;
    display: inline-block !important;
    border-right: 7px solid transparent;
    border-bottom: 7px solid @color-white;
    border-left: 7px solid transparent;
    content: '';
  }
}

/* drop up menu and bottom arrow mode */
.dropup {
  .dropdown-menu_arrow {

    &:before {
      top: auto;
      bottom: -7px;
      border-right: 7px solid transparent;
      border-bottom: none;
      border-top: 7px solid #ccc;
      border-left: 7px solid transparent;
      border-bottom-color: rgba(0, 0, 0, 0.15);
      content: '';
    }

    &:after {
      top: auto;
      bottom: -6px;
      border-right: 7px solid transparent;
      border-bottom: none;
      border-top: 7px solid @color-white;
      border-left: 7px solid transparent;
    }
  }

}

.dropdown-menu_arrow_left {
  &:before,
  &:after {
    left: 10px;
    right: auto;
  }
}

/* custom dropdown conetnt */
.dropdown-content {
  padding: 5px;

  form {
    margin: 0;
  }
}

.dropdown.inline .dropdown-menu {
  display: inline-block;
  position: relative;
}

.dropdown-menu.bottom-up {
  top: auto;
  bottom: 100%;
  margin-bottom: 2px;
}

/* dropdown sub menu support for Bootsrap 3 */
.dropdown-submenu {
  position: relative;

  & > .dropdown-menu {
    top: 5px;
    left: 100%;
    margin-top: -6px;
    margin-left: -1px;
  }

  &:hover > .dropdown-menu {
    display: block;
  }

  & > a:after {
    position: absolute;
    display: inline-block;
    font-size: 1.08em;
    right: 7px;
    top: 7px;
    font-family: "Font Awesome 5 Pro";
    height: auto;
    content: "\f105";
    font-weight: 300;
  }

  &:hover > a:after {
    border-left-color: @color-white;
  }

  &.pull-left {
    float: none;

    & > .dropdown-menu {
      left: -100%;
      margin-left: 10px;
    }
  }
}

.dropup .dropdown-submenu > .dropdown-menu {
  top: auto;
  bottom: 0;
  margin-top: 0;
  margin-bottom: -2px;
}

.dropdown {
  &.open {
    [data-toggle='dropdown'] {
      & > .pseudoLink {
        &:after {
          display: none;
        }
      }

      & > .caret {
        display: none;
      }
    }
  }
}


// remove styles Favorites lienholders dropdown from file
// when migrate to angular.latest

//Favorites lienholders dropdown
.favorites-menu.dropdown-menu {
  overflow: hidden;
  padding-top: 37px;
  width: 370px;

  .scroller {
    padding-right: 0;
    max-height: 400px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(224, 227, 230);
      border-radius: 5px;
    }
  }

  .favorites-menu__header {
    position: absolute;
    top: 5px;
    left: 0;
    right: 0;
    padding: 6px 18px 6px 13px;

    a {
      margin-top: 2px;
    }

    h4 {
      display: inline-block;
      margin: 3px 0 0 0;
      font-size: 14px;
      font-weight: 500;
    }
  }


  .list-unstyled {
    margin-bottom: 0;
    position: relative;
    left: 0;
    transition: left .2s ease-out;

      li {
        position: relative;

        a {
          padding-right: 35px;
          white-space: normal;

          strong {font-weight: 700;}
        }

        &:hover {
          .theme("default", {
            background: @themeBrand-color-corp-skyBlue;
          });
          .theme("black", {
            background: @themeBlack-color-corp-skyBlue;
          });
          .theme("classic", {
            background: @themeClassic-color-corp-skyBlue;
          });

          a, .btn, .text-warning {
            color: @white;
          }
        }

        .btn {
          padding: 10px;
          position: absolute;
          right: 10px;
          width: auto;
          color: @input-border;
          .vertical-align_absolute;

          &.btn-fa {
            font-size: inherit;
          }
        }

        .favorites-menu__trash-button:hover {
          .fa-trash-o:before {
            content: "\f1f8";
          }
        }

        .favorites-menu__favorit-button:hover {

          .fa-star.text-warning {
            color: @white;

          }
        }
      }

    }

  .favorites-menu__content__emptyState {
    padding: 6px 13px 11px 13px;
  }

  .favorites-menu__list-hidden-left {
    left: -370px;
    transition: left .2s ease-in;
    position: absolute;

  }

  .favorites-menu__list-hidden-right {
    transition: left .2s ease-out;
    left: 370px;
    position: absolute;
  }


  // new classes
  .favorites-menu__content {
    position: relative;
    left: 0;
    width: 370px;
    overflow: hidden;
    transition: left .2s ease-out;
  }

  .favorites-menu__content_hidden-left {
    left: -370px;
    transition: left .2s ease-out;
    position: absolute ;

  }

  .favorites-menu__content_hidden-right {
    transition: left .2s ease-out;
    left: 370px;
    position: absolute;
  }
}



