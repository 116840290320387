.assign-inventory {
  padding-bottom: 18px;

  font-size: @fontSize-base + 1;

  .form-group:not(.radio-inline) {

    label {
      display: block;
      font-weight: 500;
      text-transform: uppercase;
      font-size: @fontSize-base + 1;
      color: darken(@color-muted, 10%);
    }
  }

  h4 {
    margin-left: 0 !important;
  }


  .form-group {
    margin-bottom: 18px;
  }

  .radio-inline {
    margin-top: 19px;
  }

  h4, .assign-inventory__list, .assign-inventory__allocation-title {
    font-size: @fontSize-base + 5;
  }

  .assign-inventory__allocation-title {
    margin-right: 3px;
  }

  .assign-inventory__list {
    margin-bottom: 20px;

    li {
      margin-bottom: 5px;

    }

    .btn-fa {
      position: relative;
      margin-left: -10px;
      margin-bottom: -5px;
      padding: 0 15px 5px 10px;


      .fa {
        font-size: @font-size-base + 1;
      }

      .fa-star-o {
        color: #9e9e9e; //need variable
      }

      &:hover .fa-star-o {
        color: @color-status-warning;
      }
    }

  }

  .assign-inventory__list__item {
    display: none;

    .assign-inventory__list__remove-favorit {
      display: none;
    }

    .assign-inventory__list__add-favorit {
      display: none;
    }

    .assign-inventory__list__add-favorit {
      display: inline;
    }


    &_favorit {
        display: block;

       .assign-inventory__list__remove-favorit {
        display: inline;
      }

      .assign-inventory__list__add-favorit {
        display: none;
      }
    }
  }

  .assign-inventory__list_show-all .assign-inventory__list__item {
    display: block;
  }


  p {
    margin-bottom: 16px;
  }

  &.fieldset {

    h4 {
      margin-top: 7px;
      font-weight: 400;
    }
  }
}

.efileForm {

  .assign-inventory {
    padding-bottom: 0;

      margin-top: 10px;

      h4 {
        font-size: 17px !important;
        font-weight: normal;
        margin-top: 0;
        margin-bottom: 15px;
      }

      .form-group {

        label {
          font-size: 14px;
          font-weight: normal;
          text-transform: none;
          color: #333;

        }
      }

      .alert {
        font-size: 13px;
      }
  }

  .has-error .assign-inventory {

    label, .help-block, .assign-inventory__allocation-title {
      color: @state-danger-text;
    }
  }
}

