.autoEfileDashboardReview {
  .mvscModal-body {
    padding: 0 0 151px 0;
    margin: 0;
  }
    
  .entity {
    padding-top: 35px;
  }
    
  .autoEfileDashboardReview-actions {
    min-width: 1000px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 150px;
    padding-top: 40px;
    border-top: 1px solid @color-border_light;
      
    > p {
      margin-top: 15px;
    }
  }
  
  
}