.text-primary {
  .text-emphasis-variant(@brand-primary);
}

.text-success {
  .text-emphasis-variant(@color-status-success);
}

.text-info {
  .theme("default", {
    .text-emphasis-variant(@themeBrand-color-corp-lightBlue);
  });
  .theme("black", {
    .text-emphasis-variant(@themeBlack-color-corp-lightBlue);
  });
  .theme("classic", {
    .text-emphasis-variant(@themeClassic-color-corp-lightBlue);
  });
}

.text-init {
    .text-emphasis-variant(@color-status-init);
}

.text-warning {
  .text-emphasis-variant(darken(@color-status-warning, 15%));
}

.text-danger {
  .text-emphasis-variant(@color-status-danger);
}

.text-pending {
  .text-emphasis-variant(@color-status-pending);
}

.text-available {
  .text-emphasis-variant(@color-status-available);
}

.text-sold {
  .text-emphasis-variant(@color-status-sold);
}

.text-unwind {
  .text-emphasis-variant(@color-status-unwind);
}

.text-muted {
  a {
    color: @text-muted;
    text-decoration: underline;
  }
}

.text-mutedMore {
  color: #ccc;
}

.nowrap {
  white-space: nowrap !important;
}

.list-pageActions {
  margin-bottom: 15px;

  li {
    vertical-align: middle;
  }
}

.list-spaced {
  margin-bottom: 0;

  li {
    padding-bottom: floor((@line-height-computed/2));
  }
}

.list-inline {

  li:last-child,
  li.pull-right {
    padding-right: 0;
  }
}

.list-inline_comma {
  margin-left: 0;

  > li {
    display: inline;
    padding: 0;

    &:after {
      content: ' , ';
      margin-left: -.25em;
    }
  }

  li:last-child {
    &:after {
      content: '';
    }
  }
}

.list-tick {
  padding: 0;

  > li {
    margin-bottom: 10px;
    position: relative;
    padding-left: 1.53em;
    list-style: none;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: '\f00c';
      font-family: "Font Awesome 5 Pro";
      color: @btn-default-border;
      font-size: 1.07em;
    }
  }
}

.list_noMargin {
  margin-bottom: 0;

  li:last-child {
    margin-bottom: 0;
    padding-bottom: 0;
  }
}

.list-icons {
  & > li {
    position: relative;
    margin-bottom: .5em;
    padding-left: 1.9em;

    & > .fa {
      position: absolute;
      left: 0;
      top: 2px;

      //margin-left: -3px;
      margin-top: 0;
      width: 1.25em;

      text-align: center;
    }
  }

  &.text-bigger {
    li > .fa {
      top: 3px;
    }
  }

  &.text-biggest {
    li > .fa {
      top: 4px;
    }
  }

  &.list-inline {
    margin-bottom: 0;

    li {
      margin-bottom: 0;
      margin-right: 30px;
    }
  }
}

.list-tiles {
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 0;
  list-style-type: none;
  text-align: center;

  li {
    display: table-cell;
    padding: 0;
  }

  .list-tiles-divider {
    /* position: relative;
    width: 61px !important;

    &:after {
      position: absolute;
      left: 50%;
      top: 17px;
      bottom: 17px;
      margin-left: -1px;

      width: 1px;

      background-color: lighten(@table-border-color, 5%);
      content: '';
    }*/
  }

  .list-tiles-tile {
    display: block;
    height: 102px;
    padding-top: 15px;

    border-radius: @border-radius-base;

    span {
      display: block;
      font-weight: 300;
      font-size: 60px;
      line-height: 60px;
    }

    small {
      display: block;
      font-size: @fontSize-base;
      color: lighten(@color-base, 40%);
    }
  }

  a.list-tiles-tile {
    &:hover {
      text-decoration: none;
      background-color: lighten(@color-corp-grayBlue, 60%);
    }

    &.text-danger:hover {
      background-color: lighten(@color-status-danger, 46%);
    }

    &.text-info:hover {
      .theme("default", {
        background-color: lighten(@themeBrand-color-corp-lightBlue, 32%);
      });
      .theme("black", {
        background-color: lighten(@themeBlack-color-corp-lightBlue, 32%);
      });
      .theme("classic", {
        background-color: lighten(@themeClassic-color-corp-lightBlue, 32%);
      });
    }

    &.text-success:hover {
      background-color: lighten(@color-status-success, 49%);
    }

    &.text-available:hover {
      background-color: lighten(@color-status-available, 33%);
    }

    &.text-sold:hover {
      background-color: lighten(@color-status-sold, 41%);
    }

    &.text-unwind:hover {
      background-color: lighten(@color-status-unwind, 40%);
    }


  }

  &.list-tiles_mini {
    .list-tiles-tile {
      height: 71px;
      padding-top: 11px;

      span {
        font-size: 28px;
        line-height: 32px;
      }
    }

    .list-tiles-divider {
      /*width: 21px !important;

      &:after {
        top: 15px;
        bottom: 15px;
      }*/
    }
  }
}


/*change li.divider to :after*/
.list-tiles {

  li {
    position: relative;

    &:not(:first-child) {
      padding-left: 30px;
    }

    &:not(:last-child) {
      padding-right: 30px;

      &:after {
        position: absolute;
        right: 0px;
        top: 17px;
        bottom: 17px;
        content: '';
        display: block;
        width: 1px;
        background-color: lighten(@table-border-color, 5%);
      }
    }
  }

  &_mini li {

    &:not(:first-child) {
      padding-left: 10px;
    }

    &:not(:last-child) {
      padding-right: 10px;

      &:after {
        top: 15px;
        bottom: 15px;
        right: 0;
      }
    }

  }
}

/* /change li.divider to :after*/

.list-favorites {
  margin-bottom: 0;
  padding-bottom: 87px;
  font-size: @fontSize-base + 5;

  li {
    padding: 0;

    &:not(:last-child) {
      margin-right: 83px;
    }
  }

  a {
    display: inline-block;
    height: 30px;
    line-height: 30px;
  }

  .iconInCircle {
    margin-right: 10px;
  }

  .fa-comments {

    &:before {
      position: relative;
      top: -1px;
      font-size: 1.06em;
    }
  }

  .fa-desktop {

    &:before {
      position: relative;
      bottom: -1px;
      font-size: .86em;
    }
  }
}

ul.text-biggest {
  li {
    margin-bottom: .95em;
  }
}

.text-middle {
  vertical-align: middle;
}

// Following list used as a checklist in forms containing passwords:
// login page, user profile
ul.password-validation-list {
  transform: translateY(-5px);
  margin-bottom: 11px !important;

  li {
    font-size: 13px !important;
    line-height: 1.42857143 !important;
    + li {
      margin-top: 6px !important;
    }
    &.password-validation-item--valid {
      .fa {
        color: #2a9647;
      }
    }
  }

  .fa {
    font-size: 1.08em !important;
    line-height: 14px !important;
    color: @input-border;
    margin-right: 5px !important;
  }
}


// Definitions Lists
dl {
  dt {
    margin-bottom: 3px;
    color: @color-muted_light;
    font-weight: 400;
  }
}

dl.dl-horizontal {

  dt,
  dd {
    margin-bottom: 7px;
  }
}


dl.dl-horizontal_mini {
  dt {
    width: 80px;
    text-align: left;
  }

  dd {
    margin-left: 95px;
  }
}

dl.dl-horizontal_259 {
  dt {
    width: 239px;
    text-align: left;
  }

  dd {
    margin-left: 259px;
  }
}

.nav-pills_categories {
  margin-bottom: 14px;

  li {

    &:not(:last-child) {
      margin-right: 14px;
    }

    > span {
      display: inline-block;
      color: @color-muted;
    }

    a,
    > span {
      padding: 5px 11px;
      font-weight: bold;

      > span {
        font-weight: normal;
      }
    }

    &:not(.active) a:hover {
      background: @color-border_light;
    }

    &.active {

      a {

        .text-muted {
          color: @white;
        }
      }
    }
  }
}


//new scroller rules
fieldset.parentDiv {
  margin-bottom: 26px !important;
  position: relative;

  &:after {
    position: absolute;
    display: block;
    bottom: -7px;
    left: 0;
    right: 0;
    z-index: 1;
    height: 18px;
    content: '' !important;
    box-shadow: inset 0px -10px 10px 0px #fff;
    pointer-events: none;
  }

  .scroller {}
}


.slimscroll-bar {
  box-shadow: 0px 0px 0px 2px #fff;
}

.message-error {
  line-height: 18px;
}
