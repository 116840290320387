.role {
  &.card_active,
  &.card_active:hover {
    border-color: @input-border;
  }
  
  &.disabled {
    .card-action.card-action_corner {
      display: none; 
    }
  }
}

.rolesGlobalDeny {
  margin-bottom: 40px;
  
  .onoff {
    margin-left: 10px;
  }
}

.roles-list {
  li {
    padding: 12px 0 8px 0;
    border-bottom: 1px solid #e0e3e6;
    
    &:last-child {
      border-bottom: none;
    }
  }
    
  .onoff {
    margin-top:-5px;
  }
}