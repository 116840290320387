// Find/Add owner control
// lives in a modal window

.modal.owners-lookup {
    .modal-dialog {
        width: 900px;
    }

    &.modal_wide.modal_wide_xl .modal-dialog {
        width: 1010px;
    }

    .modal-content {
        //height: 570px;
    }

    .modal-body {
        //min-height: 580px;
        padding: 10px 50px 50px 50px;
    }

    .nav-tabs_mvsc-wrapper {
        margin-top: 15px;
    }

    .emptyState {
        /*position: absolute;
    left: 0;
    right: 0;
    bottom: 64px;*/
    }

    .input-lg+clear-link .form-control-reset {
        bottom: 5px;
    }

    ngb-typeahead-window.dropdown-menu {
        min-width: 175px;

        .dropdown-menu-item {
            padding-left: 10px;
            padding-right: 10px;
            width: 100%;
        }
    }

    ngb-typeahead-window.dropdown-menu {
        min-width: 175px;

        .dropdown-item {
            padding-left: 10px;
            padding-right: 10px;
            width: 100%;
        }
    }
}

// FIND OWNER TAB
.owners-lookup {
    .emptyState-wrapper {
        padding-top: 59px;
        padding-bottom: 59px;
    }
}

.owners-lookup__owner-type {
    margin-bottom: 18px;

    .radio-inline {
        margin-right: 12px;
    }
}

.owners-lookup__search-results {
    //height: 350px;

    .owners-lookup__search-results-item:not(:last-child) {
        margin-bottom: 10px;
    }
}

.owners-lookup__search-results-item {
    padding: 15px 40px;
    border: 1px solid @color-border_light;
    border-radius: @border-radius-base;

    &:hover {
        cursor: pointer;

        .owners-lookup__user-name {
            .helper-link-hover();
        }

        .theme("default", {
            background: @themeBrand-tabs-tab-hover-bgcolor;
        });

        .theme("black", {
            background: @themeBlack-tabs-tab-hover-bgcolor;
        });
    }

    table {
        width: 100%;
    }

    .table_vertical-align_top+.alert {
        padding-top: 10px;
    }

    .alert {
        margin: 0;
        padding: 5px 0 0 0;
        background-color: transparent;
        border: none;
    }

    &_opened {
        .alert {
            padding: 10px 0 5px 0;
        }
    }

    .form-actions {
        margin: 13px 0 0 0;
    }

    .text-mutedMore {
        margin-right: 2px;
    }

    .fa-user {
        margin-left: -19px;
        margin-right: 5px;
    }

    .fa-warning {
        position: relative;
        margin-left: -20px;
        margin-right: 2px;
    }

    .subInfo {
        font-size: 100%;

        .impact {
            color: @color-base;
        }

        >span {
            display: inline;
        }
    }

    &_opened:hover {
        background: none;
        cursor: default;
    }

    .btn-default:focus:not(.active) {
        background: @btn-default-bg;
        border-color: @btn-default-border;
    }

    .btn-xs+.btn-xs {
        margin-left: .15em;
    }

    .row:not(:last-child) {
        margin-bottom: 5px;
    }

    .btn-fa:hover {
        .fa-star-o {
            color: @color-status-warning;
        }
    }
}

.owners-lookup__text-warning-bg {
    background: @state-warning-border;
    padding: 0 3px;
    margin: 0 -3px;
}

// ADD OWNER TAB
.owners-lookup__add-owner-form {
    fieldset {
        margin-bottom: 20px !important;
    }
}

.owners-lookup__alerts {
    margin-bottom: 10px;
}


.owners-lookup__search-control {
    position: relative;
    margin-bottom: 20px;
    padding-right: 110px;

    .btn-lg {
        position: absolute;
        right: 0;
        top: 0;

        width: 100px;
    }
}

.owners-lookup__customer-id,
.owners-lookup__last-name {
    position: relative;
    margin-bottom: 10px;

    .form-control {
        padding-right: 180px;
    }

    .btn-link {
        position: absolute;
        right: 3px;
        top: 8px;
    }
}

.address-restricted {
    font-size: 11px;
    line-height: 13px;
    color: #666666;

    .fa {
        font-size: 11px;
    }
}
