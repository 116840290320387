.autoEfileDashboard {
  // todo: when new dashboard implemented, remove this rule
  .widget .table_vertCentered {
    .label {
      position: relative;
      top: -2px;
    }
  }
  //
  
  .autoEfileDashboard-historyEl-wrapper {
    & > td {
      // todo: make collapsable row as reusable component (it is presented in bundles and autoefile dashboards)
      height: 54px;
      padding: 0;
    }
    
    table {
      margin-bottom: 0;
      border: none;
      background-color: transparent;
      
      td {
        border: none;
      }
    }
    
    .autoEfileDashboard-historyEl-collapse {
      position: relative;
      top: -9px;
      margin-bottom: -9px;
    }
    
    .subContent {
      top: 1px;
      margin-bottom: 0;
      max-height: none;
      
      .subContent-item:hover {
        background-color: transparent;
      }
      
      .subContent-item:first-child {
        td {
          padding-top: 15px;
        }
      }
    }
    
    &:hover > td {
      background-color: transparent;
    }
    
  }
}