.rangeslider,
.rangeslider__fill {
  margin-top: 8px;
  background: @btn-default-border;
  display: block;
  float: left;
  width: 75px;
  height: 2px;
  border-radius: 10px;
}

.rangeslider {
  position: relative;
}

.rangeslider--disabled {
  opacity: 0.4;
}

.rangeslider__fill {
  position: absolute;
  top: 0;
  display: none;
}

.rangeslider__handle {
  display: inline-block;
  width: 12px;
  height: 12px;
  position: absolute;
  top: -5px;
  background: @btn-default-border;
  border: 4px solid @color-pale;
  cursor: pointer;
  border-radius: 50%;
  
  &:hover, &:active {
    border-color: darken(@color-pale, 23%);
  }
}