.loading-placeholder {
    -webkit-mask-size: 100%;
    mask-size: 100%;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;

    width: 100%;

    animation-duration: 3s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeholder;
    animation-timing-function: linear;

    position: relative;
    display: inline-block;

    background-size: cover;
    background: #ebebeb;
    background: linear-gradient(90deg, #ebebeb .25%, #ebebeb 5.57%, #dfdfdf 17.52%, #ebebeb 31.02%, #ebebeb);

    @media (-ms-high-contrast:active) and (-ms-high-contrast:none) {
        animation-name: shining;
        animation-duration: 1s;
        animation-timing-function: ease-in-out;
    }

    &--fees {
        -webkit-mask-image: url('/Scripts/assets/img/sceleton.svg');
        mask-image: url('/Scripts/assets/img/sceleton.svg');

        height: 191px;

        @media (-ms-high-contrast:active) and (-ms-high-contrast:none) {
            background-image: url('/Scripts/assets/img/sceleton.svg');
        }
    }
}


@keyframes placeholder {
    from {
        background-position: -380px 0
    }
    to {
        background-position: 380px 0
    }
}

@keyframes shining {
    0% {
        opacity: .6;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: .6;
    }
}
