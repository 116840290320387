// Typography
h1, h2, h3, h4, h5, h6 {
  font-family: @fontFamily-base;
  font-weight: 300;
  small {
    color: @color-title;
  }
  .block {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

.text-security-disc {
  font-family: text-security-disc !important;
}

p.desc {
  font-weight: 300;
}

// mixins helpers, link definition
.helper-link {
  text-decoration: none;
  color: @color-link;
}

.helper-link-hover {
  color: @color-link_hover;
  text-decoration: underline;
}

a {
  cursor: pointer;

  .helper-link();

  &:hover,
  &.hover {
    .helper-link-hover();
  }

  .fa {
      margin-right: 3px;
  }

  &, &:focus, &:hover, &:active {
    outline: 0;
  }

  &:focus {
    text-decoration: none;

    // .theme("default", {
    //   box-shadow: 0 0 0 2px #21BAE7;
    // });
  }

  &.active {
    cursor: default;
  }

  &.disabled {
    opacity: .5;
    cursor: text;

    &:hover {
      .helper-link();
    }
  }

  &.sortable,
  &.sortable_asc,
  &.sortable_desc {
    position: relative;
    color: darken(@link-color, 5%);
  }
  &.sortable_asc,
  &.sortable_desc {
    &:after {
      font-size: 11px;
      position: absolute;
      right: -1.25em;
      top: -1px;
      width: 1em;
      content: '\f0d8';
      font-family: "Font Awesome 5 Pro";

      &:hover {
        text-decoration: none !important;
      }
    }
  }

  &.sortable_desc {
    &:after {
      content: '\f0d7';
    }
  }

  &.backLink {
    position: relative;
    display: inline-block;
    margin-left: 1.25em;

    &:before {
      top: 50%;
      position: absolute;
      left: -1.25em;
      width: 1em;
      content: "\f177";
      font-weight: 300;
      line-height: 0;
      font-family: "Font Awesome 5 Pro";
      border-bottom: none;
    }
  }

  &.deleteEntity {
    .text-danger();

    &_fullView {
      border-radius: 99px;
      display: inline-block;
      height: 15px;
      margin-left: 5px;
      padding: 1px 5px;
      background-color: #e02222;
      color: #fff;
      font-size: 10px;
      line-height: 13px;
      font-weight: 500;
      text-align: center;
      text-transform: uppercase;
      text-decoration: none;
      transition: background-color 0.2s;

      &:hover {
        background-color: #ff000a;
      }

      .fa {
        position: relative;
        top: 0px;
        margin: 0;
        font-size: 11px;
        line-height: 12px;
      }
      // space to the text
      .fa + * {
        margin-left: 4px;
      }
    }
  }

  &.reverse {
    .helper-link-hover();

    &:hover {
      .helper-link();
    }
  }

  &.label:hover {
    opacity: .8;
  }

  &.text-danger:focus {
    .text-danger();
  }

  &.underlined {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.uppercase {
  text-transform: uppercase;
}

.pseudoLink {
  position: relative;
  display: inline-block;
  line-height: 18px;
  text-decoration: none !important;

  &:after {
    content: '';
    border-bottom: 1px dotted @color-link;
    position: absolute;
    bottom: 1px;
    left: 0;
    right: 0;
  }

  &.disabled:after {
    content: none;
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  &:hover:after {
    display: none;
  }

  &.text-base:after {
    border-bottom-color: @color-base;
  }

  &.text-danger:after {
    border-bottom-color: @color-status-danger;
  }

  &.text-success:after {
    border-bottom-color: @color-status-success;
  }

  &.text-muted:after {
    border-bottom-color: @text-muted;
  }

  &.text-warning:after {
    border-bottom-color: @color-status-warning;
  }

  &.active {
    color: @color-base;

    &:after {
      border-bottom-color: @color-base;
    }
  }

  > .fa:not(.text-right) {
    margin-right: 0;
    margin-left: -1.25em;
    width: 1.25em;
    text-align: left;

    &.text-biggest {
      margin-left: -1.2em;
      width: 1.2em;
    }
  }

  > .fa.text-right {
    margin-left: 0;
    margin-right: -1.25em;
    width: 1em;
  }

  &.hasFa {
    margin-left: 1.45em;
    line-height: 18px;
  }

  &[data-toggle="collapse"]:not(.collapsed) {

    &:after {
      content: none;
    }

    &.text-danger {
      color: @color-status-danger;
    }
  }

  &.marked:before {
    top: .45em;
  }
}

a:hover .pseudoLink:after {
  display: none;
}

.black {
  color: @color-base;
}

strong,
.strong {
  font-weight: 500;
}

.impact {
  font-weight: 700;
}

.regular {
  font-weight: 400;
}

.subInfo {
  display: block;
  margin-top: 2px;
  font-size: 85%;
  line-height: 16px;

  > span {
    display: block;

    /*
    &:after {
      content: "\A";
      white-space: pre;
    }
    */
  }
  &_icons {

    > span {
      margin-bottom: 1px;
    }

    i.fa {
      color: #ccc;
      margin-right: 1px;
      position: relative;

      &.fa-envelope {
        top: -1px;
        font-size: 10px;
      }

      &.fa-loccation {
        font-size: 12px;
      }
    }
  }
}

.subLinks {
  padding-top: .5em;

  a {
    margin-right: .75em;
  }

  a.disabled + i.fa.fa-warning {
    margin-left: -.5em;
  }
}

.ellipsis {
  display: inline-block;
  width: 100%;
  vertical-align: bottom;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emspace {
  padding-left: 1em;
}

.shadowOnHover:not(.active):hover {
  box-shadow: 0 0 0 3px @color-border_light;
}

.active > .shadowOnHover {
  box-shadow: none !important;
}

.text-333 {
  color: @gray-dark;
}

.text-pale {
  color: @color-pale;
}

.text-normal,
.text-normal.fa {
  font-size: @fontSize-base ;
}

.text-smaller,
.text-smaller.fa {
  font-size: @fontSize-base - 1;
}

.text-smallest,
.table .text-smallest {
  font-size: @fontSize-base - 2;
}

.text-bigger,
.text-bigger.fa {
  font-size: @fontSize-base + 1;
}

.text-biggest,
.text-biggest.fa {
  font-size: @fontSize-base + 2;
}

.text-xl,
.text-xl.fa {
  font-size: @fontSize-base + 5;
}

.text-striked {
  text-decoration: line-through;
}

.margin_0 {
  margin: 0 !important;
}

.margin {
  &.margin_right_regular {
    margin-right: 15px !important;
  }

  &.margin_right_s {
    margin-right: 10px !important;
  }

  &.margin_left_regular {
    margin-left: 15px !important;
  }

  &.margin_left_s {
    margin-left: 10px !important;
  }

  &.margin_bottom_0 {
    margin-bottom: 0;
  }

  &.margin_bottom_10 {
    margin-bottom: 10px;
  }

  &.margin_bottom_15 {
    margin-bottom: 15px;
  }

  &.margin_bottom_20 {
    margin-bottom: 20px;
  }

  &.margin_bottom_25 {
    margin-bottom: 25px;
  }

  &.margin_bottom_30 {
    margin-bottom: 30px;
  }

  &.margin_top_10 {
    margin-top: 10px;
  }

  &.margin_top_30 {
    margin-top: 30px;
  }
}

.iconed {
  display: block;
  position: relative;
  padding-left: 17px;

  .iconed-icon {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.faa-spin {
  //color: @color-link;

  &.faa-spin-mediumOne {
    font-size: 24px;
  }

  &.faa-spin_bigOne {
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -19px;
    margin-top: -19px;
    font-size: 40px;
  }
}

.dropdown-toggle .angle {
  line-height: 1;
  vertical-align: middle;
}

.angle {
  display: inline-block;
  width: auto;
  height: 1em;

  &:after {
    font-family: "Font Awesome 5 Pro";
    content: "\f107";
  }
}

.inline-block {
  display: inline-block;
}

.inline-block_marginRight {
  margin-right: 5px;
}

.inline-block_marginLeft {
  margin-left: 5px;
}

.word-break {
  word-break: break-all;
  word-break: break-word;
}

.white-nowrap {
  white-space: nowrap;
}

.marked {
  position: relative;
  height: 18px;
  line-height: 18px;
  display: inline-block;

  &:before {
    position: relative;
    top: -.1em;
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 6px;
    height: 6px;
    margin-right: 5px;
    background: @color-base;
    border-radius: 50%;
  }

  &.pseudoLink {
    margin-left: 11px;

    &:before {
      position: absolute;
      left: -11px;
      top: 6px;
    }

    &::after {
      bottom: 2px;
    }
  }

  &.marked-info:before {
    background: @color-status-info;
  }

  &.marked-success:before {
    background: @color-status-success;
  }

  &.marked-danger:before {
    background: @color-status-danger;
  }

  &.marked-warning:before {
    background: @color-status-warning;
  }

  &.marked-pending:before {
    background: @color-status-pending;
  }

  &.marked-available:before {
    background: @color-status-available;
  }

  &.marked-sold:before {
    background: @color-status-sold;
  }

  &.marked-voided:before {
    background: @color-status-voided;
  }

  &.marked-unwind:before {
    background: @color-status-unwind;
  }

  &.marked-init:before {
    background: @color-status-init;
  }

  //for default links
  &.marked-link:before {
    background: @color-link;
  }
}

.subInfo .marked {

}



//@media screen and (-webkit-max-device-pixel-ratio:1) and (min-color-index:0) {
//  .subInfo .marked:before {
//    top: 2px;
//  }
//}



.label {
  .badge {
    top: 0;
    left: 0;
    margin: 0 0 0 5px;
    padding: 0 6px;
    height: 12px;
    line-height: 12px;
    display: inline-block;
    font-size: @font-size-base - 4;
    background: fade(@black, 15%);
  }

  & + .fa {
    margin-left: 5px;
  }
}

mvsc-transaction-status + .fa {
  margin-left: 5px;
}

kbd {
  padding: 5px 10px 4px 10px;
  background: #f1f1f1;
  font-size: 11px;
  color: @gray-dark;
  border-radius: 4px;
  font-family: @fontFamily-base;
  box-shadow: none;
  font-weight: 500;
}

mvsc-vehicle-status + .fa,
mvsc-vehicle-status2 + .fa {
  margin-left: 5px;
}

.nav-tabs {
  li {
    a.disabled {
      color: #999;
      cursor: default;
      opacity: 1;

      &:hover {
        background: none;
        border-color: transparent;
      }

      .fa {
        color: #999;
      }
    }
  }
}
