.blockUI {
  z-index: @zindex-toastr !important;
  cursor: default !important;
  width: auto;
  
  &.blockMsg {
    border: none !important;
    background-color: transparent !important;
    text-align: left !important;
  }
  
  .modal {
    position: static;
    padding: 15px;
  }
}