.nav-tabs {
    > li.disabled > a {

        &:hover,
        &:focus {
            border-color: transparent;
            cursor: default;
        }
    }

    &.nav-stacked {
        border-right: 1px solid @nav-tabs-border-color;
        border-bottom: none;
        padding: 15px 0;

        > li {
            // Make the list-items overlay the bottom border
            margin-right: -1px;
            margin-bottom: 0;

            // Actual tabs (as links)
            > a {
                margin-bottom: 2px;
                margin-right: 0;

                &:hover {
                    border-color: @nav-tabs-link-hover-border-color @nav-tabs-border-color @nav-tabs-link-hover-border-color @nav-tabs-link-hover-border-color;
                }
            }

            // Active state, and its :hover to override normal :hover
            &.active > a {

                &,
                &:hover,
                &:focus {
                    color: @nav-tabs-active-link-hover-color;
                    background-color: @nav-tabs-active-link-hover-bg;
                    border: 1px solid @nav-tabs-active-link-hover-border-color;
                    border-right-color: transparent;
                    cursor: default;
                }
            }
        }
    }

    a.active {

        &,
        &:hover,
        &:focus {
            color: @nav-tabs-active-link-hover-color;
            background-color: @nav-tabs-active-link-hover-bg;
            border: 1px solid @nav-tabs-active-link-hover-border-color;
            cursor: default;
            border-bottom-color: #fff;
        }
    }
}

.nav-tabs_mvsc-wrapper {
    .tab-pane {
        padding-top: 30px;
    }

    & > ul.nav-tabs {
        .fullScreenModal-tabs;

        margin: 0;
        padding-top: 2px;
        border-bottom-color: @color-border_light;

        li {
            float: none;
            display: inline-block;

            a {
                border-top: none !important;
                border-bottom: none !important;
            }
        }

        li.active {

            a {
                border-left: 1px solid @color-border_light;
                border-right: 1px solid @color-border_light;
            }
        }
    }
}


// see an example of markup in helpcenter's templates
// todo: move navigation to references
.nav-mvscStacked {
    li {
        margin: 0;

        a {
            padding: 10px 20px;
            border-radius: 0;
        }
    }

    > li:not(.active) {
        > a:hover {
            .theme("default", {
                background: @themeBrand-tabs-bgcolor;
            });
            .theme("black", {
                background: @themeBlack-tabs-bgcolor;
            });
        }
    }

    .nav-stacked {
        margin: 15px 0;
        margin-left: 20px;
        font-size: @fontSize-base;

        li.active {

            a {
                background: none;
                color: @color-base;
                font-weight: 500;
                border-left: 3px solid;

                .theme("default", {
                    border-left-color: @themeBrand-color-corp-skyBlue;
                });
                .theme("black", {
                    border-left-color: @themeBlack-color-corp-skyBlue;
                });
                .theme("classic", {
                    border-left-color: @themeClassic-color-corp-skyBlue;
                });
            }
        }

        a {
            border-left: 1px solid @color-border_light;
        }
    }
}
