.popover {
    padding: 0;
    font-size: @font-size-base;
    line-height: @line-height-base;
    font-weight: normal;
    box-shadow: 0 0 8px rgba(0, 0, 0, .1);
    color: @text-color;
    min-width: 230px;

    &.overmodal {
        z-index: @zindex-overmodal;
    }

    .comment {
        margin: 0 -10px;
        padding: 10px 15px 4px 15px;
        border-top: none;

        .avatar {
            margin-top: 4px;
        }

        .comment-body-content p {
            font-size: @fontSize-base;
        }

        .comment-heading {
            padding-top: 6px;

            span.text-muted:first-child {
                padding-right: 9px;
            }
        }

        .comment-footer {
            margin: 10px -15px 0 -15px;
            padding: 12px 15px 3px 15px;
            border-top: 1px solid @well-border;
        }
    }
}

.popover-title {
    border-radius: 0;
    background-color: transparent;
    font-weight: 500;
    padding: 6px 10px;
    font-size: 13px;
    line-height: 18px;
}

.popover-content,
ngb-popover-window.popover {
    display: block;
    z-index: @zindex-modal;
    padding: 7px 10px;

    &>p {
        margin: .35em 0;

        &:first-child {
            margin-top: .15em;
        }
    }
}


ngb-popover-window {
    padding: 0 !important;

    .popover-body {
        padding: 6px 10px;
    }

    .popover-header {
        border-radius: 0;
        background-color: transparent;
        font-weight: 500;
        padding: 6px 10px;
        font-size: 13px;
        line-height: 18px;
        margin: 0 !important;
        border-bottom: 1px solid #dde0e4 !important;
    }
}

.popover_systemHealth {
    z-index: @zindex-overmodal !important;
}
