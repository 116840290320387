// temp intercom restyle rules
#intercom-container.intercom-container {
  z-index: @zindex-intercom;
  
  #intercom-launcher.intercom-launcher {
    right: 33px;
    bottom: 26px;
    z-index: @zindex-intercom;
  }
  
  .intercom-launcher-hovercard {
    display: none !important;
  }
  
  .intercom-launcher-button {
    width: 50px;
    height: 50px;
    background: rgba(0, 0, 0, 0.5);
    box-shadow: none;
    border: none;
    background-image: none !important;
    
    text-align: center;
    line-height: 50px;
    
    animation: none !important;
    
    &:hover {
      background: rgba(0, 0, 0, 0.67);
    }
    
    &:after {
      font-family: "Font Awesome 5 Pro";
      text-rendering: auto;
      font-smoothing: antialiased;
      font-size: 20px;
      content: "\f086";
      color: #fff;
    }
  }
  
  .intercom-launcher-initials,
  .intercom-launcher-preview {
    display: none !important;
  }
  
  .intercom-launcher-badge {
    top: -4px;
    right: -4px;
  }
  
  .intercom-conversations-item-user-avatar,
  .intercom-conversations-item-admin-avatar-no-image,
  .intercom-admin-fallback-avatar {
    background-color: @color-status-sold;
  }
}

#intercom-container .intercom-conversations-footer .intercom-conversations-new-conversation-button { 
  box-shadow: none;
  text-shadow: none;
  font-weight: 400;
  border-color: @color-status-pending; 
  background: @color-status-pending;
    
  &:hover {
    border-color: darken(@color-status-pending, 8%); 
    background: darken(@color-status-pending, 8%);
  }
}


// end of temp intercom restyle rules


.assistance-toolbar {
  position: fixed;
  bottom: 26px;
  right: 33px;
  z-index: @zindex-tours;
}

.assistance-toolbar__nav {

  > li {
    margin-left: 6px;
    padding: 0;
    
    a .fa {
      margin-top: 0;
      margin-right: 0;
    }
  }
  
  .dropdown.open {
  
    > a {
      
      .fa {
        background: rgba(0,0,0, .6);
      }
    }
  }
}

.assistance-toolbar__dropdown-menu {
  padding: 22px 0;
  max-width: 300px;
  border: none;
  box-shadow: 0 3px 10px rgba(0,0,0, .3);
  
  &.dropdown-menu {
    margin-bottom: 13px;
  }
  
  &.dropdown-menu_arrow:before,
  &.dropdown-menu_arrow:after {
    right: 18px;
  }
  
  .dropdown-header, 
  li a {
    margin: 0 26px;
    padding: 5px 0 4px 0;
  }
  
  .dropdown-header {
    color: @text-color;
    font-weight: 700;
    text-transform: uppercase;
  }
  
  li {
  
    a {
      display: inline-block;
      
      .fa {
        margin-right: 8px;
        font-size: @fontSize-base + 3;
        
        &:not(.text-danger) {
          color: @color-status-available;
        }
      }
    }
  
    a {
      color: @color-link;
      background: none;
      
      &:hover, &:focus {
        background: none;
      }
      
      &:hover {
         
        span {
          text-decoration: underline;
          color: @color-link_hover;
        }
      }
    }
  }
}