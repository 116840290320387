/* Layout */
.container {
  padding-left: 20px;
  padding-right: 20px;

  &.container_zeropadding {
    padding-left: 0;
    padding-right: 0;
  }
}

body.responsive {
  .pageWrapper,
  .container {
    max-width: 1873px;
  }
}

/* Common */

[wait-for-styles] {
  display: block;
}
[ng-cloak] {
  display: none;
}

// Helps to solve bug with font render in Safari (when element has position fixed);
* {-webkit-font-smoothing: antialiased;}

body {
  padding-left: @margin-app;
  padding-right: @margin-app;
  font-family: @fontFamily-base;
  font-size: @fontSize-base;
  color: @color-base;
  // background: @color-corp-darkBlue;

  .theme("default", {
    background: @themeBrand-color-corp-darkBlue;
  });
  .theme("black", {
    background: @themeBlack-color-corp-darkBlue;
  });
  .theme("classic", {
    background: @themeClassic-color-corp-darkBlue;
  });
}

.relative {
  position: relative;
}

.blockable {
  position: relative;
  opacity: .35;

  &:after {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    content: '';
  }

  .locationPreview-title {
    text-decoration: line-through;
    color: @text-muted;

    &:after {
      border-bottom-color: @text-muted !important;
    }
  }
}

.nav.pull-right > li > .dropdown-menu,
.nav > li > .dropdown-menu.pull-right {
  right: 0;
  left: auto;
  &:before {
    right: 12px;
    left: auto;
  }
  &:after {
    right: 13px;
    left: auto;
  }
  & .dropdown-menu {
    right: 100%;
    left: auto;
    margin-right: -1px;
    margin-left: 0;
    border-radius: 6px 0 6px 6px;
  }
}

// Avatar
.avatar {
  border-radius: 50%;
}

.inputTypeFile {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  opacity: 0;
  filter: alpha(opacity=0);
  transform: translate(-300px, 0) scale(4);
  font-size: 23px;
  direction: ltr;
  cursor: pointer;
}

.required {
  &-form {
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;
    .fas {
      font-size: 14px;
      color: #D8D8D8;
      position: absolute;
      top: 2px;
      left: 0;
    }
  }
  &-name {
    font-size: 12px;
    color: #808080;
    text-transform: uppercase;
    padding-top: 5px;
    &.margin-top {
      padding-top: 17px;
    }
  }
  &-alert {
    font-size: 13px;
    line-height: 20px;
    color: #808080;
    background: none;
    padding: 0;
    margin-bottom: 12px;
    border: 0;
  }
}

.display-hidden {
  display: none !important;
}

.copyToClipboard {
  position: relative;

  ~ .subInfo {
    transition: opacity 500ms ease-out;
  }

  span {
    display: inline-block;
    position: relative;
    z-index: 2;
  }

  &:hover {
    .copy-to-clipBoard-button {
      background: #f2fbff;
    }
    ~ .subInfo {
      opacity: 0;
    }
  }
}

.copy-to-clipBoard-button {
  display: flex;
  align-items: center;
  background: none;
  padding: 0;
  border: none;
  white-space: nowrap;
  position: absolute;
  top: 5px;
  left: 0;
  right: 0;
  z-index: 1;
  opacity: 0;
  transition: all 300ms ease-in;

  &::before {
    display: block;
    content: "\f0c5";
    font-weight: 900;
    font-size: 12px;
    font-family: 'Font Awesome 5 Pro';
    color: #296189;
    margin-right: 4px;
  }

  span {
    display: block;
    font-size: 11px;
    line-height: 14px;
    color: #296189;
    border-bottom: 1px dotted #296189;
  }

  &.copied::before {
    content: "\f058";
    color: #30954a;
  }

  &:hover {
    span {
      color: #000;
      border-bottom-color: #000;
    }
  }
}