  /*!
 * Stylesheet for the Date Range Picker, for use with Bootstrap 3.x
 *
 * Copyright 2013 Dan Grossman ( http://www.dangrossman.info )
 * Licensed under the Apache License v2.0
 * http://www.apache.org/licenses/LICENSE-2.0
 *
 * Built for http://www.improvely.com
 */

.daterangepicker.dropdown-menu {
  padding: 0;
  max-width: none;
  // ???
  z-index: 3000;
  font-size: @fontSize-base + 1;
}

.daterangepicker {
  padding-top: 0 !important;

  .calendar {
    border: 1px solid #d4d9dd;
    padding: 14px 20px 20px 20px;
    background: #fff;
  }

  &.opensleft .ranges {
    width: 170px;
  }

  .table-condensed {
    border-collapse: separate;
  }

  .table-condensed thead th {
    font-weight: 500;
  }

  .table-condensed thead tr:first-child th {
    border-bottom: 8px solid transparent;
  }

  .table-condensed thead th.prev,
  .table-condensed thead th.next {

    &:hover {
      background: transparent;
    }

    .fa {
      font-size: @fontSize-base + 5;
    }
  }

  .single {
    position: relative;
    margin: -1px 0 -1px -1px;
  }

  .calendar:not(.single) {
    margin-top: -1px;
  }

  &.opensleft .calendar {
    margin-left: -1px;
  }

  &.opensright .calendar {
    margin-right: -1px;
  }

  .ranges {
    padding: 15px 20px 67px 20px;
    min-height: 351px;
    width: 180px;
    text-align: left;

    &:after {
      position: absolute;
      bottom: 62px;
      left: 0;
      right: 0;
      content: '';
      display: block;
      height: 1px;
      background: #d4d9dd;
    }
  }

  .ranges ul {
    list-style: none;
    margin: 0;
    padding: 0;

    li {
      .btn;
      .btn-default;
      .btn-sm !important;
      display: block;
      text-align: left;
      font-size: @fontSize-base;
      margin-bottom: 8px;
    }
  }

  .range_inputs {
    position: absolute;
    left: 20px;
    bottom: 15px;
    right: 20px;

    > .btn-sm {
      font-size: @fontSize-base;
    }
  }
}

//Single datepicker with
.daterangepicker_single_pressets.opensleft {
  .ranges {
    display: block !important;

    &:after {
      content: none;
    }

    .range_inputs {
      .applyBtn , .clearBtn, .btn-link {
        display: none;
      }
    }
  }

  .range_inputs {
    display: none !important;
  }

}

.daterangepicker.opensleft .ranges, .daterangepicker.opensleft .calendar {
  float: left;
}

.daterangepicker.opensright .ranges, .daterangepicker.opensright .calendar {
  float: right;
}

.daterangepicker .ranges .range_inputs > div {
  float: left;
}

.daterangepicker .ranges .range_inputs > div:nth-child(2) {
  padding-left: 11px;
}

.daterangepicker.show-calendar .calendar {
  display: block;
}

.daterangepicker .calendar.single .calendar-date {
  border: none;
}

.daterangepicker .calendar th, .daterangepicker .calendar td {
  white-space: nowrap;
  text-align: center;
}

.daterangepicker .daterangepicker_start_input label,
.daterangepicker .daterangepicker_end_input label {
  color: #333;
  display: block;
  font-size: 11px;
  font-weight: normal;
  height: 20px;
  line-height: 20px;
  margin-bottom: 2px;
  text-shadow: #fff 1px 1px 0px;
  text-transform: uppercase;
  width: 74px;
}

.daterangepicker .ranges input {
  font-size: 11px;
}

.daterangepicker .ranges .input-mini {
  background-color: #eee;
  border: 1px solid #ccc;
  color: #555;
  display: block;
  font-size: 11px;
  height: 30px;
  line-height: 30px;
  vertical-align: middle;
  margin: 0 0 10px 0;
  padding: 0 6px;
  width: 74px;
}


.daterangepicker .calendar-time {
  text-align: center;
  margin: 8px auto 0 auto;
  line-height: 30px;
}

.daterangepicker {
  position: absolute;
  background: #fff;
  top: 100px;
  left: 20px;
  padding: 4px;
  margin-top: 1px;
}

.daterangepicker.opensleft:before {
  position: absolute;
  top: -7px;
  right: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.daterangepicker.opensleft:after {
  position: absolute;
  top: -6px;
  right: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  content: '';
}

.daterangepicker.opensright:before {
  position: absolute;
  top: -7px;
  left: 9px;
  display: inline-block;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #ccc;
  border-left: 7px solid transparent;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.daterangepicker.opensright:after {
  position: absolute;
  top: -6px;
  left: 10px;
  display: inline-block;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
  content: '';
}

.daterangepicker table {
  width: 100%;
  margin: 0;
}

.daterangepicker td, .daterangepicker th {
  text-align: center;
  width: 37px;
  height: 30px;
  padding: 5px 0px !important;
  white-space: nowrap;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: 1px solid transparent;
}

.daterangepicker td,
.daterangepicker thead th.available {
  cursor: pointer;
}

.daterangepicker {

 td.off, td.off.disabled {
    color: @dropdown-fallback-border;
  }
}

.daterangepicker td.disabled {
  color: #999;
}

.daterangepicker td:not(.active).available:hover, .daterangepicker th.available:hover {
  background: #eee;
}

.daterangepicker td.in-range {
  .theme("default", {
    background: @themeBrand-tabs-tab-hover-bgcolor;
    border-left-color: @themeBrand-tabs-tab-hover-bgcolor;
    border-bottom-color: @themeBrand-tabs-tab-hover-bgcolor;
  });
  .theme("black", {
    background: @themeBlack-tabs-tab-hover-bgcolor;
    border-left-color: @themeBlack-tabs-tab-hover-bgcolor;
    border-bottom-color: @themeBlack-tabs-tab-hover-bgcolor;
  });
}

.daterangepicker td.active {
  background-color: @color-status-info;
  color: #fff;
}


.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.ampmselect {
  width: 50px;
  margin-bottom: 0;
}

.daterangepicker_start_input {
  float: left;
}

.daterangepicker_end_input {
  float: left;
  padding-left: 11px
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker tr:nth-child(2) th {
  font-size: @fontSize-base - 1;
  text-transform: uppercase;
}


.daterangepicker {
  .daterangepicker_start_input,
  .daterangepicker_end_input,
  .ranges > ul > li:last-child {
    display: none;
  }

  .icon-arrow-right,
  .icon-arrow-left {
    font-family: "Font Awesome 5 Pro" !important;
  }

  .icon-arrow-left:before {
    content: @fa-var-angle-left !important;
  }

  .icon-arrow-right:before {
    content: @fa-var-angle-right !important;
  }
}

.datepicker_range + .input-group-addon {
  cursor: pointer;
}

// in disabled state
.form-control[mvsc-date-range][readonly],
.form-control[mvsc-date-range-picker-modal][readonly],
.form-control[mvsc-date-range-picker2][readonly],
.form-control.datepicker_single[readonly],
.form-control.datepicker_range[readonly] {
  background-color: #fff;
  cursor: pointer;
}

// Modal daterangepicker
.mvsc-date-range-embedded {
  .modal-dialog {
    width: auto;
    text-align: center;

    .modal-content {
      display: inline-block;
    }
  }

  .modal-body {
    padding: 8px 50px 45px 50px;
  }

  h2 {
    margin-bottom: 20px;
    text-align: left;
  }

  .dropdown-menu {
    float: none;
    border: none;
    background: none;
    z-index: 1;
    box-shadow: none;
  }

  .input-group {
    display: none !important;
  }

  .daterangepicker {
    padding-top: 33px !important;
    padding-bottom: 100px;
    border-top: 1px solid #e0e3e6 !important;

    overflow: hidden;
    position: relative;
    left: 0 !important;
    top: 0 !important;

    &:before,
    &:after {
      display: none;
    }

    .calendar {
      margin: 0;
      border: none;
      padding: 0;

      .month {
        font-size: 16px;
      }
    }

    .calendar.left {
      margin-right: 32px;
    }

    .ranges {
      display: none;

      width: auto;
      padding: 0;

      min-height: 0;

      &:after {
        bottom: 69px;
        background-color: #e0e3e6;
      }

      ul {
        margin-left: 40px;
        padding-left: 40px;
        border-left: 1px solid #eff1f2;
      }

      li {
        width: 114px;
      }
    }

    .range_inputs {
      left: 0;
      bottom: 1px;
    }

    .applyBtn,
    .clearBtn {
      width: 87px;
    }
  }
}

// Single datepicker
.mvsc-date-range-embedded_datesingle {
  .modal-header .close,
  h2 {
    display: none;
  }

  .calendar {
    margin-right: 0 !important;
  }

  .daterangepicker {
    padding-top: 17px !important;
    padding-bottom: 0 !important;
    border-top: 0 !important;
  }
}

//Range picker modal width fix
.mvsc-date-range-embedded_daterange {
  .modal-dialog {
    min-width: 845px;
  }
}
