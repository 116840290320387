/* Mixins ---------------- */
.filter (@filter) {
    filter: @filter;
}

.text-shadow(none) {
    text-shadow: none;
}

.text-shadow(@style, @c) when (iscolor(@c)) {
    text-shadow: @style @c;
}

.text-shadow(@style, @alpha: 50%) when (isnumber(@alpha)) {
    .text-shadow(@style, rgba(0, 0, 0, @alpha));
}

// Smooth property transition
.standard-transition (@properties) {
    /* autoprefixer: off */
    transition-property: @properties;
    transition-duration: .15s;
}

// ended here
.text-overflow(@width) {
    overflow: hidden;
    display: block;
    width: @width;
    white-space: nowrap;
    text-overflow: ellipsis;
}

// Copy Paste from Bootstrap mixins.less
.retina-img(@file-1x; @file-2x; @width-1x; @height-1x;) {
    background-image: url("@{file-1x}");

    @media
    only screen and (        min-device-pixel-ratio: 2),
    only screen and (                min-resolution: 192dpi),
    only screen and (                min-resolution: 2dppx) {
        background-image: url("@{file-2x}");
        background-size: @width-1x @height-1x;
    }
}

.vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.vertical-align_absolute {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.horisontal-align {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}

.horisontal-align_absolute {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

.vh-align {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.vh-align_absolute {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.cs-input-select-group {
    .input-select-group {
        display: flex;
        padding: 0 15px;

        [class^="col"] {
            padding: 0;
            min-width: 0;
        }

        mvsc-form-control2,
        mvsc-vin-control {
            .input-group {
                width: 100%;
            }

            &:nth-child(1) {

                ::ng-deep {
                    .form-control,
                    .form-control.ng-select .ng-select-container {
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                    }

                    .form-control.ng-select .ng-dropdown-panel {
                        min-width: 100%;
                        width: fit-content;
                    }
                }
            }

            &:nth-child(2) {

                ::ng-deep {
                    .form-control {
                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        margin-left: -1px;
                    }

                    .required::after {
                        display: none;
                    }
                }
            }

            .mvscSpinner {
                z-index: 5;
            }
        }
    }
}
