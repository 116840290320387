.apps {
  &.row {
    margin: 0 -10px;

    .col-xs-3,
    .col-xs-12 {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .apps-app {
    @app-logoPath: '@{assets-path}img/applications/logo/';

    display: block;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
    padding: 15px;
    height: 130px;
    text-align: center;
    border: 2px solid @well-border;
    background-color: @well-bg;
    word-break: break-word;
    border-radius: 5px;
    transition: background-color .3s;

    &:hover {
      background: darken(@well-bg, 4%);
      transition: none;
    }

    &_success {
      border-color: @color-status-success;
    }

    &_pending {
      border-color: @color-status-warning;
    }

    .apps-app-state {
      position: absolute;
      top: 8px;
      left: 9px;
    }

    .apps-app-status {
      position: absolute;
      bottom: 13px;
      left: 0;
      right: 0;
      line-height: 16px;
    }

    .apps-app-logo {
      display: inline-block;
      font-size: @fontSize-base + 10;
      text-decoration: none;
    }

    &_carfax {
      .apps-app-logo {
        width: 134px;
        height: 26px;
        text-indent: -1000px;
        .retina-img('@{app-logoPath}carfax.png', '@{app-logoPath}carfax_2x.png', 134px, 26px);
      }
    }

    &_autocheck {
      .apps-app-logo {
        width: 140px;
        height: 31px;
        text-indent: -1000px;
        .retina-img('@{app-logoPath}autocheck.png', '@{app-logoPath}autocheck_2x.png', 140px, 31px);
      }
    }

    &_nmvtis {
      .apps-app-logo {
        width: 124px;
        height: 51px;
        text-indent: -1000px;
        .retina-img('@{app-logoPath}nmvtis.png', '@{app-logoPath}nmvtis_2x.png', 124px, 51px);
      }
    }

    &_nationwidedirect {
      .apps-app-logo {
        width: 73px;
        height: 61px;
        text-indent: -1000px;
        .retina-img('@{app-logoPath}dmvNationwide.png', '@{app-logoPath}dmvNationwide_2x.png', 73px, 61px);
      }
    }
  }
}

.appDescription {
  font-size: @fontSize-base + 1;

  h3 {
    margin-top: 0;
  }

  .alert {
    font-size: @fontSize-base;

    h4 {
      font-weight: 500;
      font-size: @fontSize-base + 1;
      text-transform: uppercase;
    }
  }
}

.appSubscription {
  h3 {
    margin-bottom: 17px;
  }

  .entity-actions {
    .fa-warning {
      margin-right: 5px;
    }
  }
}
