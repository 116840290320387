/* Wizard ---------------- */
// list with steps indicators
// we use it in generate RS flow

.wizard {
  margin-bottom: 25px;
  display: table;
  table-layout: fixed;
  width: 100%;
  padding: 0;
  list-style-type: none;
}

.wizard-step {
  position: relative;
  display: table-cell;
  padding: 0;

  &:not(:last-child):after {
    content: '';
    position: absolute;
    left: 21px;
    top: 9px;
    right: 11px;
    height: 1px;
    background: @well-border;
  }
}


.wizard-step-title {
  margin-top: 3px;
  display: block;
  line-height: 22px;

  .text-center {
    display: inline-block;
    position: relative;
    transform: translateX(-50%);
    right: -6px;
  }
}

//centred design hotfix
.col-xs-6.col-xs-offset-4 {
  .wizard {
    position: relative;
    right: -35px;
  }
}

@keyframes shadowPulse_active {
  0% {

    box-shadow: 0 0 0 0 rgba(236,111,70, 0.5);
  }
  70% {

      box-shadow: 0 0 0 10px rgba(236,111,70, 0);
  }
  100% {

      box-shadow: 0 0 0 0 rgba(236,111,70, 0);
  }
}

@keyframes shadowPulse_final {
  0% {

    box-shadow: 0 0 0 0 rgba(42,150,41, 0.5);
  }
  70% {

      box-shadow: 0 0 0 10px rgba(42,150,41, 0);
  }
  100% {

      box-shadow: 0 0 0 0 rgba(42,150,41, 0);
  }
}

@keyframes shadowPulse_error {
  0% {

    box-shadow: 0 0 0 0 rgba(215,38,45, 0.6);
  }
  70% {

      box-shadow: 0 0 0 8px rgba(215,38,45, 0);
  }
  100% {

      box-shadow: 0 0 0 0 rgba(215,38,45, 0);
  }
}

.status-tracker {
  display: flex;
  flex-direction: row;
  text-align: center;
  position: relative;
  margin: 20px 0;

  .status-tracker__item {
    flex: 1;
    padding-top: 25px;
    position: relative;
    color: @text-muted;

    .status-tracker__icon {
      padding: 12px;
      display: inline-block;
      background: #fff;
      margin-top: -6px;
      z-index: 1;
      position: relative;

      .fa {
        margin-bottom: 26px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        color: @color-status-info;
        background: #E5F6FF;
        line-height: 58px;
        text-align: center;
        font-size: 26px;

        &-file-export {
          font-size: 22px;
        }
      }
    }

    &:not(:first-child):before {
      display: block;
      content: '';
      height: 2px;
      border-top: 2px dashed #21BAE7;
      position: absolute;
      top: 60px;
      left: -50%;
      right: 50%;
    }

    &_complete {

      &:before {
        border-top: 2px solid #21BAE7 !important;
      }
    }

    &_active {
      .status-tracker__icon {
        .fa {
          background: @color-status-init;
          color: #fff;
          animation: shadowPulse_active 1s infinite;
        }
      }
    }

    &_final {
      .status-tracker__icon {
        .fa {
          background: @color-status-success;
          animation: shadowPulse_final 1s infinite;
          color: #fff;
        }
      }
    }

    .status-tracker__info {
      position: absolute;
      right: -50%;
      top: 12px;
      z-index: 2;
      width: 100%;
      &.status-delivery {
        top: 73px;
        .fa-truck {
          font-weight: 400;
        }
        span {
          color: #296189;
          border-bottom: 1px dashed #296189;
          cursor: default;
          outline: none;
        }
        .popover {
          min-width: 0 !important;
        }
      }

      strong {
        font-weight: 500;
      }
    }

    .status-tracker__date {
      position: relative;
      margin-top: -22px;
      margin-bottom: 6px;
      z-index: 1;
    }

    .status-tracker__break {
      z-index: 1;
      position: absolute;
      left: -50%;
      top: 20px;
      text-align: center;
      width: 100%;

      &:before {
        display: block;
        content: '';
        height: 2px;
        border-top: 2px solid #21BAE7;
        position: absolute;
        top: 40px;
        left: 42px;
        right: 50%;
      }

      .fa {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        color: #fff;
        background: @color-status-danger;
        line-height: 25px;
        text-align: center;
        font-size: 13px;
        position: relative;
        z-index: 1;
        position: relative;
        outline: 6px solid #fff;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          animation: shadowPulse_error 1s infinite;
          border-radius: 50%;
        }

      }

      .pseudoLink {
        margin-bottom: 10px;
        font-size: 13px;
      }
    }

    .status-tracker__address {
      margin-bottom: 7px;
    }

    .pseudoLink {
      font-size: 15px;
      font-weight: 500;
      margin-bottom: 4px;
    }

  }
}
