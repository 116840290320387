// Badges
.badge {
  height: 18px;
  padding: 3px 7px 3px 7px;
  text-align: center;
  position: relative;
  left: -4px;
  top: -7px;
  margin-right: -8px;
  text-shadow: none;
  font-size: 12px;
  font-weight: 500;

  .badge-group.badge_inline > &,
  &.badge_inline {
    left: 0;
    top: -1px;
    margin-right: 1px;
    margin-left: 1px;
    font-size: 10px;
    padding: 3px 5px 1px 5px;
    height: 15px;
    font-weight: 500;
  }


  .badge-group.badge_mini > &,
  &.badge_mini {
    height: 14px;
    min-width: 14px;
    padding: 3px;
    line-height: 7px;
    font-size: 11px;

    &.badge_mini_bordered {
      height: 20px;
      min-width: 20px;
      border: 3px solid #fff;

    }

    &.badge_mini_fa {
      .fa {
        position: relative;
        margin: -2px;
        font-size: 11px;
      }
    }
  }

  .badge-group.badge_micro > &,
  &.badge_micro {
    height: 12px;
    min-width: 12px;
    padding: 2px;
    line-height: 7px;
    font-size: 10px;
  }

  &.badge_default {
    background-color: @text-muted;
  }

  &.badge_danger {
    background-color: @color-status-danger;
  }

  &.badge_success {
    background-color: @color-status-success;
  }

  &.badge_warning {
    background-color: @color-status-warning;
  }

  &.badge_info {
    background-color: @color-status-info;
  }

  &.badge_pending {
    background-color: @color-status-pending;
  }

  &.badge_init {
    background-color: @color-status-init;
  }

  &.badge_empty {
    top: -10px;
    left: -1px;
    margin-right: 1px;
    padding: 0;
    min-width: 0;
    width: 8px;
    height: 8px;
    display: inline-block;
    border-radius: 8px;
  }
}

.badge-group {
  position: relative;
  display: inline-block;
  overflow: visible;
  font-size: 1px;
  line-height: 1;

  .badge {

  }
  .badge + .badge,
  .badge + .badge-group,
  .badge-group + .badge,
  .badge-group + .badge-group {
    margin-left: -1px;
  }

  > .badge:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  > .badge:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    margin-right: 0;
  }

  > .badge:not(:first-child):not(:last-child) {
    border-radius: 0;
    margin-right: 0;
  }
}


// Rounded Badges in IE8
html.ie8 {
  .badge {
    & {
      z-index: 2;
      padding: 3px 0;
      min-width: 5px !important;
      width: auto;
    }


    &.badge_mini {
      padding: 2px 0 2px 0;
      &:before,
      &:after {
        top: 2px;
        font-size: 16px;
      }
    }

    &.badge_micro {
      padding: 1px 0 1px 0;
      &:before,
      &:after {
        top: 2px;
        font-size: 14px;
      }
    }

    &.badge_inline {
      padding: 1px 0;
      top: 0;
      margin-left: 6px;
      margin-right: 6px;
      line-height: 9px;
      &:before,
      &:after {
        top: 3px;
        font-size: 18px;
      }
    }

    &:before,
    &:after {
      position: relative;
      content: @fa-var-circle;
      font-family: "Font Awesome 5 Pro";
      z-index: -1;
      color: @badge-bg;
      margin-left: -12px;
      margin-right: -12px;
      line-height: .5;
      top: 1px;
      font-size: 21px;
    }
  }


  .badge_default:before,
  .badge_default:after {
    color: @text-muted;
  }

  .badge_danger:before,
  .badge_danger:after {
    color: @color-status-danger;
  }

  .badge_success:before,
  .badge_success:after {
    color: @color-status-success;
  }

  .badge_warning:before,
  .badge_warning:after {
    color: @color-status-warning;
  }

  .badge_info:before,
  .badge_info:after {
    color: @color-status-info;
  }

  .badge_init:before,
  .badge_init:after {
    color: @color-status-init;
  }
}
