.bundles {
  .bundles-createBundleBtn {
    margin-bottom: 10px;
  }

  .bundles-bundle {
    .col-xs-2 {
      position: relative;
      .mvscSpinner {
        position: absolute;
        top: 12px;
        left: 2px;
      }
    }
  }

  .mvscSpinner {
    &_position {
      display: block;
      margin: 160px auto 0;
    }
  }

  .bundles-bundleWrapper {
    & > td {
      // !: couldn't find another solution, collapsed block is causing parent row jumping
      // if 'jumping' is back — just fix the height's value
      height: 54px;
      padding: 0;
    }

    table {
      margin-bottom: 0;
      border: none;
      background-color: transparent;

      &:hover {
        background-color: #f5f5f5;

        & + .bundles-collapse .subContent-item:first-of-type {
          border-color: #f5f5f5;
          border-top-width: 5px;
        }
      }

      td {
        border: none;
      }

      & + .bundles-collapse .subContent-item:first-of-type {
        border-top-width: 5px;
        border-color: #ffffff;
      }
    }

    .btn {
      position: relative;
      top: 5px;
      margin-top: -5px;
      margin-bottom: -7px;

      //to delete
      .fa-mvsc-ups {
        position: relative;
        margin: -1px -2px -1px -2px;
        font-size: 16px;
        bottom: -4px;
      }
      // /to delete

      .fa-mvsc-fedex {
        vertical-align: middle;
        position: relative;
        font-size: 16px;
        margin: 0 8px;
        position: relative;
        &::before {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
        }
      }
    }

    .btn-white + .btn-white {
      margin-left: 17px;
    }

    .bundles-collapse {
      position: relative;
      top: -9px;
      margin-bottom: -9px;
    }

    .subContent {
      top: 1px;
      margin-bottom: 0;
      max-height: none;

      .subContent-item:hover {
        background-color: transparent;
      }

      .subContent-item:first-child {
        td {
          padding-top: 11px;
        }
      }
    }

    &:hover > td {
      background-color: transparent;
    }
  }

  .subContent-item.bundles-deal {
    .table-hover > tbody > tr:hover > td {
      background-color: #ebebeb;
    }
    &:hover {
      .table-clickable {
        background-color: #ebebeb;
      }
    }
  }

  .ng-select-opened {
    .ng-arrow-wrapper {
      border-left: 0;
    }

    .ng-dropdown-panel {
      border-top: 0;
    }
  }

  .ng-dropdown-panel .ng-dropdown-header {
    border-bottom: 0;
    padding: 4px 4px 0 4px;
    margin-bottom: 10px;
  }

  .input-search {
    border: 1px solid #d8d8d8;
    border-radius: 2px;
    background: #fff url('@{assets-path}img/select2/select2.png') no-repeat 100% -22px;
    color: #333;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    outline: none;
    height: 26px;
    padding: 4px 20px 4px 5px;
    width: 100%;

    &:focus {
      border-color: #999;
      outline: 0;
    }
  }

  .checkbox {
    label {
      padding-left: 24px !important;
      height: 24px !important;
      margin-top: -3.5px;
      &:before {
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin-top: 0;
      }
      &::after {
        left: 6.5px;
        top: 50%;
      }
    }
  }

  .subContent:before {
    background-image: url('@{assets-path}img/dropdown-menu-subContent-bundel.png');
  }
}

