.collapse-toggle {
  font-weight: 700;
  position: relative;
  cursor: pointer;
  margin-bottom: 6px;

  &.collapse-toggle_light {
    font-weight: normal;
    color: @gray-dark;
  }

  .collapse-toggle-text_collapsed {
    display: none;
  }

  .collapse-toggle-text_expanded {
    display: inherit;
  }
  
  /*TEMPORARY before killing all .pseudoLink in .collapse-toggle*/
  &.pseudoLink {
    
    &:after {
      position: relative;
      border: none;
    }
  }
  /* /TEMPORARY */
  
  &:after {
    content: '\f106';
    display: inline-block;
    margin-left: 8px;
    font-weight: normal;
    font-size: 14px;
    font-family: "Font Awesome 5 Pro";
    font-weight: 900;
    text-align: center;
    color: darken(@input-border, 10%);
    .animation('fadeIn .2s');
  }

  &.collapsed {
    .collapse-toggle-text_collapsed {
      display: inherit;
    }
    
    &:after {
      content: '\f107';
    }

    .collapse-toggle-text_expanded {
      display: none;
    }
  }
}