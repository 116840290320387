/* mvscCards ---------------- */

/* Cards in grid context */
.mvscCards {
  margin: -8px -8px 15px -8px !important;
  margin: 0 -8px 15px -8px !important;
 
  .mvscCard {
    margin: 8px;
    vertical-align: top;
  }
  
  &.row {
    .mvscCard {
      margin-left: 0;
      margin-right: 0;
    }
  }
}

.mvscCards-wrapper:not(:last-child) .mvscCards-form,
.mvscCards-form_marginBottom {
  margin-bottom: 40px;
}

/*
.mvscCards-form {
  h1 {
    margin-bottom: 20px;
  }
  
  &:not(:last-child) {
    margin-bottom: 40px;
  }
}
*/

/* End of Cards in grid context */

/* Cards in form-group context */
.form-group {
  .mvscCards {
    margin-top: -8px !important;
    margin-bottom: -8px !important;
  }
}
/* End of Cards in form-group context */


/* Card common styles */
.mvscCard {
  position: relative;
  display: inline-block;
  border: 1px solid @input-border;
  border-radius: @border-radius-base;
  background-color: darken(@color-white, 2%);
  
  .subInfo,
  .subInfo * {
    word-wrap: break-word;
  }
  
  .mvscCard-title-title {
    font-weight: 500;
    .ellipsis;
    max-width: 74%;
    width: auto;
  }
  
  // Hide actions and title links
  .mvscCard-action,
  .mvscCard-title_edit,
  .mvscCard-subNav {
    display: none;
  }
}

// Actions styles
.mvscCard-action {
  &_add {
    color: darken(@input-border, 10%);
    transition: color ease-in-out .15s;
      
    &.pseudoLink {
      color: @color-link;
    }
  }

  &_undo,
  &_remove {
    color: @color-white;
    background-color: darken(@input-border, 10%);
    transition: background-color ease-in-out .15s;
  }

  &_corner, {
    font-size: 10px;
    line-height: 12px;
    height: 15px;
    padding: 2px 5px 0 5px;
    position: absolute;
    overflow: hidden;
    right: -7px;
    top: -7px;
    text-decoration: none;
    border-radius: 99px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
    color: @color-white;
    
    &:hover, &:focus {
      color: @color-white;
      text-decoration: none;
    }
    
    .fa {
      position: relative;
      top: 0px;
      margin: -2px;
      font-size: 11px;
      line-height: 12px;
    }
      
    .fa + * {
      margin-left: 5px;
    }
  }
}
/* End of Card common styles */


/* Cards Sizes */

// Regular Card
.mvscCard_regular {
  height: 97px; 

  .mvscCard-title {
    padding: 10px 10px 3px 10px;
    line-height: 19px;
  }
    
  .subInfo {
    padding: 0 10px;
      
    .fa {
      position: relative;
        
      margin-right: 3px;
      margin-top: 0;
        
      font-size: 12px;
    }
      
    .iconed-icon {
      position: absolute;
    }
  }
    
  .mvscCard-subNav {
    position: absolute;
    bottom: 8px;
    padding: 0 10px;
    font-size: @fontSize-base - 1;
      
    a {
      margin-right: 1em;
    }
      
    .fa {
      margin-right: 6px;
      font-size: @fontSize-base - 2;
    }
  }
    
  .mvscCard-title-favorites,
  .mvscCard-title-warning,
  .mvscCard-title-edit {
    float: right;
    margin-left: 8px;
  }
    
  .mvscCard-title-favorites {
    margin-top: 2px;
  }

  .mvscCard-title-warning {
    position: relative;
    top: 1px;
    color: @text-muted;
  }
}

// Regular, Role and Report Cards
.mvscCard_regular,
.mvscCard_role,
.mvscCard_report {
  width: 100%;
  display: block;
  
  .mvscCard-title {
    display: block;
  }
  
  a.mvscCard-title {
    cursor: pointer;
    text-decoration: none;
    text-align: center;
  }
    
  .subInfo {
    font-size: @fontSize-base - 1;
    line-height: 16px;
  }
}
  
// Role Card (Biggest size)
.mvscCard_role {
  height: 154px;
    
  &:not(.mvscCard_disabled):not(.mvscCard_deleted):not(.mvscCard_add):not(.mvscCard_active) {
    cursor: pointer;
  }
    
  &.mvscCard_active.mvscCard_add {
    .mvscCard-title {
      cursor: default;
    }
  }
    
  &:not(.mvscCard_add){
    
    .mvscCard-title {
      background: @color-white;
      border-bottom: 1px solid @input-border;
    }
  }
    
  .mvscCard-title {
    padding: 13px 15px 10px 15px;
  }
    
  .mvscCard-title-title {
    margin-bottom: 2px;
    display: block;
    font-size: @fontSize-base + 7;
    font-weight: normal;
    line-height: @fontSize-base + 10;
    color: @color-link;
    max-width: 100%;
  }
    
  .badge {
    margin-right: 3px;
  }
    
  .subInfo {
    padding: 8px 15px 0 15px;
  }
    
  .fadeContent {
    height: 75px;
      
    &:after {
      background: linear-gradient(transparent, darken(@color-white, 2%));
    }
  }
}
  
// Mini Card
.mvscCard_mini {
  width: 185px;
  height: 32px;
  transition: border-color ease-in-out .15s;
    
  .mvscCard-title {
    text-align: center;
    cursor: default;
    line-height: 19px;
    padding: 6px 0 5px 0;
    display: block;
  }
    
  a.mvscCard-title {
    cursor: pointer;
    text-decoration: none;
  }
}

// mini_select
.mvscCard_mini.mvscCard_mini_select {
  border: none;
  
  .mvscCard-title {
    padding-top: 0;
    padding-bottom: 0;
  }
  
  select {
    display: inline-block;
  }
}
/* End of Cards Sizes */

/* Add Cards */
.mvscCard_add {
  &.mvscCard_regular {
    a.mvscCard-title {
      padding: 38px 10px;
    }
  }
  
  &.mvscCard_mini {
    a.mvscCard-title {
      padding: 6px 0 5px 0;
    }
  }
  
  &.mvscCard_role {
    a.mvscCard-title {
      padding: 66px 15px;
    }
  }
  
  &.mvscCard_report {
    a.mvscCard-title {
      padding: 56px 15px;
    }
  }
  
  &.mvscCard_add_required {
    .mvscCard-title:after {
      content: '*';
      .text-danger();
    }
  }
}

/* Card Actions */
.mvscCard_showDelete {
  .mvscCard-action_remove {
    display: inline-block;
  }
}
  
.mvscCard_showUndo {
  .mvscCard-action_undo {
    display: inline-block;
  }
}
  
.mvscCard_showEdit {
  .mvscCard-title-edit {
    display: inline-block;
  }
}

.mvscCard_showFavorites {
  .mvscCard-title-favorites {
    display: inline-block;
  }
}
  
.mvscCard_add {
  .mvscCard-action_add {
    display: inline-block;
  }
}
  
.mvscCard_showSubNav {
  .mvscCard-subNav {
    display: block;
  }
}
/* End of Card Actions */


/* Hide Cards elements */
.mvscCard_hideSubInfo {
  .subInfo {
    display: none;
  }
}
/* End of Hide Cards elements */

/* Card Statuses */
// deleted
.mvscCard_deleted {
  .mvscCard-title-title,
  .subInfo {
    text-decoration: line-through;
  }
}
   
// disabled and deleted
.mvscCard_deleted,
.mvscCard_disabled {
  .mvscCard-title-title,
  .mvscCard-title-title.pseudoLink,
  .mvscCard-title-favorites,
  .subInfo,
  .subInfo a,
  .mvscCard-subNav {
    color: @text-muted;
    &:not(.mvscCard-title-title) {
      opacity: .5;
    }
  }
  
  .mvscCard-title-title.pseudoLink:after {
    border-color: @text-muted;
  }
  
  .favoritesLinkIco.favoritesLinkIco_checked {
    color: @color-muted;
  }    
  
  .badge.badge_inline {
    background-color: darken(@input-border, 10%);
  }
}
  
// disabled
.mvscCard_disabled {
  cursor: default;
    
  .mvscCard-title-edit {
    opacity: .5;
  }
    
  &:after {
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 10;
    content: '';
  }
    
  .mvscCard-title[href] {
    cursor: default;
    .mvscCard-action {
      display: inline;
    }
    .pseudoLink {
      display: inline-block;
    }
  }
}
  
// active
.mvscCard_active {
  border-style: solid;
  border-color: @input-border-focus;
    
  .mvscCard-action {
    &_remove {
      background-color: @color-status-danger;
    }
    &_undo {
      background-color: @color-status-danger;
    }
  }
    
  .mvscCard-title {
    cursor: text;
            
    .pseudoLink {
      color: @color-base;
        
      &:after {
        display: none;
      }
    }
  }
  
  &.mvscCard_add {
    a.mvscCard-title {
      cursor: default;
    }
  }
}

// hover
.mvscCard:hover:not(.mvscCard_disabled):not(.mvscCard_deleted) {
  border-color: @input-border-focus;
}
  
.mvscCard:hover:not(.mvscCard_disabled):not(.mvscCard_active) {
  .mvscCard-action {
    &_add:not(.pseudoLink) {
      color: @color-status-success;
    }

    &_remove,
    &_undo {
      background-color: @color-status-danger;
    } 
  }
    
  a.mvscCard-title {
    .pseudoLink:after {
      display: none;
    }
  }
}
/* End of Card Statuses */


//One click reports card
.mvscCard_report {
  height: 132px;
  
  .mvscCard-title-title {
    max-width: 87%;
  }
    
  &:not(.mvscCard_disabled):not(.mvscCard_deleted):not(.mvscCard_add) {
    
    &:hover {
      .mvscCard-subNav a {
        color: lighten(@color-base, 40%);
        
        &:hover {
          color: @color-white;
        }
      }
    }
  }
    
  &.mvscCard_active.mvscCard_add {
    .mvscCard-title {
      cursor: default;
    }
  }
    
  &:not(.mvscCard_add){
    
    .mvscCard-title {
      background: @color-white;
      border-bottom: 1px solid @input-border;
    }
  }
    
  .mvscCard-title {
    padding: 17px 15px 14px 15px;
  } 
  
  .mvscCard-title-title {
    margin-bottom: 2px;
    font-size: @fontSize-base + 5;
    font-weight: normal;
    line-height: @fontSize-base + 10;
    
    & a {
      color: @color-link;
    }
  }
  
  .mvscCard-subNav {
    padding: 0 15px;
    
    a {
      display: inline-block;
      margin-left: -4px;
      margin-right: 3px;
      padding: 0 4px;
      height: 19px;
      line-height: 20px;
      color: @color-muted;
      
      &:hover {
        background: @color-status-success;
        text-decoration: none;
        border-radius: @border-radius-base;
      }
    }
  }
    
  .badge {
    margin-right: 3px;
  }
    
  .subInfo {
    padding: 8px 15px 0 15px;
    
    .fa-pencil {
      font-size: @fontSize-base;
      margin-left: 4px;
      
      &:not(:hover){
        color: @color-muted;
      }
    }
  }
}
  
  




