//
// Popovers
// --------------------------------------------------


.popover {
  position: absolute;
  top: 0;
  left: 0;
  z-index: @zindex-popover;
  display: none;
  max-width: @popover-max-width;
  padding: 1px;
  text-align: left; // Reset given new insertion method
  background-color: @popover-bg;
  background-clip: padding-box;
  border: 1px solid @popover-fallback-border-color;
  border: 1px solid @popover-border-color;
  border-radius: @border-radius-large;
  .box-shadow(0 5px 10px rgba(0,0,0,.2));

  // Overrides for proper insertion
  white-space: normal;

  // Offset the popover to account for the popover arrow
  &.top     { margin-top: -@popover-arrow-width; }
  &.right   { margin-left: @popover-arrow-width; }
  &.bottom  { margin-top: @popover-arrow-width; }
  &.left    { margin-left: -@popover-arrow-width; }
}

.popover-title {
  margin: 0; // reset heading margin
  padding: 8px 14px;
  font-size: @font-size-base;
  font-weight: normal;
  line-height: 18px;
  background-color: @popover-title-bg;
  border-bottom: 1px solid darken(@popover-title-bg, 5%);
  border-radius: 5px 5px 0 0;
}

.popover-content {
  padding: 9px 14px;
}

// Arrows
//

.popover {
  .popover-arrow {
    position: absolute;
    display: block;
    width: @popover-arrow-width;
    height: @popover-arrow-height;

    &::before,
    &::after {
      position: absolute;
      display: block;
      content: "";
      border-color: transparent;
      border-style: solid;
    }
  }
}

.bs-popover-top {
  > .popover-arrow {
    bottom: -@popover-arrow-height;

    &::before {
      bottom: 0;
      border-width: @popover-arrow-height (@popover-arrow-width * .5) 0;
      border-top-color: @popover-arrow-outer-color;
    }

    &::after {
      bottom: @popover-border-width;
      border-width: @popover-arrow-height (@popover-arrow-width * .5) 0;
      border-top-color: @popover-arrow-color;
    }
  }
}

.bs-popover-end {
  > .popover-arrow {
    left: -@popover-arrow-height;
    width: @popover-arrow-height;
    height: @popover-arrow-width;

    &::before {
      left: 0;
      border-width: (@popover-arrow-width * .5) @popover-arrow-height (@popover-arrow-width * .5) 0;
      border-right-color: @popover-arrow-outer-color;
    }

    &::after {
      left: @popover-border-width;
      border-width: (@popover-arrow-width * .5) @popover-arrow-height (@popover-arrow-width * .5) 0;
      border-right-color: @popover-arrow-color;
    }
  }
}

.bs-popover-bottom {
  > .popover-arrow {
    top: -@popover-arrow-height;

    &::before {
      top: 0;
      border-width: 0 (@popover-arrow-width * .5) @popover-arrow-height (@popover-arrow-width * .5);
      border-bottom-color: @popover-arrow-outer-color;
    }

    &::after {
      top: @popover-border-width;
      border-width: 0 (@popover-arrow-width * .5) @popover-arrow-height (@popover-arrow-width * .5);
      border-bottom-color: @popover-arrow-color;
    }
  }
}

.bs-popover-start {
  > .popover-arrow {
    right: -@popover-arrow-height;
    width: @popover-arrow-height;
    height: @popover-arrow-width;

    &::before {
      right: 0;
      border-width: (@popover-arrow-width * .5) 0 (@popover-arrow-width * .5) @popover-arrow-height;
      border-left-color: @popover-arrow-outer-color;
    }

    &::after {
      right: @popover-border-width;
      border-width: (@popover-arrow-width * .5) 0 (@popover-arrow-width * .5) @popover-arrow-height;
      border-left-color: @popover-arrow-color;
    }
  }
}
