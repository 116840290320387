/* Stickyfooter ---------------- */
html,
body {
  height: 100%;

  .pageWrapper {
    min-height: 100%;
    height: auto;
    margin: 0 auto -@footerHeight;
    padding: 0 0 @footerHeight;
    background-color: @color-white;
    
    &.pageWrapper_transparent {
      background-color: transparent;
    }
    
    &.pageWrapper_overview {
      background-color: @color-corp-lightGray;
    }
    
    // fix table-layout issue with firefox
    &.table {
      height: 100%;
    }
  }

  .footer {
    height: @footerHeight;
  }
}
