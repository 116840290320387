// Tiny grid for inline spacing
.row {
  &.mvscCards,
  &.row_tight {
    @gutter: 16px; // even number
    margin: 0 (-@gutter / 2);
    [class^="col-"] {
      padding-left: (@gutter / 2);
      padding-right: (@gutter / 2);
    }
  }

  &.row_tighter {
    @gutter: 10px; // even number
    margin: 0 (-@gutter / 2);
    [class^="col-"] {
      padding-left: (@gutter / 2);
      padding-right: (@gutter / 2);
    }
  }

  &.row_spaced {
    @gutter: 60px; // even number
    margin: 0 (-@gutter / 2);
    [class^="col-"] {
      padding-left: (@gutter / 2);
      padding-right: (@gutter / 2);
    }
  }

  // 16-column grid used to space 7 columns in table row details
  &.row_16 {

    @grid-columns: 16;
    @class: 16;
    @grid-gutter-width: 16px; // even number


    .calc-grid-column(@index, @class, @type) when (@type = width) and (@index > 0) {
      .col-@{class}-@{index} {
        width: percentage((@index / 16));
      }
    }

    .loop-grid-columns(@index, @class, @type) when (@index >= 0) {
      .calc-grid-column(@index, @class, @type);
      // next iteration
      .loop-grid-columns((@index - 1), @class, @type);
    }

    .make-row(@grid-gutter-width);
    .float-grid-columns(@class);

    .float-grid-columns(@class);
    .loop-grid-columns(@grid-columns, @class, width);

    [class*=col-16-] {
      padding-left:  (@grid-gutter-width / 2);
      padding-right: (@grid-gutter-width / 2);
      min-height: 1px;
    }
  }

  &.range {

    .col-xs-6:first-child:after {
      content: '—';
      position: absolute;
      top: 9px;
      right: -5px;
    }
  }
}

.row.row_mergedInputs {
  margin: 0;

  [class^="col-"] {
    padding: 0;

   input {
      border-radius: 0;
    }

    .input-group {
      margin-right: -1px;
    }

    [class^='col-xs-']:first-child {
      input {
        border-radius: @border-radius-base 0 0 @border-radius-base;
      }
    }

    [class^='col-xs-']:last-child {
      input {
        border-radius: 0 @border-radius-base @border-radius-base 0;
      }
    }
  }

  [class^='col-xs-'] {
    input {
      position: relative;
      z-index: 1;
    }
  }

  [class^='col-xs-']:not(:last-child) {
    input {
      border-right-width: 0;

      &:focus {
        z-index: 2;
        box-shadow: 1px 0px 0px 0px @input-border-focus;
      }
    }
  }
}

.col-xs-1_5 {
  width: 12.5%;
}

.col-xs-2_5 {
  width: 20.8333%;
}

.col-xs-4_5 {
  width: 37.5%;
}
