/* E-file forms preview */

.efilePreviewSection {
  position: relative;
  margin-bottom: 30px;
  border: 1px solid @hr-border-modal;

  h3 {
    position: absolute;
    left: 10px;
    top: -8px;
    margin: 0;
    padding-left: 10px;
    padding-right: 10px;

    color: @color-muted_light;
    font-weight: 500;
    font-size: @fontSize-base + 1;
    text-transform: uppercase;

    background-color: #fff;
  }

  .efilePreviewSection-primarySecondaryWrapper {
    padding: 20px 20px 1px;

    label {
      display: none;
    }

    .efilePreviewSection-entity {
      display: inline-block;

      &.efilePreviewSection-entity_primary {
        margin-right: 26px;

        .entity-value {
          font-size: @fontSize-base * 2 + 2;
          font-weight: 300;
        }
      }

      &.efilePreviewSection-entity_secondary {
        margin-right: 18px;

        .entity-value {
          font-size: @fontSize-base + 7;
        }

        .fa {
          margin-right: 2px;
          font-size: @fontSize-base + 5;
          .theme("default", {
            color: @themeBrand-color-corp-skyBlue;
          });
          .theme("black", {
            color: @themeBlack-color-corp-skyBlue;
          });
          .theme("classic", {
            color: @themeClassic-color-corp-skyBlue;
          });
        }

        &.efilePreviewSection-entity_secondary_noMarginRight {
          margin-right: 0;
        }
      }
    }
  }

  .efilePreviewSection-tertiaryWrapper {
    margin-top: 20px;
    padding: 10px 20px 0 20px;
    background-color: fadeout(@hr-border-modal, 84%);

    > .row:not(:first-child) {
      margin-top: 4px;
    }

    .form-group {
      margin-bottom: 11px;
    }

    label {
      margin-bottom: 1px;
      color: darken(@color-muted, 10%);
      font-size: 13px;
      line-height: 20px;
      white-space: normal;
    }

    .table {
      & > tbody > tr:first-child td {
        border-top: none;
      }
    }
  }

  .efilePreviewSection-primarySecondaryWrapper + .efilePreviewSection-tertiaryWrapper {
    margin-top: 0;
  }

  /* inline-block layout*/
  .row {
    &,
    ng-include {
      font-size: 0;
    }

    > *,
    ng-include > * {
      font-size: @fontSize-base;
    }

    mvsc-form-and-fees-transactions-dynamic-field {
      font-size: 0;
    }
  }

  [class^="col-xs"],
  [class*=" col-xs"] {
    float: none;
    display: inline-block;
    vertical-align: top;
    font-size: @fontSize-base;
  }
}
