// general

.status {
  &-white {
    border: 1px solid #ccc;
    background-color: #fff;
    color: #999;
  }

  &-light-blue {
    background-color: #7fa1ba;
  }

  &-blue {
    background-color: #21bae7;
  }

  &-dark-blue {
    background-color: #039ac8;
  }

  &-turquoise {
    background-color: #26BBB3;
  }

  &-red {
    background-color: #D7262D;
  }

  &-dark-red {
    background-color: #a94442;
  }

  &-light-green {
    background-color: #77aca6;
  }

  &-green {
    background-color: #2A9647;
  }

  &-light-yellow {
    background-color: #F4C242;
  }

  &-yellow {
    background-color: #ffb848;
  }

  &-gray {
    background-color: #999999;
  }

  &-orange {
    background-color: #f1703c;
  }
}

.status-available,
.status-noRecord,
.status-due,
.status-initial-quote {
  background-color: @color-status-available;
}

.status-inactive,
.status-cancelled,
.status-voided,
.status-readyfor,
.status-sentback,
.status-duplicate,
.status-deleted,
.status-suspended {
  background-color: @color-border_dark;
}

.status-sold,
.status-received,
.status-out-of-state,
.status-manual,
.status-perfected {
  background-color: @color-status-sold;
}

.status-init,
.status-progress,
.status-reported,
.status-in-progress,
.status-submitted,
.status-release-pending {
  background-color: @color-status-init;
}

.status-pending,
.status-draft {
  background-color: @color-status-pending;
}

.status-failed,
.status-discrepancy,
.status-error {
  background-color: @color-status-danger;
}

.status-queue-pfo,
.status-rdf,
.status-senttodmv,
.status-at-dmv,
.status-no-record {
  background-color: @color-status-rdf;
}

.status-queue-clear,
.status-cleared,
.status-released,
.status-confirmed,
.status-current,
.status-completed {
  background-color: @color-status-success;
}

.status-unwind,
.status-pre-audit-completed {
  background-color: @color-status-unwind;
}

.status-unconfirmed,
.status-filing-init,
.status-pre-audit-in-progress {
  background-color: @color-status-warning;
}

.status-billed,
.status-pre-audit-errors-found {
  background-color: @color-status-billed;
}


// tickets specific

.status-ticket-new {
  .status-failed();
}

.status-ticket-inprogress {
  .status-init();
}

.status-ticket-closed {
  .status-voided();
}


// files specific

.status-file-pdf {
  .status-failed();
}

.status-file-url {
  .status-rdf();
}


//

.status-released_white {
  border: 1px solid #ccc;
  background-color: #fff;
  color: #999;
  //font-weight: normal;
}
