/*
  Override bootstrap tables classes
  Set MVSC tables modifiers
  Set 'table-manageEntities', todo: rename and exclude
  Set Popovers in tables
*/

/*
  Override bootstrap tables classes
*/
// why there are two separate blocks of styles?
// todo: refactor following .tables styles
// use PhantomCSS to test markup after changes are done
.table {
  > thead,
  > tbody,
  > tfoot {
    > tr {
      > th,
      > td {
        border-top: 1px solid @table-border-color;
        border-bottom: 1px solid @table-border-color;
        line-height: 18px;

        &.limit-length-column {
          min-width: 170px;
          max-width: 235px;
        }
      }

      &.opened {
        > td {
          border-bottom: none;
        }

        &:hover > td {
          background-color: transparent;
        }
      }

      &.details {
        &:hover > td {
          background-color: transparent;
        }

        & > td {
          padding-top: 20px;
          padding-bottom: 5px;
        }

        .row {
          margin-bottom: 25px;

          dl {
            margin-bottom: 0;
          }
        }
      }

      > th {
        vertical-align: top;
        font-size: @font-size-base - 2;
        font-weight: 700;
        color: #000;
        border-bottom: 1px solid darken(@table-border-color, 10%);
        text-transform: uppercase;
        line-height: 16px;
      }
      > td.text-middle {
        .text-middle;
      }

      > td.active,
      > th.active,
      &.active > td,
      &.active > th {
        color: @component-active-color;
        border-left-width: 0;
        border-right-width: 0;
      }
    }
  }

  // add top border to the firt row of the footer
  > tfoot > tr:first-child > td {
    border-top: 1px solid darken(@table-border-color, 10%);
  }

  // remove last tbody row bottom border if there is a footer
  > tbody > tr:last-child > td {
    border-bottom: none;
  }

  // remove bottom border
  > tfoot > tr:last-child,
  tr.tr_noBorder {
    > th,
    > td {
      border-bottom: none;
    }
  }


  // remove top border
  tr.tr_noBorderTop {
    > th,
    > td {
      border-top: none;
    }
  }

  tr.tr_noPaddingTop {
    td {
      padding-top: 0;
    }
  }

  tr.tr_noPaddingBottom {
    > th,
    > td {
      padding-bottom: none;
    }
  }

  tr.tr_noPaddingLeft {
    > th:first-child,
    > td:first-child {
      padding-left: 0;
    }
  }

  tr.tr_alignBottom {
    td, th {
      vertical-align: bottom;

      > * {
        vertical-align: bottom;
      }
    }
  }

  .tr_no

  td.hasInput {
    padding: 3px;
  }

  th.fitContent,
  td.fitContent {
    width: 1px;
  }

  a.table-entityLink {
//    .strong();
    //a > .reverse();
  }

  &.table_marginTop {
    margin-top: @line-height-computed;
  }
  &.table_zeroMargin {
    margin-bottom: 0;
    .fa-inbox {
      font-size: 78px;
    }
  }

  &.table_footerBordered {
    border-bottom: 1px solid @table-border-color;
  }

  &.table_thBg {
    > thead {
      th {
        background-color: desaturate(lighten(@color-corp-lightGray, 3%), 100%);
        line-height: 15px;
        white-space: nowrap;
      }
    }
  }

  &.table_alignBottom {
    th, td {
      vertical-align: bottom;
    }
  }

  &.table_alignMiddle {
    th, td {
      .text-middle;

      > * {
        .text-middle;
      }
    }
  }

  &.table_noThead {
    tbody > tr:first-child td {
      border-top: none;
    }

    tbody > .table-tr-subTitle:first-child {
      h3 {
        margin-top: 0;
      }
    }
  }

  label {
    font-size: 13px;
  }

  .table-tr-subTitle {
    h3 {
      margin: 16px 0 1px 0;
    }
  }

  .table-td_border {
    width: 2px;
    padding: 0 !important;
  }

  .table-row_highlighted {
    td,
    &:hover td {
      background: lighten(@color-status-success, 53%);;
    }
  }

  td {
    .badge {
      &_inline {
       margin-left: 1px;
       margin-right: 1px;
      }
    }
  }
}

.table {
  td,
  th {
    .checkbox:not(.form-group) {
      margin-top: 0;
      margin-bottom: -3px;
    }

    &.table-manageEntities-td_autopay .fa.fa-warning {
      margin-right: 3px;
      position: relative;
      bottom: -2px;
    }

    .tableContainer-negativeSpace {
      margin-left: -7px;
      margin-right: -7px;
    }

    .tableContainer-negativeSpace_condensed {
      margin-left: -5px;
      margin-right: -5px;

      &[class*="col-"] {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .tableContainer-negativeSpace_vertical {
      & > .table {
        margin-top: -7px;
        margin-bottom: 0;
      }
    }
  }
}

.table-hover {
  .table-hover-toggle {
    opacity: 0;
    transition: opacity 0.2s linear;
  }

  & > tbody > tr {
    & > td {
      .standard-transition(background-color);
    }

    &:hover {
      & > td {
        transition: none;
        .theme("default", {
          background: @themeBrand-tabs-tab-hover-bgcolor;
          border-left-color: @themeBrand-tabs-tab-hover-bgcolor;
        });
        .theme("black", {
          background: @themeBlack-tabs-tab-hover-bgcolor;
          border-left-color: @themeBlack-tabs-tab-hover-bgcolor;
        });
      }
    }
  }

  .table-row_highlighted:hover td {
    background: darken(lighten(@color-status-success, 53%), 2%);
  }
}
/* end Override bootstrap tables classes */



/*
  MVSC tables modifiers
  todo: set proper modifiers,
  e.g. table-negative > table_negative
*/
.table-negative {
  margin-left: -7px;
  margin-right: -7px;
}

.table-negative-condensed {
  margin-left: -5px;
  margin-right: -5px;
}

.table.table-borderless > tbody > tr > td {
  border-bottom: none;
  padding: 6px 5px;
}

.table.table_horSpaced {
  th,
  td {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.table.table_vertSpaced {
  td {
    padding-bottom: 25px;
  }
}

.table.table_vertCentered {
  td {
    padding-top: 15px;
    padding-bottom: 15px;
  }
}

.table_fixed {
  table-layout: fixed;
}

.table_normalized {
  th:first-child,
  td:first-child {
    padding-left: 0;
  }

  th:last-child,
  td:last-child {
    padding-right: 0;
  }
}

.table_customizable {
  thead {

    th:last-child {
      padding-right: 27px;

      .table_customizable-settingsLink {
        position: absolute;
        right: 9px;
        top: 9px;

        .fa {
          color: darken(@input-border, 10%);
        }

        &:hover {
          .fa {
            color: @color-muted;
          }
        }
      }
    }
  }

  & + .toothy_allShown {
    margin-top: -10px;
  }
}

.table_vertical-align {
  display: table;
  width: 100%;
  margin-bottom: 0;

  .table-cell {
    display: table-cell;
    vertical-align: middle;
  }
}

.table_borderless {
  th,
  td {
    border: none !important;
  }
}
/* end MVSC tables modifiers */


// todo: must be renamed
// and excluded into separate less document
.table-manageEntities {
  border-collapse: separate;

  thead {
    tr th {
      border: none;
      padding: 0 20px 16px 0;
    }
  }

  &:not(.table-manageEntities_edit) {
    tfoot td {
      padding-top: 15px;
    }
  }

  tbody {
    tr:first-child td:first-child {
      border-top-left-radius: @border-radius-base;
      &.td-danger {
        &::before {
          border-bottom-color: #D7262D;
        }
      }
    }

    tr:first-child td:last-child {
      border-top-right-radius: @border-radius-base;
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: @border-radius-base;
    }

    tr:last-child td:last-child {
      border-bottom-right-radius: @border-radius-base;
    }

    tr td {
      padding: 10px 20px;
      border-bottom: none;

      &:first-child {
        border-left: 1px solid @table-border-color;
      }

      &:last-child {
        border-right: 1px solid @table-border-color;
      }

      p {
        margin-bottom: 4px;
      }

      .subInfo {
        margin-bottom: 3px;
        line-height: 1.33;
      }
    }

    tr:first-child {

      td {
        border-top: 1px solid @table-border-color;
      }

      td:first-child {
        position: relative;

        &:before {
          position: absolute;
          top: -9px;
          left: 20px;
          display: inline-block;
          border-right: 8px solid transparent;
          border-bottom: 9px solid transparent;
          border-left: 8px solid transparent;
          border-bottom-color: @table-border-color;
          content: '';
        }

        &:after {
          position: absolute;
          top: -7px;
          left: 20px;
          display: inline-block !important;
          border-right: 8px solid transparent;
          border-bottom: 9px solid @color-white;
          border-left: 8px solid transparent;
          content: '';
        }
      }
    }

    tr:last-child {

      td {
        border-bottom: 1px solid @table-border-color;
      }
    }

    tr.table-row_highlighted td {
      &:first-child:after {
        border-bottom-color: lighten(@color-status-success, 53%);
      }
    }

    .table-manageEntities-undoBtn {
      display: none;
    }

    .table-row_deleted {
      .table-manageEntities-undoBtn {
        display: inline-block;
      }

      .table-manageEntities-deleteBtn {
        display: none;
      }

      td:first-child *:not(.pseudoLink) {
        color: @text-muted;
        text-decoration: line-through;
      }

      .onoff {
        opacity: .35;
      }
    }
  }

  tfoot {
    tr td, tr td:first-child {
      border: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.table-manageEntities_edit tfoot .table-row_actions td {
    border-top: 1px solid @table-border-color;
  }

  &.table-manageEntities_assign {

    tfoot tr:first-child td {
      padding-top: 0;
    }
  }
}



/*
  Popovers in tables
*/
// table-commentsPopover and table-feesPopover used for the same goal —
// to trigger popovers with comments (logbook) and fees (etx log + vehicle > transactions)
// todo: standardize the approach if a new popover is coming
.table-commentsPopover,
.table-feesPopover {
  position: relative;
  display: inline-block;

  .popover {
    // to hide these popovers under the header and actionsBar
    z-index: @zindex-navbar-fixed - 2;

    max-width: none;
    cursor: auto;

    /* based on left: 26px arrow's position */
    left: 50% !important;
    margin-left: -28px;

    font-family: @fontFamily-base;

    .arrow {
      left: 26px;
    }
  }
}

// read the comment above
.table-commentsPopover {
  top: -2px;
  margin-left: 3px;
  display: inline-block;

  & > .fa {
    margin: 0;
    color: darken(@input-border, 10%);
    cursor: pointer;

    &:hover {
      color: fade(darken(@input-border, 10%), 80%);
    }
  }

  .popover {
    width: 400px;
    margin: 6px 0 0;
    left: 0 !important;
  }
}

// read the comment above
.table-feesPopover {
  .popover {
    width: 305px;
  }

  table {
    td {
      padding: 2px 0 6px 0 !important;
    }
  }
}
/* end popovers in tables */

.table_vertical-align_top {
  td, th {
    vertical-align: top;
  }
}

.table tr.tr-subcontentWrapper {

  .subContent {
    max-height: none;
  }

  > td {
    padding: 0;

    .subContent {
      margin-bottom: 0;
    }

    .subContent-item:first-child td {
      padding-top: 15px;
    }

    .table {
      margin: 0;
      border: none;
      background: transparent;

      td {
        border: none;
      }
    }
  }
}

.solution {
  .table-clickable > tbody > tr:hover {
    cursor: default;
  }
}

.table-clickable {
  & > tbody > tr:hover {
    cursor: pointer;

    & > td > a.table-clickable__entityLink.pseudoLink {
      &:after {
        display: none;
      }
    }
  }

  & table:not(.table-clickable) {
    & > tbody > tr:hover {
      cursor: default;
    }
  }
}

.table-checkable {
  th.table-checkable__selectAll,
  td.table-checkable__selectAll {
    width: 14px;

    & + th,
    & + td {
      padding-left: 10px;

      .mvscSpinner {
        left: -8px !important;
      }
    }

    .checkbox {
      label {
        padding-left: 14px;
      }
    }
  }

  th.table-checkable__selectAll {
    .checkbox {
      margin-top: -3px;
    }
  }

  .table-checkable__row--checked {
    td {
      .theme("default", {
        background: @themeBrand-tabs-tab-hover-bgcolor !important;
      });
      .theme("black", {
        background: @themeBlack-tabs-tab-hover-bgcolor !important;
      });
    }

    td.table-td_border {
      border-top-style: double;
      .theme("default", {
        background: @themeBrand-color-corp-skyBlue !important;
        border-top-color: @themeBrand-color-corp-skyBlue;
        border-bottom-color: @themeBrand-color-corp-skyBlue;
      });
      .theme("black", {
        background: @themeBlack-color-corp-skyBlue !important;
        border-top-color: @themeBlack-color-corp-skyBlue;
        border-bottom-color: @themeBlack-color-corp-skyBlue;
      });
      .theme("classic", {
        background: @themeClassic-color-corp-skyBlue !important;
        border-top-color: @themeClassic-color-corp-skyBlue;
        border-bottom-color: @themeClassic-color-corp-skyBlue;
      });
    }
  }
}

.table__helper-text {
  display: inline-block;
  vertical-align: top;
  padding-top: 3px;
  margin-left: 15px;
}

.table {
  td {
    .sub-table-toggle {
      display: inline-block;
      padding: 0 15px 10px 15px;
      color: #21BAE7;
      font-size: 18px;
      cursor: pointer;

      &:hover {
        color: #17374e;
      }
    }
  }

  .td-toggle {
    width: 60px;
  }

  .td-expanded {
    border-left: 4px solid #21BAE7;
    border-top: none;
    padding-left: 0;
    padding-right: 0;
    background: #F9FAFB;
  }

  .table-sub {
    margin-left: 46px;
    width: calc(100% - 46px);
    margin-bottom: 0;
    th, td {
       background: #F9FAFB;
    }
  }

  .tr-expanded {

    td {
      border-bottom: none;
      background: #F9FAFB;

      &:first-child {
        border-left: 4px solid #21BAE7;
      }
    }
  }
}

td.td-danger {
  border: 1px solid @color-status-danger !important;

  .text-danger {
    padding-top: 11px;
  }
}
