.subContent {
  overflow: hidden;
  position: relative;
  max-height: 194px; // 206 (same as for subContent-scroller) - 9 - 3 (margin)
  margin-bottom: 9px;
  margin-top: 3px;

  background-color: @dropdown-link-hover-bg;

  &:before {
    position: absolute;
    z-index: 10;
    left: 0;
    right: 0;
    top: 0;
    display: block;
    height: 11px;
    content: '';
    .retina-img('@{assets-path}img/dropdown-menu-subContent.png', '@{assets-path}img/dropdown-menu-subContent_2x.png', 1600px, 11px);
    background-position: -50px 0;
  }

  &:after {
    position: absolute;
    left: -10px;
    right: -10px;
    bottom: -8px;
    display: block;
    content: '';
    padding-bottom: 8px;
    background-color: @color-white;
    border-top: 1px solid @table-border-color;
    box-shadow: 0px 1px 6px 0px rgba(0, 0, 0, 0.13);
  }

  .subContent-scroller {
    max-height: 206px;
    padding-right: 0;

  }

  .slimScrollBar {
    // it is a trick, in order to make scrollbar fit only the visible part of the space
    background-color: transparent !important;

    &:after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 5px;
      background-color: rgb(145, 145, 145);
      border-radius: 7px
    }
  }

  .slimScrollRail {
    top: 50px !important;
  }

  &.subContent_slightlyShifted:before {
    background-position: -5px 0;
  }

  &.subContent_shifted:before {
    background-position: -5px 0;
  }
}

.subContent-item {
  border-top: 1px solid @table-border-color;

  &:hover {
    background-color: darken(@dropdown-link-hover-bg, 4%);
  }

  .subContent-item:first-child {
    border-top: 0;
  }
}

.subContent-item.comment {
  padding: 11px 16px;
  cursor: pointer;

  &:first-child {
    padding-top: 16px !important;
  }

  .comment-heading {
    margin-bottom: 0;
  }

  .comment-body-content {
    font-weight: 500;
    font-size: @fontSize-base;
  }

  .comment-body-content + .text-muted {
    font-size: @fontSize-base - 2;
  }
}

.subContent-item.bundles-item {
  &:first-child {
    padding-top: 15px;
  }
}

