.audit {
  
  h1 {
    margin-top: 20px;
    margin-bottom: 24px;
    color: @black;
  }
  
  .mvscModal-body {
    .container {
      padding-top: 41px;
      background: @white;
    }
  }
  
  .audit-issue {
    padding: 0 84px 84px 84px;
  }
  
  .comment_ticket .label {
    margin-right: .5em;
  }
  
  .comment-addCommentForm {
    border-top: 1px solid #e0e3e6;
  }
  
  .comment {
    margin-bottom: -1px;
    border-bottom: 1px solid #e0e3e6;
  }
  
  .comment_ticket {
    h2 {
      font-size: @font-size-base + 20;
      
      small {
        .label, .pseudoLink {
          top: -4px;
          font-size: @font-size-base - 2;
          line-height: 1.3;
        }
        
      }
    }
  }
  
  .audit-issue-footer {
    margin-top: 35px;
  }
  
  .backLink {
    margin-bottom: 7px;
  }
  
  .list-favorites {
    padding-bottom: 60px;
    
    li:not(:last-child) {
      margin-right: 30px;
    }
  }
}

.audit-adaptiveContent {
  display: none;
}

@media screen and (min-width: 1600px) {
  .audit-adaptiveContent {display: table-cell;} 
}