.blog {
  padding: 0 95px;
  color: @gray-dark;
  
  P, li {
    font-family: 'PT Serif';
    font-size: 20px;
    line-height: 1.65em;
    font-weight: 400;
  }
  
  
  h1 {
    font-size: @font-size-base + 25;
    margin: 0 0 74px 0;
    padding: 72px 0;
    border-bottom: 1px solid @color-border_light;
    text-align: center;
  }
  
  h2, h3, h4 {
    font-family: Lato;
  }
  
  h2 {
    margin-bottom: 11px;
    font-size: @font-size-base + 25;
    font-weight: 900;
    line-height: 1.11;
    text-align: left;
    
    a {
      color: @gray-dark;
    }
  }
  
  h3, h4 {
    font-size: @font-size-base + 11;
  }
  
  h3 {
    font-weight: 800;
    margin: 20px 0 15px 0;
  }
  
  .blog__post h4,
  .blog__post h3, 
  .blog__post h2,
  .blog__post h1 {
    
    strong, em {
      .badge();
      font-family: Roboto;
      
    }
    
    strong {
      background: @color-status-success;
    }
    
    em {
      background: @color-status-info;
      font-style: normal;
    }
    
    strong, em {
      padding: 3px 8px 4px 8px;
      position: relative;
      top: -7px;
      margin-left: 2px;
      font-size: @font-size-base - 1;
      font-weight: 500;
      font-family: Roboto;
    }
  }
  
  
  h4 {
    margin-top: 0;
    margin-bottom: 1px;
    font-weight: 700;
    line-height: 1.38em;
  }
  
  img:not(.blog__userpic) {
    display: block;
    margin: 20px 0;
  }
  
  p {
    margin-bottom: 27px;
  }
  
  strong {
    font-weight: 800;
  }
  
  p + h4, 
  p + .blog__post__list,
  .blog__post__list + p {
    margin-top: -14px;
  }
  
  .blog__post {
    padding: 0 14% 71px 14%;
    margin-bottom: 74px;
    border-bottom: 1px solid @color-border_light;
    
    a h2 {
      color: @gray-dark;
    }
  }
  
  .blog__post__header {
    position: relative;
    padding-left: 52px;
    margin-bottom: 25px;
    
    .blog__userpic {
      top: 0;
      left: 0;
    }
  }
  
  .blog__post__img-description {
    margin-top: -3px;
    margin-bottom: 38px;
    font-family: 'PT Serif';
    font-size: @font-size-base + 1;
  }
  
  .blog__post__list {
    margin-bottom: 47px;
    
    li {
      margin-bottom: 14px;
    }
  }
  
  .blog__post__list {
    padding-left: 20px;
    
    li {
      padding-left: 4px;
    }
  }
  
  .blog__post__img {
    display: block;
    margin: 14px auto;
    max-width: 100%;
  }
  
  
  .blog__post img:not(.blog__userpic) {
    display: block;
    margin: 20px auto;
    max-width: 100%;
  }
  
  .blog__post__wide-content {
    margin-left: -14%;
    margin-right: -14%;
    
    p {
      padding: 28px 0;
      font-size: @font-size-base + 19;
      line-height: 1.38;
    }
    
    .blog__post__img {
      margin-bottom: 42px;
    }
  }
  
  .blog__userpic {
    border-radius: 50%;
    position: absolute;
    
  }
  
  .blog__user-name,
  .blog__read-more,
  .blog__load-more {
    font-size: @font-size-base + 3;
  }
  
  .blog__load-more {
    height: 50px;
    padding: 13px;
    margin-bottom: 100px;
    margin-top: -5px;
    color: @color-link;
    cursor: pointer;
    
    &:hover {
      background: darken(@color-white, 2%);
      color: darken(@color-corp-grayBlue, 15%);
    }
    
    .mvscSpinner {
      margin-right: 5px;
    }
    
    .mvscSpinner {
      position: relative;
      bottom: -2px;
      display: inline-block;
      vertical-align: middle;
    }
  }
  
  .blog__post__author {
    margin-top: 43px;
    margin-bottom: -14px;
    padding: 46px 0 0 118px;
    position: relative;
    font-size: @font-size-base + 3;
    min-height: 150px;
    
    &:after {
      padding-right: 5px;
      position: absolute;
      left: 0;
      top: 0;
      display: inline-block;
      content: 'Author';
      font-family: 'PT Serif';
      font-weight: 800;
      color: @color-muted;
      font-size: @font-size-base + 7;
      background: @white;
    }
    
    &:before {
      position: absolute;
      top: 16px;
      left: 0;
      right: 0;
      height: 1px;
      background: @color-border_light;
      content: '';
    }
    
    .blog__userpic {
      top: 46px;
      left: 0;
      width: 100px;
      height: 100px;
    }
    
  }
  
  .blog__post__author__username {
    font-weight: 500;
    line-height: 1.63;
  }
  
  .blog__post__author__description {
    line-height: 1.44;
  }
  
  .blog__back-link {
    display: inline-block;
    margin-left: 124px;
    margin-bottom: 70px;
    position: relative;
    top: -41px;
    font-size: @font-size-base + 3;
    
    .fa {
      position: relative;
      bottom: -2px;
      margin-right: 7px;
      font-size: @font-size-base + 11;
    }
  }
}