.launch {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100%;
    &:not(.launch_relative) {
        height: 100%;
        background-color: #fff;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 1070;
    }
    &__container {
        width: 244px;
        height: 107px;
        position: relative;
    }
    &__text {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        white-space: nowrap;
        min-height: 20px;
        font-size: 15px;
        line-height: 18px;
        color: #B5B5B5;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        transform: translateY(24px);
        &::before {
            flex: 0 0 12px;
            width: 12px;
            display: inline-block;
            content: "";
        }
        &::after {
            flex: 0 0 12px;
            width: 12px;
            display: inline-block;
            content: "...";
        }
    }
    &__underlay {
        width: 244px;
        height: 107px;
        [class^='launch__letter__'] {
            fill: #edf0ee;
        }
    }
    &__overlay {
        width: 244px;
        height: 107px;
        position: absolute;
        top: 0;
        left: 0;
        .launch {
            &__letter {
                &__v {
                    animation-name: colorV;
                }
                &__i {
                    animation-name: colorI;
                }
                &__t {
                    animation-name: colorT;
                }
                &__u {
                    animation-name: colorU;
                }
                &__point {
                    animation-name: color0;
                }
            }
        }
        [class^='launch__letter__'] {
            fill: #003f4e;
            opacity: 0;
            
            animation-fill-mode: forwards;
            animation-delay: 1s;
            animation-duration: 4s;
            animation-iteration-count: infinite;
        }
        .launch__letter__point {
            fill: #21bae7;
        }
    }

    &__container,
    &__text {
        opacity: 0;

        animation-name: opacity;
        animation-duration: 1s;
        animation-fill-mode: forwards;
    }
}

@keyframes colorV {
    0% {
        opacity: 0;
    }
    15% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes colorI {
    0% {
        opacity: 0;
    }
    15% {
        opacity: 0;
    }
    30% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes colorT {
    0% {
        opacity: 0;
    }
    30% {
        opacity: 0;
    }
    45% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes colorU {
    0% {
        opacity: 0;
    }
    45% {
        opacity: 0;
    }
    60% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes color0 {
    0% {
        opacity: 0;
    }
    60% {
        opacity: 0;
    }
    75% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes opacity {
    0% {
        visibility: hidden;
        opacity: 0;
    }
    50% {
        visibility: visible;
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}