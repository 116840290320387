// todo: rewrite all the alert-... classes. naming is inconsistent.
.alert-important,
.alert-note {
  position: relative;
  margin-bottom: 30px;
  padding: 20px 30px;
  color: @gray;
  border: 1px solid @well-border;

  &:before {
    position: absolute;
    top: -1px;
    left: -1px;
    bottom: -1px;
    content: '';
    display: block;
    width: 4px;
    background: @state-info-text;
  }

  h4 {
    margin-top: 0;
    color: @state-info-text;
    font-weight: 500 !important; //Todo ned to fix context conflict;
    font-size: @fontSize-base + 1;
  }

  .fa-info-circle {
    font-size: @fontSize-base + 7;
  }
}

.alert-important {
  border-color: @color-status-warning;

  &:before {
    background: @color-status-warning;
  }

  h4 {
    .text-warning();
  }
}

.alert-link {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.alert-loading {
  @padding: 15px;
  @iconsize: 20px;
  @percentage: 20%;
  @shadow: 0 0 12px rgba(0, 0, 0, .2),
  0 0 1px rgba(0, 0, 0, .3);
  @width: 300px;

  position: fixed;
  z-index: @zindex-toastr;
  top: 12px;
  left: 50%;
  width: @width;
  min-height: 1px;
  margin-left: -(@width/2);
  padding: @padding @padding + 15px @padding @padding + @iconsize + 5px; //override .alert

  text-align: center;
  color: #333;
  background-color: @alert-info-bg;
  color: @alert-info-text;

  opacity: .97;

  box-shadow: @shadow;

  &_error {
    background-color: @alert-danger-bg;
    color: @alert-danger-text;

    .fa {
      margin-right: .25em;
    }
  }

  .mvscSpinner {
    position: relative;
    bottom: -4px;
    margin-top: -5px;
    margin-right: 6px;
  }
}

.alert {

  .alert-p {
    display: block;
    margin-bottom: 5px;
  }

  .alert-p:only-child {
    margin-bottom: 0;
  }

  .alert-item {
    margin-bottom: 12px;

    p {
        margin: 0;
    }

    &:last-child {
        margin-bottom: 0;
    }
  }

  label {
    font-size: @fontSize-base;
    line-height: 18px;
    color: inherit;
  }

  & > .form-group,
  & .form-group {
    margin-bottom: 0;
  }

  .checkbox,
  .checkbox label,
  .radio-inline label {
    margin-bottom: 0;
  }

  .radio,
  .checkbox,
  .radio-inline,
  .checkbox-inline {

    input {

      +label,
      ~label {
        color: inherit;

        &:before {
          border-color: currentColor;
        }
      }
    }
  }

  .row:last-child {

    .form-group {
      margin-bottom: 0;
    }
  }

  .btn-xs {
    margin-left: 6px;
  }

  .mvscSpinner {
    .mvscSpinner-spinner {
      display: inline-block;
      vertical-align: middle;
      margin-right: 4px;
    }

    h3 {
      position: relative;
      bottom: -2px;
      display: inline-block;
      font-size: @font-size-base;
      font-weight: 200;
      text-transform: none;
      vertical-align: middle;

    }
  }

  &-default {
    color: #333;
    background: #f4f5f6;
    border-color: #e0e3e6;

    .fa {
      color: #333;
      margin-right: 7px;
    }
  }

  &-success {
    .mvscSpinner {
      .mvscSpinner-spinner {
        border-top-color: @state-success-text;
      }
    }
    .form-control {
      border-color: @alert-control-border-success;
    }
  }

  &-warning {
    .mvscSpinner {
      .mvscSpinner-spinner {
        border-top-color: @state-warning-text;
      }
    }
    .form-control {
      border-color: @alert-control-border-warning;
    }
    mvsc-form-control2 .checkbox {
      margin-top: 0;
    }
  }

  &-info {
    .mvscSpinner {
      .mvscSpinner-spinner {
        border-top-color: @state-info-text;
      }
    }
    .form-control {
      border-color: @alert-control-border-info;
    }
  }

  &-danger {
    .mvscSpinner {
      .mvscSpinner-spinner {
        border-top-color: @state-danger-text;
      }
    }
    .form-control {
      border-color: @alert-control-border-danger;
    }
  }

  &-control {
    margin: 15px 15px 6px;
  }

  label,
  label.required {
    white-space: normal;
  }

  .checkbox.required {
    &:after {
      content: none !important;
    }
  }

  &-checkbox {

    .checkbox input ~ label,
    .checkbox input + label {
      color: inherit;
    }
  }

  &_fa {
    position: relative;
    padding-left: 36px;

    .fa {
      position: absolute;
      left: 15px;
      top: 16px;
      font-weight: 900;
      font-size: 13px;
      line-height: 13px;
    }
  }
}

.helpCenter-knowledgeBase-ol {
  position: relative;
  padding-left: 60px;

  .helpCenter-knowledgeBase-ol-number {
    position: absolute;
    top: 0;
    left: 30px;
  }
}
