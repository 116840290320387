@supports not ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
  .lockScreen {
    padding: 0 !important;

    & mvsc-layout {
      display: block;
      filter: blur(3px);
    }
  }
}

.lockScreen {
  overflow: hidden;

  .modal.adminPanel {
    &::before {
      content: "";
      background-color: transparent;
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 9999;
    }
  }

  .lockScreen-modal {
    z-index: @zindex-lockModal;
    overflow-y: auto;

    .modal-dialog {
      position: absolute;
      margin: 0;
      top: 50%;
      left: 50%;
      width: 350px;
      margin-left: -175px;
      text-align: center;
      transform: translateY(-50%);

      .animate-show();
    }

    .modal-content {
      box-shadow: none !important;
    }

    @padding: 35px;

    .modal-body {
      padding: 50px @padding 0;
    }

    .lockScreen-modal-header {
      margin-top: 0;
      margin-bottom: 26px;
      text-align: center;
      font-size: 24px;
      color: @gray-dark;

      img {
        margin-bottom: 15px;
      }

      span {
        display: block;
      }

    }

    .lockScreen-modal-form {
      margin-bottom: 35px;
    }

    .form-group {
      position: relative;
      margin-bottom: 20px;
    }

    .form-actions {
      padding: 0;
      margin: 0;
    }

    .table-cell {
      display: block;
    }

    label {
      position: absolute;
      left: 8px;
      top: 5px;
      margin-bottom: 0;
      padding: 0 6px;

      background: @color-white;
      font-size: @fontSize-base;
      line-height: 1em;

      opacity: 0;

      transition: top .2s, opacity .2s;
    }

    input:valid + label {
      top: -7px;
      opacity: 1;
    }

    .btn-teal {
      border: none;
      font-weight: 400;
    }

    .lockScreen-modal-footer {
      margin: 0 -@padding;
      padding: 21px 0 25px 0;
      border-top: 1px solid @pagination-disabled-border;
      border-radius: 0 0 @border-radius-base @border-radius-base;
      background: darken(@color-white, 2%);

      a {
        color: lighten(@color-base, 40%);
      }
    }

    .lockScreen-modal-processing {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      h3 {
        margin-bottom: 45px;
      }

      .vertical-align_absolute {
        left: 0;
        right: 0;
        padding-bottom: 10px;
      }
    }

  }

  .lockScreen-backdrop {
    backdrop-filter: blur(3px);
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 1 !important;
    z-index: @zindex-lockBackdrop;
  }
}

.ie9 .lockScreen .lockScreen-modal label {
  opacity: 1;
  top: -7px !important;
}

.ie9,
.ie10,
.ie11 {
  .lockScreen {
    overflow: hidden;

    .modal-backdrop {
      opacity: 1;
      // background-color: @color-corp-darkestBlue;

      .theme("default", {
        background-color: @themeBrand-color-corp-darkestBlue;
      });
      .theme("black", {
        background-color: @themeBlack-color-corp-darkestBlue;
      });
      .theme("classic", {
        background-color: @themeClassic-color-corp-darkestBlue;
      });
    }
  }
}
