.gallery {
  margin-bottom: 30px;
  padding: 19px 14px;

  border: 1px solid @well-border;
  background-color: @well-bg;
  border-radius: @border-radius-base;
   
  .gallery-item {
    padding: 0 5px; 
  }
  
  .gallery-item-description {
    margin-top: 15px;
    padding: 0 10px;
  }
    
  a {
    text-align: center;
    display: inline-block;
    position: relative;
    text-decoration: none;
    
    &:after {
      position: absolute;
      content: '\f00e';
      display: block;
      width: 55px;
      height: 55px;
      line-height: 55px;
      font-size: @fontSize-base + 10;
      font-family: "Font Awesome 5 Pro";
      color: @white;
      text-align: center;
      background-color: rgba(0,0,0,.6);
      opacity: 0;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }
    
    &:hover:after {
      opacity: 1;
    }
  }
  
  a:hover {
  
    img {
      box-shadow: 0 3px 7px rgba(0,0,0,.2);
    }
  }
  
  img {
    max-width: 100%;
    box-shadow: 0 3px 7px rgba(0,0,0,.1);
    border-radius: @border-radius-base;
  }
}