.comm {

  h2 {
    margin: 0 0 23px;
  }

  &__blank {
    font-weight: 300;
    font-size: 27px;
    line-height: 32px;
    color: #000;
    text-align: center;
    .far {
      display: block;
      font-weight: 300;
      font-size: 75px;
      line-height: 75px;
      color: #999;
      margin: 150px auto 8px;
    }
  }

  .comm__header {
    padding-bottom: 28px;
    //margin-top: 5px;
    position: relative;
    min-height: 60px;

    .btn {
      margin-right: 10px;
    }

    .text-muted {
      display: inline-block;
      position: relative;
      bottom: -1px;

      .fa {
        position: relative;
        top: -2px;
      }
    }
  }

  .text-muted {
    > .fa {
      margin-right: 5px;
    }
  }

  .comm__search {
    position: absolute;
    right: 0;
    top: 0;
    width: 165px;
    transition: width .3s;

    &_wide {
      width: 100%;
    }

    .fa.fa-search {
      position: absolute;
      left: 9px;
      top: 9px;
      color: #9D9D9D;
      font-size: 13px;
      font-weight: 400;
    }

    .fa-times-circle {
      position: absolute;
      right: 9px;
      top: 9px;
      color: #9D9D9D;
      font-size: 13px;
      cursor: pointer;

      &:hover {
        opacity: 0.75;
      }
    }

    .form-control {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .comm__posts {
    border-top: 1px solid #e6e6e6;

    .comm__post {
      padding: 16px 0 19px 16px;
      border-bottom: 1px solid #e6e6e6;
      font-size: 14px;

      .comm__post__title {
        .comm__status_new {
          position: relative;
          top: -2px;
        }
      }

      &_new {
        a {
          font-weight: 500;
        }

      }

      .comm__post__footer {
        margin-top: 8px;
      }

      .comm__post__date {
        font-size: 13px;
      }
    }
  }

  .comm__status {
    height: 22px;
    line-height: 20px;
    padding: 0 8px;
    border: 1px solid #D8D8D8;
    border-radius: 2px;
    display: inline-block;
    font-size: 13px;
    color: #636363;
    margin-right: 5px;

    &_selected {
      background: #296189;
      border-color: #296189;
      color: #fff;
    }

    &_new {
      height: 16px;
      line-height: 16px;
      padding: 0 7px;
      color: #fff;
      border-radius: 2px;
      background: @color-status-danger;
      display: inline-block;
      margin-right: 5px;
      font-size: 9px;
    }

    &_progress {
      background: @color-status-warning;
      border-color: @color-status-warning;
      color: #fff;
    }

    &_open {
      color: #fff;
      .theme("default", {
        background: @themeBrand-color-corp-skyBlue;
        border-color: @themeBrand-color-corp-skyBlue;
      });
      .theme("black", {
        background: @themeBlack-color-corp-skyBlue;
        border-color: @themeBlack-color-corp-skyBlue;
      });
      .theme("classic", {
        background: @themeClassic-color-corp-skyBlue;
        border-color: @themeClassic-color-corp-skyBlue;
      });
    }

    &_closed {
      color: #fff;
      background: #999;
      border-color: #999;
    }

    &_completed {
      color: #fff;
      background: @color-status-success;
      border-color: @color-status-success;
    }
  }

  .comm__attachments {
    background: #F4F5F6;
    border: 1px solid #E0E3E6;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 21px 25px 0 25px;

    .comm__attachments__header {
      padding-bottom: 17px;
      margin-bottom: 22px;
      border-bottom: 1px solid #E0E3E6;
    }

    .nav.nav-tabs {
      border-bottom: none;
      padding: 0;
      margin-bottom: 0;

      li {
        margin-right: 13px;

        a {
          padding: 0;
          border-bottom: 1px solid #296189;
          font-weight: 500;
          font-size: 11px;
          line-height: 15px;
          text-transform: uppercase;
          color: #296189;

          &:hover {
            background: none;
          }

        }

        &.active {

          a {
            background: none;
            border-color: transparent;
            color: #979797;
          }
        }
      }
    }

    .tab-pane#links {

      .comm__attachments__item {
        padding-right: 0;
      }
    }

    .comm__attachments__item {
      margin-bottom: 20px;
      padding: 0 30px 0 33px;
      position: relative;
      word-break: break-word;

      .fa {
        font-weight: normal;
        font-size: 32px;
        line-height: 23px;
        color: #D0D4D8;
        margin-right: 5px;
        position: absolute;
        top: 5px;
        left: 0;
      }

      .comm__attachments__title {
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        display: inline-block;
        vertical-align: top;
        margin-top: -2px;
      }

      .btn.btn-link {
        position: absolute;
        right: 0;
        top: 0;
        font-weight: 500;
        font-size: 24px;
        line-height: 20px;
        color: #9D9D9D;
        padding: 0;

        &:hover,
        &:focus {
          opacity: 0.75;
          text-decoration: none;
        }
      }
    }
  }

  .comm__messages-header {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .text-right {
      padding-right: 55px;
    }

    a {
      .fa {
        font-weight: 400;
        margin-right: 6px;
      }
    }
  }

  .comm__messages {

    .comm__messages__message {
      text-align: left;
      width: 80%;
      margin-bottom: 15px;
      padding: 0 0 0 60px;
      position: relative;

      .comm__messages__message__text {
        background: #F1F1F1;
        padding: 14px 20px;
        font-size: 14px;
        line-height: 20px;
        border-radius: 2px;
        display: inline-block;
        word-break: break-word;
      }

      .comm__messages__message__userpic,
      .avatar {
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 50%;
        width: 50px;
        height: 50px;
      }

      .comm__messages__message__descr {
        font-size: 13px;
        line-height: 18px;
        color: #9D9D9D;
        text-align: left;
        margin: 8px 0;
        font-size: 13px;

        strong {
          font-weight: 500;
          margin-right: 10px;
        }
      }

      &_file {
        width: 45%;

        .comm__messages__message__file {
          background: #F1F1F1;
          height: 80px;
          position: relative;
          padding: 13px 20px;
          font-size: 13px;
          line-height: 20px;
          word-break:break-word;

          .comm__messages__message__file__title {
            font-weight: 500;
            font-weight: 500;
          }

          .comm__messages__message__file__descr {
            color: #9D9D9D;
          }

          .btn.btn-link {
            position: absolute;
            top: 5px;
            right: 10px;
            font-weight: 500;
            font-size: 24px;
            line-height: 20px;
            color: #9D9D9D;
            padding: 0;
          }
        }

        .btn-block.btn-primary {
          position: relative;
          text-align: left;
          padding-left: 20px;
          font-weight: 500;
          font-size: 13px;
          border-radius: 0;
          padding: 10px 20px;

          .fa {
            font-weight: 400;
            position: absolute;
            right: 11px;
            top: 11px;
            font-size: 16px;
          }
        }
      }

      &_my {
        margin-left: 20%;
        padding: 0 60px 0 0;
        text-align: right;

        &.comm__messages__message_file {
          margin-left: 55% !important;

          .comm__messages__message__file {
            text-align: left;
          }
        }

        .comm__messages__message__text {
          color: #026481;
          background: #E5F6FF;
          text-align: left;
        }

        .comm__messages__message__userpic,
        .avatar {
          left: auto;
          right: 0;
        }

        .comm__messages__message__descr {
          text-align: right;
        }
      }


      .btn-block.btn-primary + .comm__messages__message__file,
      .btn-block.btn-primary + .comm__messages__message__text {
        margin-top: 15px;
      }

    }

    &_search {
      .comm__messages__message {
        width: 100%;
      }
    }
  }

  .comm__messages__create {
    .form-group {

      &:not(.margin) {
        margin-bottom: 20px;
      }

      label {
        font-size: 14px;
        color: #000;
      }

      .comm__status {
        margin-right: 10px;
        cursor: pointer;
        height: 32px;
        line-height: 32px;
        padding: 0 15px;

        &:hover:not(.comm__status_selected) {
          background: #EBEDEF;
          border: 1px solid #D8D8D8;
        }
      }
    }
  }

}
