.readMore {
  position: relative;
  overflow: hidden;

  .readMore-trigger {
    display: none;
    position: absolute;
    z-index: 3;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
  }

  &:before,
  &:after {
    position: absolute;
    display: none;
    content: '';
    z-index: 2;
    left: 0;
    right: 0;
  }

  &:before {
    bottom: 0;
    height: 80px;

    // Don't use #gradient > .vertical because of 4 points gradient
    background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,0) 1%,rgba(255,255,255,0.8) 30%,rgba(255,255,255,1) 63%,rgba(255,255,255,1) 100%);
  }

  &:after {
    bottom: 9px;
    height: 1px;
    background-color: @table-border-color;
  }

  &.readMore_collapsed {
    &:before,
    &:after {
      display: block;
    }

    .readMore-trigger {
      display: inline-block;
      white-space: nowrap;

      .fa.text-right {
        margin-right: 3px;
      }
    }
  }

  &.readMore_danger {

    &:before {
      background: linear-gradient(to bottom,  rgba(242,222,222,0) 0%,rgba(242,222,222,0) 1%,rgba(242,222,222,0.8) 30%,rgba(242,222,222,1) 63%,rgba(242,222,222,1) 100%);
    }

    &:after {
      background-color: #EACCD1;
    }

  }
}
