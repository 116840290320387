.change-address-type {
  padding-bottom: 18px;
  
  &_padding-top {
    padding-top: 30px !important;
  }
  
  &_padding-top_20 {
    padding-top: 20px !important;
  }
  
  h4, .change-address-type__list, .change-address-type__title {
    font-size: @fontSize-base + 5;
  }
  
  .form-group.radio {
    
    p {
      font-size: @font-size-base;
    }
  }
  
  .change-address-type__title {
    display: inline-block;
    margin-top: 23px;
    margin-right: 3px;
    margin-bottom: 3px;
    
    &_no-margin {
      margin-top: 0;
    }
  }
  
  .change-address-type__list {
    margin-bottom: 20px;
    
    li {
      margin-bottom: 5px;
    }
  }
  
  &.fieldset {
    
    h4 {
      margin-top: 25px;
      font-weight: 400;
    }
    
    &.change-address-type_noFormActions {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }
}