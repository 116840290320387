// Filters and filtersTrigger
// (left col of every 'list of entities' page)
.filters {
    @mb: 25px;
    @scroller-maxHeight: 132px;
    @scroller-fade-height: 18px;

    //margin-bottom: @mb;
    padding-right: 0;

    #fly-sideBar {
        margin-left: 11px !important;
    }

    form {
        padding-top: 12px;
    }

    .filters-forecast {
        .btn.active {
            cursor: pointer;
        }
    }

    // since we added these wrappers to support sections show/hide
    // !potential issue with margin-bottom if mvsc-... will be a last section in .filters
    mvsc-state-filter,
    mvsc-location-filter {
        display: block;
    }

    mvsc-state-filter,
    mvsc-location-filter,
    fieldset {
        margin-bottom: 26px;
    }

    fieldset {
        &:last-child {
            margin-bottom: 0;
        }

        .form-group {
            //margin-left: 6px;
            //margin-right: 6px;
        }

        &.filters-fieldset_keyword {
            margin-top: -12px;
            margin-bottom: 11px;

            mvsc-vin-type-dropdown2 {
                display: table-cell;
                width: auto;

                .input-group-btn {
                    width: 100%;

                    .angle {
                        margin-left: 5px;
                    }
                }

                .dropdown {
                    left: auto;
                    right: 0;
                    margin-right: 0;

                    &-item {
                        display: block;
                        border: none;
                        background: none;
                        width: 100%;
                        padding: 6px 13px;
                        color: #000;
                        font-weight: normal;
                        line-height: 18px;
                        white-space: nowrap;
                        text-align: left;

                        &:hover {
                            background-color: #21bae7;
                            color: #fff;
                        }
                    }
                }
              }
        }

        .filters-fieldset_withoutHeader {
            margin-top: -3px;
        }

        .filters-fieldset-header {
            margin: 0 0 7px 0;
            padding: 0 12px 7px 6px;
            border-bottom: 2px solid @pagination-disabled-border;
            line-height: 18px;
        }

        .filters-fieldset-header_presets {
            margin-right: 0;
            padding-right: 0;
        }

        .filters-fieldset-body {

            // remove top margins from elements in collapse
            .checkbox:first-child {
                margin-top: 0;
            }

            .checkbox:last-child {
                margin-bottom: 0;
            }
        }

        .scroller .form-group {
            margin-right: -6px;
            margin-left: 6px;

            &:first-child {
                margin-top: 6px;
            }
        }
    }

    label {
        font-size: @fontSize-base;
    }

    .checkbox label {
        line-height: 18px;
    }

    .pseudoLink {
        line-height: 1;
    }

    .filters-group-clear,
    .filters-group-edit,
    .filters-group-create {
        margin-left: .5em;
    }

    .filters-group-edit {
        margin-left: .2em;
    }

    .filters-group-clear {
        position: relative;
        top: 1px;
        right: -6px;
        line-height: 17px;
        font-size: 11px;
    }

    .filters-group-edit {

        .fa {
            position: relative;
            color: darken(@input-border, 10%);
        }

        &:hover {

            .fa {
                color: @color-muted;
            }
        }
    }

    .btn-add {
        position: relative;
        top: -2px;
        margin-bottom: -2px;
    }

    h5 {
        display: inline-block;
        margin: 0;
        text-transform: uppercase;
        color: @text-color;
        font-weight: 700;
        line-height: 14px;
    }

    .checkbox {
        label {
            .ellipsis {
                width: 85%;
                vertical-align: top;
            }
        }
    }

    .checkbox:hover,
    .checkboxRadio:not(.deleted):hover {
        border-radius: @border-radius-base;

        .theme("default", {
            background: @themeBrand-tabs-tab-hover-bgcolor;
        });

        .theme("black", {
            background: @themeBlack-tabs-tab-hover-bgcolor;
        });
    }

    .checkboxRadio_bordered:hover {
        background: none !important;
    }

    .checkbox,
    .checkboxRadio:not(.checkboxRadio_bordered) {
        margin: 0 -6px -1px 0;
        padding: 0 6px;

        label {
            margin: 0;
            display: block;
            border-top: 3px solid transparent;
            border-bottom: 3px solid transparent;
        }
    }

    // align 'hide daily invoices' (billing) checkbox
    .form-group>.checkbox {
        margin-left: -6px;
    }

    .scroller {
        max-height: @scroller-maxHeight;
        overflow-x: hidden;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: rgb(224, 227, 230);
            border-radius: 5px;
        }

        &[style*="height: @{scroller-maxHeight};"] {
            >*:last-child {
                //padding-bottom: @scroller-fade-height;
            }
        }


        &:after,
        .scroller-gradient {
            position: absolute;
            top: @scroller-maxHeight - @scroller-fade-height;
            left: 0;
            right: 0;
            z-index: 1;
            height: @scroller-fade-height;
            content: '';

            box-shadow: inset 0px -10px 10px 0px @color-white;
        }

        &.scroller_filter {
            &:after {
                display: none;
            }
        }
    }

    .slimScrollBar {
        box-shadow: 0px 0px 0px 2px #fff;
        background: #e0e3e6 !important;
        opacity: 1 !important;
    }

    .slimScrollDiv:hover .slimScrollBar,
    .slimScrollBar:hover,
    .slimScrollBar:active {
        margin-right: -1px;
        width: 7px !important;
    }

    .slimScrollRail {
        background: #f6f7f7 !important;
        opacity: 1 !important;
    }

    .filters-presetsInlineForm {
        margin: 0 0px 0 0px;

        hr {
            margin-top: 9px;
        }

        .form-group {
            margin-bottom: 7px;
            margin-left: 0;
            //margin-right: 0;
        }

        hr {
            margin-top: 10px;
            margin-bottom: 10px;
        }

        &.filters-presetsInlineForm_create {
            margin: 10px 0 14px 0;
            padding: 0;
        }
    }

    .filters-emptyStateMessage {
        display: block;
        padding-left: 6px;
        line-height: 1.3em;
        line-height: 14px;
    }

    .filters-currentLocation {
        margin-top: -12px;
        margin-bottom: 30px;
        padding: 8px 7px 7px 7px;
        border: 2px solid @pagination-disabled-border;
        color: @text-color;

        .fa {
            color: darken(@input-border, 10%);
            margin-right: 7px;
        }

        &+.filters-fieldset_keyword {
            margin-top: 0;
        }
    }

    .filters-presetsButtons {
        font-size: 0; //need for kill inline-block margins
        padding-bottom: 8px;
    }

    .filters-presets {
        padding: 0 0 6px 6px;
    }

    .filters-forecasts {
        padding: 5px 0 0 6px;
    }

}

/* Adaptive for 'Create' button */
@media screen and (max-width: 1100px) {
    .filters .adaptive-title {
        display: none;
    }

    .filters .btn-add.btn-xs .btn-add-icon.btn-add-icon_isntrounded {
        margin-right: -2px;
    }
}


// Panel at the top of the page with list of entites in Admin
.filter {
    &.filter_panel {
        min-height: 20px;
        padding: 12px;
        margin-bottom: 20px;
        background-color: @well-bg;
        border: 1px solid @well-border;
        border-radius: @border-radius-base;
    }

    & .checkbox,
    & .checkbox-inline {
        margin-bottom: 0;

        &>label {
            margin-top: 7px;
        }

        &.input-sm>label {
            margin-top: 1px;
        }

        &.input-lg>label {
            margin-top: 1px;
        }
    }

    .btn-group {
        .btn {
            font-weight: 400;
        }
    }
}


.filtersTrigger {
    padding-top: 7px;
    white-space: nowrap;

    .filtersTrigger-wrapper {
        display: inline-block;
        margin-right: 20px;
        padding-left: 2px;
    }

    .filtersTrigger-title {
        display: inline-block;
        margin-right: 6px;
        text-transform: uppercase;
        color: @text-color;
        font-weight: 700;
    }

    .filtersTrigger-icon {
        position: relative;
        top: 1px;

        display: inline-block;
        width: 14px;
        height: 14px;
        line-height: 14px;
        margin-right: 9px;
        background: none;

        &:after {
            font-family: mvsc;
            content: '\e606';
            font-size: 14px;


            .text-mutedMore();
        }
    }

    &.filtersTrigger_set {
        .filtersTrigger-icon:after {
            color: darken(@input-border, 10%);
        }
    }
}

.filtersTrigger-btn {
    line-height: 17px;

    &:before {
        font-size: 11px;
        content: 'Hide';
    }
}

#fly-actionsBar.fly-actionsBar_collapsed,
.fly-actionsBar_collapsed {
    .filtersTrigger-btn {
        &:before {
            content: 'Show';
        }
    }
}
