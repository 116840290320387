.customizable-options {

  & fieldset {
    .efileForm-fieldset-header {
      margin-bottom: 12px;
    }
  }
  .col-xs-12 {
    margin-bottom: 4px;
  }

  .customizable-options__add-control {
    & > * {
      margin-bottom: 0;
      vertical-align: bottom;
    }
    
    & > .form-group,
    & > .form-control {
      width: 170px;
    }
  }
  
  .customizable-options__add-input {
    display: inline-block;
    width: auto;
  }
  
  .customizable-options_add-filter-link {
    margin-top: 7px;
    margin-bottom: 15px;
  }
  
  .options-option, 
  .form-control,
  .input-group,
  .mvsc-flexibleContext {
    margin-right: 5px;
  }
  
  .mvsc-flexibleContext {
    .input-group {
      margin-right: 0;
    }
  }
  
  /* */
  .cancel {
    margin-left: 6px;
  }
  /* */
  
  .options-option.options-option_default {
    width: 110px;
    
    .options-option-text {
      color: @color-muted;
    }
  }
  
  .options-option {
    .options-option-text {
      color: @text-color;
    }
  }
}

fieldset.customizable-options {

  .efileForm-fieldset-header {
    margin-bottom: 12px;
  }
}

.customizable-options__add-control {
  display: inline-block;
  margin-bottom: 8px; 
}