/* Toothy */
.toothy {
  position: relative;
  height: 48px;
  font-size: @fontSize-base - 2;
  text-transform: uppercase;
  .retina-img('@{assets-path}img/zigzag-sprite.png', '@{assets-path}img/zigzag-sprite_2x.png', 6px, 144px);

  .toothy-showMore,
  .toothy-loadingMore {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    padding-top: 16px;
    font-weight: 700;
  }

  .toothy-showMore {
    text-decoration: none !important;

    &:hover {
      text-decoration: none;
    }
  }

  .toothy-shown {
    position: absolute;
    right: 20px;
    top: 0;
    padding-top: 16px;
  }

  .toothy-showAll {
    margin-left: 5px;
    font-weight: 500;
    position: relative;
    z-index: 1;
    //text-decoration: none;
  }

  .toothy-loadingMore {
    display: none;

    .mvscSpinner {
      vertical-align: top;
      position: relative;
      top: 0;
      margin-right: 3px;
    }
  }

  &:hover {
    background-position: left 50%;
  }

  &:active {
    background-position: left 100%;
  }

  // Loading state
  &.toothy_loading {
    .toothy-showMore {
      display: none;
    }
    .toothy-loadingMore {
      display: block;
    }

    &:hover {
      background-position: 0 0;
    }
  }

  // All shown state
  &.toothy_allShown {
    margin-bottom: -20px;
    background: none;

    .toothy-showMore {
      display: none;
    }

    .toothy-shown {
      left: 0;
      right: 0;
      text-align: center;
    }
  }
}

mvsc-show-more {
  display: block;
}

.table + .toothy,
.table + mvsc-show-more {
  z-index: 2;
  //margin-top: -22px;
  margin-bottom: 18px;
}
