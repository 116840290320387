/* Account for fixed navbar */
body {
  min-width: 1000px;
}

/* Non-responsive overrides
 *
 * Utilitze the following CSS to disable the responsive-ness of the container,
 * grid system, and navbar.
 */

/* Reset the container */
.container,
.pageWrapper {
  width: 100%;
  min-width: 970px;
  max-width: 1170px;
}
