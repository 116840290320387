.browsersCheck {
  margin: 0 auto 90px auto;
  padding: 0 30px;
  width: 860px;
  border: 1px solid @color-border_light;
  background: @white;
  border-radius: @border-radius-base;
  
  h1 {
    margin: 65px 0 19px 0;
  }
  
  p.text-biggest {
    padding-left: 135px;
    padding-right: 135px;
  }
  
  .browsersCheck-list {
    margin: 57px -65px 59px 0;
    
    li {
      margin-right: 65px;
    
      a {
        display: block;
        
        .fa {
          margin: 0 0 27px 0;
          font-size: @fontSize-base + 87;
          line-height: 1;
        }
        
        .small {
          display: block;
          font-size: @fontSize-base + 3;
          font-weight: 500;
        }
      }
      
      a:hover {
        text-decoration: none;
        
        .fa {
          color: @color-status-init;
          
          transform: scale(1.035);
          transform-origin: center center;
          transition: transform .1s ease;
        }
      }
    }
  }
}
