//certificate-plate

.certificate-plate-edit {
  
  .edit-certificate {
    color: #296189;
    font-size: 12px;
    font-weight: 400;
    margin-left: 5px;
    text-transform: none;
    &[disabled],
    &:disabled,
    &.disabled {
      pointer-events: none;
      opacity: 0.65;
      user-select: none;
    }
  }

  .certificate-plate .input-group i.fa {
    position: absolute;
    z-index: 2;
    margin-left: -30px;
  }
}

.certificate-plate {
   
   .input-group {
     .form-control {
        float: none;
        display: inline-block;
        vertical-align: middle;
        font-size: @font-size-base + 20;
        height: 66px;
        padding-left: 12px;
        padding-right: 0;
        letter-spacing: 7px;
        outline: none;
        text-transform: uppercase;
        font-family: 'Roboto Mono', monospace;
        border-radius: @border-radius-base;
      }
    
      .input-group-addon + .form-control {
        //border-radius: 0 @border-radius-base @border-radius-base 0 !important;
      }
      
      .input-group-addon {
        width: auto;
        padding: 0 5px 0 12px;
        letter-spacing: 7px;
        font-size: @font-size-base + 20;
        font-family: 'Roboto Mono', monospace;
        box-sizing: border-box;
      }
      
      .form-control + .input-group-addon {
        border-left: none;
         border-radius: 0 @border-radius-base @border-radius-base 0;
      }
    
      .btn {
        height: 66px;
        padding-left: 20px;
        padding-right: 20px;
        margin-left: 8px;
      }
    
      .btn:empty {
        display: none;
      }
    
      .input-group-addon:empty {
        display: none;
      }
    
      .input-group-addon:empty + input {
        border-radius: @border-radius-base !important;
      }
      
      i.fa {
        display: inline-block;
        vertical-align: middle;
        margin-left: 8px;
        font-size: @font-size-base + 3;
        
        &:after {
          height: 65px;
          content: '';
          display: inline-block;
          vertical-align: middle;
          
        }
      }
   }
   
   .help-block {
     margin-top: 15px;
     font-size: @font-size-base + 1;
     color: @gray-dark;
     
     .marked.pseudoLink {
       white-space: nowrap;
     }
     
     p {
       margin-bottom: 20px;
     }
   }
    
  &.certificate-plate_3-digits .form-control {
    width: 100px;
  }
  
  &.certificate-plate_4-digits .form-control {
    width: 127px;
  }
    
  &.certificate-plate_5-digits .form-control {
    width: 154px;
  }
    
  &.certificate-plate_6-digits .form-control {
    width: 181px;
  }
    
  &.certificate-plate_7-digits .form-control {
    width: 208px;
  }
    
  &.certificate-plate_8-digits .form-control {
    width: 235px;
  }
    
  &.certificate-plate_9-digits .form-control {
    width: 261px;
  }
}
