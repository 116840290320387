  .mvsc-input-alert {
    position: relative;
    &_pfr:after {
      background: @color-status-sold;
      content: 'PFR #';
    }
    &_unit:after {
      background: @color-status-pending;
      content: 'UNIT #';
    }
    &:after {
      
      
      
      display: inline-block;
      position: absolute;
      right: -3px;
      top: -6px;
      padding: 0 3px;
      line-height: 1.35;
      font-size: 10px;
      font-weight: 700;
      color: #fff;
      border-radius: @border-radius-base;
      z-index: 10;
    }
  }
