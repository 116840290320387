.adminPanel {
  .row.modal-row {
    margin-left: 0;
    margin-right: 0;
  }
  .entity-actions {
    position: relative;
    mvsc-action-dropdown2 + .btn-danger {
      margin-left: 20px;
    }
  }
  .modal-body {
    padding: 0;
  }
  .modal-row-sidebar {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
  }
  .modal-row-content {
    padding-right: 0;
  }
  .verticalScrollableComponent {
    min-height: calc(100vh - 92px);
    max-height: calc(100vh - 92px);
    overflow-y: auto;
    overflow-x: hidden;
    padding: 15px;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background: none;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(224, 227, 230);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-button {
      background: none;
    }
    &::-webkit-scrollbar-corner {
      background: none;
    }
  }
}

.modal {
  &-admin {
    // new ng-select
    mvsc-show-more3 {
      display: block;
      margin-bottom: 18px;
    }
    mvsc-help-center-resources-tab {
      .ng-select {
        .ng-clear-wrapper {
          .ng-clear {
            vertical-align: middle;
            font-size: 0;
            line-height: 0;
            color: #999;
            opacity: .3;
            transition: opacity .5s;
            &::before {
              display: inline-block;
              vertical-align: middle;
              content: "\f057";
              font-family: "Font Awesome 5 Pro";
              font-weight: 900;
              font-size: 14px;
            }
            &:hover {
              opacity: 1;
            }
          }
        }
      }
    }
    // ###
  }
}