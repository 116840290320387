// List of chosen filtering options.
// Appears on top of the filtered table.

.options {
  margin-bottom: 10px;
  padding: 9px 9px 4px 9px;
  font-size: 0;
  background-color: desaturate(lighten(@color-corp-lightGray, 3%), 100%);
  
  &.in {
    animation: options-appear .2s;
  }
}

.options.options-hide {
  animation: options-disappear 0.3s;
}

.options-option,
.options-clear {
  margin-right: 3px;
  margin-bottom: 5px;
  
  display: inline-block;
  vertical-align: top;
  
  font-size: @fontSize-base;
  color: lighten(@color-base, 40%);
  line-height: 1;
}

.options-option {
  max-width: 100%;
  position: relative;
  overflow: hidden;
  
  padding: 4px 25px 5px 10px;
  
  border-radius: @border-radius-base;
  border: 1px solid #d8d8d8;
  background: #fff;
  
  cursor: pointer;
  
  &:after {
    position: absolute;
    right: 4px;
    top: 8px;
    
    color: #ccc;
    font-weight: 300;
    font-size: 24px;
    line-height: 2px;
    
    content: '\00d7';
  }
  
  &:hover {
    border-color: #ababab;
    
    .options-option-text {
      text-decoration: line-through;
    }
    
    &:after {
      color: #acacac;
    }
  }
  
  &_manuallySelected {
    background-color: #f2fbff;
    border-color: #c5d5da;
    
    &:after {
      color: #c5d5da;
    }
    
    &:hover {
      border-color: #a1cfdd;
      
      &:after {
        color: #a1cfdd;
      }
    }
  }
}

.options-clear {
  padding: 4px 4px 5px 10px;
  border: 1px solid transparent;
  
  &:hover {
    color: lighten(@color-base, 40%);
  }
}

.options-option_xl {
  margin-bottom: 8px;
  padding: 6px 25px 6px 10px;
  line-height: 1.4em;
  
  &:after {
    top: 12px;
  }
}

.options-option_disabled {
  &:hover {
    cursor: default;
    border: 1px solid #d8d8d8;
    
  }
}

.options-option_undeletable {
  padding-right: 10px;
  
  &:after {
    content: none;
  }
  
  &:hover {
  
    .options-option-text {
      text-decoration: none;
    }
  }
}
