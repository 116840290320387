
.fadeContent {
  position: relative;
  overflow: hidden;
      
  &:after {
    content: '';
    display: block; 
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 20px;
    background: linear-gradient(transparent, @color-white);
  }
}