// NG Animations for Table rows
.animate-table {
  &.enter {
    > td {border-top: none; }
    .animation(fadeInDown .5s);
  }

  &.leave{
    .animation(fadeOutLeft 1s);
  }
}

.animate-form {
  &.ng-enter{
    .animation(fadeIn .5s);
  }
  &.ng-leave{
    .animation(fadeOut 0s);
  }
}

// NG Animations for show/hide elements
.animate-show {
  .animation(fadeIn .5s);
  
  &.ng-hide-add {
    display: none;
  }
  
  &.ng-leave {
    .animation(none);
  }
}

// Animation for populated form fields. E.g. 'Decode VIN', 'Populate from KSR'
.animate-populated:not(.select2-container) {
  .animation(~'pulse .5s, makeGreen 3s');
}

.select2-container.animate-populated {
  .animation(pulse .5s);
  
  .select2-choice {
    .animation(makeGreen 3s);
  } 
}

.animate-warningIndicator {
  @inactive-color: @text-muted;
  @active-color: lighten(saturate(@alert-warning-text, 20%), 5%);

  .blink-warning(@inactive-color, @active-color);
}

.animate-newEntityAppears {
  .animation(pulse .2s);
}

.animate-itemActivated {
  .animation(pulse_withoutFadeIn .2s);
}

.shake {
  .animation(shake .6s);
}

// Spinner
.faa-spin.animated {
	.animation(spin 1.25s linear infinite);
}


/* Mixins and keyframes */

// animate the warning on card hover to catch attention
.blink-warning(@color-from, @color-to) {
  .animation(colorblink 1s ease-in-out infinite);

  @keyframes colorblink {
    0%, 70% {
      color: @color-from;
    }
    10%, 20% {
      color: @color-to;
    }
  }
}