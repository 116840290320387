.pagination {
  .btn-pagination {
    width: 50px;
    //text-align: center;
    background: transparent;
    float: left;
  }

  > li {
    > a,
    > span,
    > .btn-pagination {
      padding: 5px 10px;
    }

    // additional class C&P
    > .btn-pagination {
      position: relative;
      display: block;
      float: left; // Collapse white-space
      line-height: @line-height-base;
      text-decoration: none;
      color: @pagination-color;
      background-color: @pagination-bg;
      border: 1px solid @pagination-border;
      margin-left: -1px;

      &:hover,
      &:focus {
        color: @pagination-hover-color;
        background-color: @pagination-hover-bg;
        border-color: @pagination-hover-border;
      }
    }

    &:first-child {
      > .btn-pagination {
        margin-left: 0;
        border-bottom-left-radius: @border-radius-base;
        border-top-left-radius: @border-radius-base;
      }
    }

    &:last-child {
      > .btn-pagination {
        border-bottom-right-radius: @border-radius-base;
        border-top-right-radius: @border-radius-base;
      }
    }
  }

  > .active > .btn-pagination {
    &,
    &:hover,
    &:focus {
      z-index: 2;
      color: @pagination-active-color;
      background-color: @pagination-active-bg;
      border-color: @pagination-active-border;
      cursor: default;
    }
  }

  > .disabled {
    > .btn-pagination {
      &,
      &:hover,
      &:focus {
        color: @pagination-disabled-color;
        background-color: @pagination-disabled-bg;
        border-color: @pagination-disabled-border;
        cursor: not-allowed;
      }
    }
  }

  &.pagination-sm {
    > li {
      > a,
      > span {
        padding: 3px 7px;
      }
      > .btn-pagination {
        padding: 2px 7px;
      }
    }
  }
}