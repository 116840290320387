@titleMarginTop: 10px;
@padding: 100px;
@paddingTdTop: 10px;
@paddingTdLeft: 7px;
@iconSize: 75px;
@btnMarginTop: 20px;

// Empty states
.emptyState {

    /* temp */
    text-align: center;
    caption-side: bottom;

    >.fa,
    >.fas,
    >.far,
    >.fad {
        font-size: @iconSize;
        line-height: 1em;
    }

    /* end of temp */

    .emptyState-wrapper {
        position: relative;
        padding: @padding;
        text-align: center;
        caption-side: bottom;

        >.fa,
        >.fas,
        >.far,
        >.fad {
            font-size: @iconSize;
            line-height: 1em;
        }

        .fa,
        .fas,
        .far,
        .fad {
            animation-name: pulse;
            animation-duration: 0.84s;
        }

        h2 {
            animation-name: climb_1;
            animation-duration: .24s;
        }

        h3 {
            font-weight: 400;
            margin-bottom: 18px;
        }

        p {
            animation-name: climb_2;
            animation-duration: .48s;
            margin-bottom: 8px;
        }

        .btn-group,
        ul,
        >.btn {
            animation-name: climb_2;
            animation-duration: .60s;
        }
    }

    h2 {
        margin-top: @titleMarginTop;
        line-height: 1.3;
        margin: 7px 0;

        +.empty-actions {
            margin-top: 30px;
        }
    }



    p+.btn,
    p+.btn-group {
        margin-top: @btnMarginTop;
        text-align: left;
    }

    .list-inline {
        margin-top: 34px;
        font-size: @fontSize-base + 5;

        li:not(:last-child) {
            margin-right: 33px;
        }
    }

    &.table-cell {
        padding-top: @padding - 25;
        padding-bottom: @padding + 25;

        .emptyState-wrapper {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &.vh-align_absolute {

        .emptyState-wrapper {
            padding-left: 0;
            padding-right: 0;
            position: relative;
        }
    }

    &.max-content {
        width: max-content;
    }

    .list-favorites {
        .iconInCircle.fa-envelope {
            font-size: 12px;
        }
    }

    .last5vin-block {
        border: 1px solid #dedede;
        display: flex;
        font-size: 14px;
        margin: 30px 0 50px;
        text-align: left;

        &__col {
            flex: 1;
            padding: 20px 30px;
            width: 50%;

            &:first-of-type {
                background-color: #F4F5F6;
                border-right: 1px solid #dedede;
            }
        }

        h2 {
            margin: 0 0 10px;
        }
    }

    .last5vin-info {
        margin-top: 31px;

        &__row {
            border-bottom: 1px solid #D9D9D9;
            display: flex;
            gap: 35px;
            padding-bottom: 10px;
            margin-bottom: 12px;

            div {

                &:first-child {
                    width: 115px;
                }

                &:last-child {
                    padding-right: 15px;
                }
            }

            &:last-of-type {
                border: none;
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }

    .last5vin-action {
        margin-top: 30px;
        display: flex;
        align-items: flex-end;
        gap: 10px;

        &__control {
            margin-bottom: -15px;
            flex: 1;
        }
    }
}

caption.emptyState {
    padding: @padding + @paddingTdTop @padding + @paddingTdLeft;

    &_nothingFound {
        padding: @padding + @paddingTdTop - 50px @padding + @paddingTdLeft - 50px;

        border-bottom: 1px solid darken(@table-border-color, 10%);
    }
}

.emptyState_getStarted {
    .retina-img('@{assets-path}img/getStarted.png', '@{assets-path}img/getStarted_2x.png', 222px, 191px);
    background-position: 7% 12px;
    background-repeat: no-repeat;
}

.emptyState_getStarted_flipped {
    .retina-img('@{assets-path}img/getStarted_flipped.png', '@{assets-path}img/getStarted_flipped_2x.png', 222px, 191px);
    background-position: 92% 12px;
}
