/* PDF viewer, iframe with pdf.js inside ---------------- */
.pdf-viewer {
  width: 100%;
  height: 300px;
  
  display: block;
  
  border: 0;
  
  &_lightbox {
    height: 700px;
  }
}