.modal.trade-credits {
  .modal-dialog {
    width: 900px;
  }

  .modal-body {
    padding: 10px 50px 50px 50px;
  }

  h2 {
    margin: 17px 0 35px 0
  }

  .emptyState h2 {
    margin: 10px 0 9px 0;
  }
}

.trade-credits__search-control {
  position: relative;
  margin-bottom: 5px;
  padding-right: 113px;

  .btn-lg {
    position: absolute;
    right: 0;
    top: 0;

    width: 100px;
  }

  .trade-credits__search-control__hide-link {
    position: absolute;
    top: 14px;
    right: 126px;

    &:focus {color: @color-link;}
  }

  .trade-credits__search-control__search-input {
    padding-right: 160px;
  }
}

.trade-credits__search-results {
  margin-bottom: 6px;

  .trade-credits__search-results-item:not(:last-child) {
    margin-bottom: 10px;
  }
}

.trade-credits__search-results-item {
  padding: 25px 20px;
  border: 1px solid @color-border_light;
  border-radius: @border-radius-base;

  &:hover {
    cursor: pointer;

    .trade-credits__user-name {
      .helper-link-hover();
    }

    .theme("default", {
      background: @themeBrand-tabs-tab-hover-bgcolor;
    });
    .theme("black", {
      background: @themeBlack-tabs-tab-hover-bgcolor;
    });
  }

  table {
    width: 100%;
  }

  .form-actions {
    margin: 13px 0 0 0;
  }

  .text-mutedMore {
    margin-right: 2px;
  }

  .fa-user {
    margin-left: -19px;
    margin-right: 5px;
  }

  .btn-default:focus:not(.active) {
    background: @btn-default-bg;
    border-color: @btn-default-border;
  }

  .btn-xs + .btn-xs {
    margin-left: .15em;
  }

  .row:not(:last-child) {
    margin-bottom: 5px;
  }
}
