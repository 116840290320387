// floatableLabel -- allows use input without regular labels
// input placeholder is being used as a label (while value == ''),
// if value != '', label appears from nowhere and slides to the top of the input
// * UI.handlers.floatableLabel must bi initialized
// * .mvsc-floatableLabel_value — is being set by the handler (if value != '')
.mvsc-floatableLabel {
  position: relative;
  
  &.mvsc-floatableLabel_value .mvsc-floatableLabel-label {
    top: -7px;
    opacity: 1;
  }
  
  label {
    cursor: text;
  }
}


// pretty same style we have for labels in mvsc-flexibleContext control
// going something to change? don't forget about consistency
.mvsc-floatableLabel-label {
  position: absolute;
  left: 5px;
  top: 5px;
  
  margin-bottom: 0;
  padding: 0 6px;
  
  max-width: 90%;
  
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  
  background: @color-white;
  font-size: @fontSize-base - 2 !important;
  line-height: 1em;
  
  opacity: 0;
  
  transition: top .2s, opacity .2s;
}