.userpic {
  position: relative;
  text-align: center;
  
  .userpic-view {
    margin-top: 30px;
  }
  
  .userpic-change {
    padding: 20px 0;
    border: 1px dotted darken(@input-border, 10%);
    border-radius: @border-radius-base;
  }
  
  /* actual userpic */
  .userpic-imgContainer {
    margin-bottom: 10px;
    
    img {
      width: 100%;
      height: auto;
    }
  }
  
  .userpic-changeUserpic {
    .fa {
      position: relative;
      top: 1px;
    }
  }
  
  /* striked --or-- + link */
  .userpic-change-uploadLink {
    margin-left: 10px;
    margin-right: 10px;
    
    .userpic-change-upload-or {
      position: relative;
      display: block;
      margin-bottom: 3px;
      
      span {
        position: relative;
        z-index: 2;
        display: inline-block;
        padding-left: 8px;
        padding-right: 8px;
        background-color: #fff;
      }
      
      &:after {
        position: absolute;
        z-index: 1;
        display: block;
        content: '';
        left: 0;
        right: 0;
        top: 50%;
        margin-top: 1px;
        height: 1px;
        background-color: @color-border_light;
      }
    }
  }
  
  /* 'cancel' btn */
  .userpic-change-cancel {
    display: block;
    position: absolute;
    overflow: hidden;
    right: -7px;
    top: -7px;
    height: 15px;
    padding: 2px 5px 0;
    
    color: #fff;
    background-color: darken(@input-border, 10%);
    transition: background-color ease-in-out .15s;
    
    line-height: 12px;
    font-size: 10px;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;

    border-radius: 99px;
    transition: opacity ease-in-out .15s;
    
    .fa {
      position: relative;
      top: 0px;
      margin-top: -2px;
      margin-bottom: -2px;
      margin-right: -2px;
      //font-size: 11px;
      line-height: 12px;
    }
    // space to the text
    .fa + * {
      margin-left: 5px;
    }
    
    &:hover {
      background-color: @color-link;
    }
  }
  
  .userpic-change-restrictions {
    margin-bottom: 0;
    line-height: 1em;
  }
  
  /* predefined userpic image */
  .userpic-change-element {
    margin-bottom: 10px;
    margin-left: 4px;
    margin-right: 4px;
    display: inline-block;
    
    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
    }
  }
  
  /* */
  .userpic-change-overlay {
    position: absolute;
    z-index: 3;
    left: 1px;
    right: 1px;
    top: 1px;
    bottom: 1px;
    background: rgba(255,255,255,.97);
  }
  
  /* progress bar */
  .userpic-change-uploadBar {
    position: absolute;
    z-index: 4;
    left: 10px;
    right: 10px;
    top: 50%;
    margin-top: -14px;
    
    .upload-status {
      position: absolute;
      top: 23px;
      left: 0;
      right: 0;
      .small();
    }
  }
  
  /* uploading image state */
  .userpic-change_uploading {
    .userpic-change-cancel {
      cursor: default;
      background-color: lighten(@input-border, 5%) !important;
    }
  }
}

.modal-row-sidebar {
  .userpic {
    margin-top: 15px;
    
    .userpic-imgContainer {
      img {
        width: 80% !important;
        height: auto !important;
      }
    }
  }
}