.efileForm {
    fieldset:not(.efileForm-fieldset_regularMargin) {
        margin-bottom: @efileForm-fieldset-margin-bottom;
    }

    fieldset {
        h3 {
            margin-bottom: 0;
            padding-bottom: 2px;
            font-size: 14px;
            font-weight: 500;
            line-height: 15px;
            text-transform: uppercase;
        }

        .efileForm-fieldset-header {
            margin-bottom: @efileForm-fieldset-header-margin-bottom;
            padding-bottom: @efileForm-fieldset-header-padding-bottom;
            border-bottom: 1px solid @hr-border-modal;
            line-height: 19px;

            .btn {
                margin-bottom: -2px;
            }
        }

        // inherited from _forms.less
        .row {
            .row.infiniteCols()
        }
    }

    .form-group {
        .hasSpinner.input-group mvsc-vin-type-dropdown2 {
            width: auto;
        }

        mvsc-vin-type-dropdown2 {
            display: table-cell;
            width: 1%;

            .input-group-btn {
                width: 100%;
                .angle {
                    margin-left: 3px;
                }
            }

            .dropdown {
                &-item {
                    display: block;
                    border: none;
                    background: none;
                    width: 100%;
                    padding: 6px 13px;
                    color: #000;
                    font-weight: normal;
                    line-height: 18px;
                    white-space: nowrap;
                    text-align: left;
                    &:hover {
                    background-color: #21bae7;
                    color: #fff;
                    }
                }
            }
        }
    }

    .btn + .btn {
        margin-left: 10px;
    }

    .cancel {
        margin-left: 18px;
    }

    .ng-select .ng-clear-wrapper {
        margin-right: 4px;
    }

    .inline-block_marginRight {
        margin-right: 8px;
    }

    .checkbox-inline {
        padding-left: 35px;

        .form-group.checkbox {
            display: flex;
            align-items: center;

            .fa {
                order: 10;
                margin-left: 2px;
                transform: translateY(-1px);
            }
        }
    }

    .padding-fix {
        padding-left: 0;
    }

    fieldset fieldset {
        margin-bottom: 20px !important;

        .efileForm-fieldset-header {
            margin-bottom: 15px;
            padding-bottom: 0;
            border-bottom: 0;
        }

        h3 {
            //font-weight: 400;
            text-transform: none;
        }
    }

    fieldset h4 {
        line-height: 18px;
    }

    .row mvsc-form-and-fees-transactions-dynamic-field {
        font-size: 0;
    }
}
