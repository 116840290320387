// Entities on a pages
.entity {
    // add negative space
    .well-sm {
        //margin-left: -10px;
        //margin-right: -10px;
        padding-top: 14px;
        padding-bottom: 14px;
        // remove the spacing from the last row in the well
        .row:last-child .form-group {
            margin-bottom: 0;
        }
    }
    .nav-tabs {
        margin-bottom: 25px;
        //margin-left: -10px;
        //margin-right: -10px;
        padding-left: 10px;
    }

    .form-group label {
        white-space: nowrap;

        > p {
            white-space: normal;
        }
    }

    h3.inline-block + .btn.inline-block {
        position: relative;
        top: -3px;
    }
}

.entity-back {
    margin-bottom: 20px;
    line-height: 18px;

    &__separator {
        margin: 0 13px;

        i {
            font-size: 13px;
            font-weight: 300;
        }
    }
}

.entity-back + h1 {
    margin-top: 0;
}

.entity-header {
    margin-bottom: 24px;

    h1 {
        margin-bottom: 0;
        margin-top: 5px;
        line-height: 36px;

        *:not(.ng-span) {
            vertical-align: middle;
        }

        .badge {
            top: 0;
        }
    }

    h1 small {
        font-size: @fontSize-base;
        line-height: normal;

        > * {
            margin-right: .5em;
        }

        &.label {
            font-size: @fontSize-base - 3;
        }
    }

    h1 > .fa {
        font-size: @fontSize-base;
    }
}

.entity-downloads {
    margin-top: 10px;

    .list-inline li {
        line-height: 18px;
        a {
            display: inline-block;
            line-height: inherit;
        }
    }
}

.entity-actions {
    display: flex;
    .pull-right();

    mvsc-action-dropdown2 {
        +.btn {
            margin-left: 7px;
        }

        &:not(:first-child) {
            >div,>button,>span {
            margin-left: 7px;
            }
        }
    }

    &--valign {
        align-items: center;
    }
}

.entity-value {
    display: block;
    font-size: @fontSize-base;
    line-height: 18px;

    strong&.text-info{
        font-weight: 700;
    }

    i {
        font-size: @fontSize-base;
    }

    &_inline {
        display: inline-block;
        margin-right: 10px;
    }
}

.entityPreview {
    // Enlarges the size of input group for a larger font-size used in entityPreview
    // This should not be applied when in modal.
    // it may be obsolete usage

    // positions of grouped input controls: i.e. with $ signs
    .table .input-group{
        margin-bottom: -6px;
    }

    label&, //label.entityPreview for custom look of labels in form.entity
    label {
        color: darken(@color-muted, 10%);
        font-size: 13px;
        line-height: 20px;

        &.inTable{
            margin: 0;
        }

        &.text-danger {
            .text-danger();
        }

        &.text-success {
            .text-success();
        }
    }

    fieldset {
        sub-fieldset {
            h4 {
                margin-top: 3px !important;
                margin-bottom: 16px !important;
                font-size: 18px !important;
            }
        }

        sub-fieldset + sub-fieldset {
            margin-top: 23px;
        }
    }

    .status-tracker {
        margin: -17px 0 20px 0;
    }
}

//temporary modifier

.entityPreview_v2 {

    .alert {
        margin-bottom: 17px;
    }

    fieldset {
        margin-bottom: 58px;
        border-bottom: 1px solid fade(@hr-border-modal, 50%);

        h2 {
            font-size: @fontSize-base + 11;
            margin-bottom: 11px;
            font-weight: 400;

            .badge {
                margin-left: 9px;
                top: -4px;
            }
        }

        fieldset {
            margin: 0;

            &:not(:last-child) {
                margin: 0 0 16px 0;
            }

            &:last-child {
                border: none;
            }

            h2 {
                margin: 31px 0 13px 0;
                font-size: @fontSize-base + 2;
                font-weight: 500;
                text-transform: uppercase;

                & + fieldset > h2 {
                    margin-top: 12px;
                }
            }

            fieldset {
                margin: 0;

                &:not(:last-child) {
                    margin: 0 0 16px 0;
                }

                &:last-child {
                    border: none;
                }

                h2 {
                    margin: 25px 0 13px 0;
                    font-size: @fontSize-base;
                }
            }
        }
    }

    .form-group {
        margin: 21px 0 20px 0;
    }

    div[class^="col-xs-"] {
        position: static;
    }

    div[class^="col-xs-"]:nth-child(4n+1) {

        &:before {
            display: block;
            position: absolute;
            left: 20px;
            right: 20px;
            height: 1px;
            background-color: @hr-border-modal;
            opacity: .5;
            content: '';
        }
    }
}

.entity-markRow {

    .entity-markRow-item {
        font-size: @fontSize-base + 1;
        display: inline-block;
        margin-bottom: 10px;

        &:not(:last-child) {
            margin-right: 10px;
        }
    }
}

.entity-content table tr td:last-child .btn-fa {
    margin-right: 10px;
}

.entity-content table .btn-fa:hover {
    .fa-star-o {
        color: @color-status-warning;
    }
}

.entity__doc-header {
    margin-bottom: 20px;

    .btn {
        margin-right: 15px;
    }
    .fa {
        margin-right: 5px;
    }
}
