//
// Custom icons
// --------------------------------------------------

a.fileTypeIco {
  &:hover {
    .filter(drop-shadow(0 0 2px rgba(0,0,0,.2)))
  }

  &_invertShadow {
    &:hover {
      //.filter(drop-shadow(0 0 2px rgba(255,255,255,.6)))
    }
  }
}

// Font awesome
[class^="fa-"],
[class*=" fa-"] {
  margin-top: 1px;
  font-size: 1.08em;
  line-height: 14px;
}

// Make font awesome icons fixed width
.dropdown-menu li [class^="fa-"],
.dropdown-menu li [class*=" fa-"] {
  width: 1em;
  text-align: center;
}

li [class^="fa-"].icon-large,
li [class*=" fa-"].icon-large {
  // increased font size for icon-large
  width: 1.5625em;
}

.checkbox{
  .fa-fw {
    &.fa-offset-left {
      margin-left: -(18em / 14)-.25em;
    }
  }
}
.fa-fw {
  &.fa-offset-left {
    margin-left: -(18em / 14);
  }
}

// Quick override icon Sizes forced above
// TODO: Optimize the code above later

/* makes the font 33% larger relative to the icon container */
.fa-lg {
  font-size: (4em / 3);
  line-height: (3em / 4);
  vertical-align: -15%;
}
.fa-2x { font-size: 2em !important; }
.fa-3x { font-size: 3em !important; }
.fa-4x { font-size: 4em !important; }
.fa-5x { font-size: 5em !important; }

//End icon size override


.close {
  font-size: 22px;
  outline: none;
  -webkit-font-smoothing: antialias;

  // make it pixel-thin on retina
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    -webkit-font-smoothing: none;
  }

  &:hover,
  &:focus {
    color: @close-color;
    text-decoration: none;
    cursor: pointer;
  }

  // Additional properties for button version
  // iOS requires the button element instead of an anchor tag.
  // If you want the anchor version, it requires `href="#"`.
  button& {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    font-weight: 300;
  }
}

.favoritesLinkIco {
  cursor: pointer;

  &:hover {
    color: @color-status-warning;
  }

  &.favoritesLinkIco_checked,
  &.favoritesLinkIco_checked:hover {
    color: darken(@color-status-warning, 15%);
    &:before {
      content: '\f005';
      font-family: "Font Awesome 5 Pro";
      font-weight: 900;
    }
  }
}


// Icon inside a fancy circle
.iconInCircle {
  display: inline-block;
  height: 30px;
  width: 30px;
  text-align: center;
  line-height: 30px;
  font-size: @fontSize-base + 2;
  border-radius: 50%;
}

.iconInCircle_large {
  width: 50px;
  height: 50px;
  line-height: 50px;
  font-size: @fontSize-base + 7;
}

.iconInCircle_info {
  color: @btn-info-color;
  .theme("default", {
    background: @themeBrand-btn-info-bg;
  });
  .theme("black", {
    background: @themeBlack-btn-info-bg;
  });
  .theme("classic", {
    background: @themeClassic-btn-info-bg;
  });
}

a:hover > .iconInCircle_info {
  .theme("default", {
    background: darken(@themeBrand-btn-info-bg, 8%);
  });
  .theme("black", {
    background: darken(@themeBlack-btn-info-bg, 8%);
  });
  .theme("classic", {
    background: darken(@themeClassic-btn-info-bg, 8%);
  });
}


.iconInCircle_black {
  background: rgba(0,0,0, .5);
  color: @color-white;
}

a:hover > .iconInCircle_black {
  background: rgba(0,0,0, .67);
}


// Vehicle types icons
i.vehicle-type {
  position: relative;
  bottom: -2px;
  display: inline-block;
  height: 16px;
  .retina-img('@{assets-path}img/vehicle-icons-sprite.png', '@{assets-path}img/vehicle-icons-sprite_2x.png', 272px, 16px);

  &_passenger {
    width: 21px;
    background-position: 0 0;
  }

  &_camper {
    width: 25px;
    background-position: -102px 0;
  }

  &_moped {
    width: 20px;
    background-position: -158px 0;
  }

  &_motorcycle {
    width: 23px;
    background-position: -178px 0;
  }

  &_motorHome {
    width: 31px;
    background-position: -46px 0;
  }

  &_ltTrailer {
    width: 25px;
    background-position: -77px 0;
  }

  &_travelTrailer {
    width: 31px;
    background-position: -127px 0;
  }

  &_truck {
    width: 25px;
    background-position: -21px 0;
  }

   &_mediumSpeed {
    width: 25px;
    background-position: -201px 0;
  }

  &_lowSpeed {
    width: 22px;
    background-position: -226px 0;
  }

  &_allTerrain {
    width: 24px;
    background-position: -248px 0;
  }
}




