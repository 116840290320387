ui-modal-window {

  &.ui-date-range-picker-modal-window,
  &.ui-date-picker-modal-window {
    z-index: 1055;

    date-range-picker-modal {
      display: block;
      background: #fff;
      box-shadow: 0 5px 15px rgba(0,0,0,.5);
      border-radius: 2px;
      .ui-daterangepicker {
        background: none !important;

        &-controls {
          background: none !important;
          justify-content: flex-start;
          border-top: none;
          position: relative;
          padding: 38px 50px 46px !important;
          &::before {
            content: "";
            height: 1px;
            background-color: #e0e3e6;
            position: absolute;
            top: 0;
            left: 50px;
            right: 50px;
          }

          button + button {
            margin-left: 0 !important;
            margin-right: 10px;
          }

          .btn {
            font-size: 13px !important;
            line-height: 16px !important;
            padding: 7px !important;
            &:not(.btn-link) {
              width: 87px;
            }

            &-success {
              order: 1;

              background-color: #2a9647 !important;
              border-color: #24823e !important;
              &:not(:disabled) {
                &:hover,
                &:active {
                  color: #fff;
                  background-color: #217638 !important;
                  border-color: #175227 !important;
                }
              }
            }
            &-default {
              order: 5;

              color: #333 !important;
              background-color: #ebedef !important;
              border-color: #d2d6db !important;
              &:not(:disabled) {
                &:hover,
                &:active {
                  background-color: #d4d9dd !important;
                  border-color: #afb7bf !important;
                }
              }
            }
            &-link {
              order: 10;

              color: #296189 !important;
              border-color: transparent !important;
              &:hover:not(:disabled) {
                color: #17374e;
                text-decoration: underline;
              }
            }
          }
        }

        &-modal {
          &-header {
            display: block;
            position: relative;
            .close {
              position: absolute;
              right: 15px;
              top: 5px;
              z-index: 1;
              font-size: 47px;
              font-weight: 300;
              margin-top: 0;
            }
          }
        }

      }

      date-range-picker {
        .ui-daterangepicker {
          &::before {
            display: block;
            content: "Date";
            font-weight: 300;
            font-size: 27px;
            line-height: 1.1;
            font-family: 'Roboto',sans-serif;
            color: #000;
            margin: 42px 50px 20px;
          }

          &-to {
            margin-left: 32px;
            margin-right: 40px;
          }

          &-container {
            position: relative;
            padding: 34px 50px 38px;
            &::before {
              content: "";
              height: 1px;
              background-color: #e0e3e6;
              position: absolute;
              top: 0;
              left: 50px;
              right: 50px;
            }
          }

          .range {
            padding-left: 40px;
            padding-bottom: 0;
            &-item {
              &:not(:first-child) {
                margin-top: 8px;
              }

              button {
                width: 114px;
                padding: 5px 7px;

                font-weight: 500;
                font-size: 12px;
                line-height: 1.5;
                border-radius: 2px;
                color: #333;
                background-color: #ebedef;
                border-color: #d2d6db;

                &.active,
                &:hover {
                  color: #333;
                  background-color: #d4d9dd;
                  border-color: #afb7bf;
                }
              }

            }
          }

        }

      }

    }

    datepicker-calendar-view {
      display: flex;
      flex-direction: column;
      padding-top: 3px;

      .calendar {
        &-table {
          width: auto;
          td, th {
            width: 37px !important;
            height: 31px !important;
          }
        }

        &-week {
          th {
            font-weight: 500 !important;
            font-size: 12px !important;
            text-transform: uppercase;
            padding-top: 14px !important;
            padding-bottom: 6px !important;
          }
        }

        &-select {
          font-size: 16px;
          color: #29618D;
        }

        &-month {
          &-name {
            label {
              margin-bottom: 5px !important;
            }
          }
        }

        &-range {
          &-prev,
          &-next {
            span {
              font-family: "Font Awesome 5 Pro"!important;
              transform: translateY(-4px);
              &::before {
                content: @fa-var-angle-left !important;
                display: inline-block;
                vertical-align: top;

                font-weight: 900;
                font-size: 18px;
              }
              svg {
                display: none !important;
              }
            }
          }

          &-next {
            span {
              &::before {
                transform: rotate(180deg);
              }
            }
          }
        }

        &-day {
          span {
            font-size: 14px;
            width: 37px !important;
            height: 31px !important;
            border-radius: 0 !important;
          }

          &.active {
            span {
              background: #21bae7 !important;
            }
          }

          &.disabled {
            color: #ccc !important;
          }

        }
      }
    }

    date-picker {
      height: 335px;
      .ui-datepicker {
        border-radius: 2px;
      }
      .ui-modal-content {
        background: none;
        box-shadow: none;
        border-radius: 0;
      }
    }

    date-picker-with-labels {
      display: block;
      height: 340px;
      .ui-daterangepicker {
        box-shadow: 0 5px 15px rgba(0,0,0,.5);
        &-container {
          padding: 40px;
          .range {
            padding-left: 40px;
            padding-bottom: 0;
            margin-bottom: 0;

            &-item {
              &:not(:first-child) {
                margin-top: 8px;
              }

              button {
                width: 114px;
                padding: 5px 7px;

                font-weight: 500;
                font-size: 12px;
                line-height: 1.5;
                border-radius: 2px;
                color: #333;
                background-color: #ebedef;
                border-color: #d2d6db;

                &.active,
                &:hover {
                  color: #333;
                  background-color: #d4d9dd;
                  border-color: #afb7bf;
                }
              }

            }
          }
        }
      }

    }
  }
}
