.tokenfield {

  .select2-choices {
    min-height: 86px;
  }
  
  .select2-choices {
    padding: 0 8px 6px 8px;
    
    .select2-search-field {
    
      .select2-input {
        padding: 0;
        height: 31px;
      }
    }
    
    .select2-search-choice {
      margin: 6px 4px 0 0;
      padding: 2px 23px 4px 7px;
      font-size: @font-size-base - 1;
      background: @color-corp-lightGray;
      border: none;
      line-height: 15px; 
  
      & > div {
        display: inline-block;
        vertical-align: middle;
        max-width: 150px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      
      .select2-search-choice-close {
        position: absolute;
        right: 5px;
        top: 11px;
        left: auto;
        font-family: @font-family-base;
        background: none !important;
        text-decoration: none;
        
        &:after { 
          content: '×';
          position: relative;
          font-weight: 200;
          font-size: @font-size-base + 6;
          color: @color-base;
          opacity: 0.2;
        }
        
        &:hover:after {
          opacity: 0.5;
        }
      }
      
      &.select2-search-choice_invalid {
        background: @state-danger-bg;
      }
    }
  }
}

.tokenfield_hideDropdown {
  display: none !important;
}