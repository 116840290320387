.flot {
  width: 100%;
  height: 266px;
  font-size: 14px;
  line-height: 1.2em;
}

.flot-wrapper {
  padding: 34px 0 18px;
}

.flot-x-axis > div {
  position: relative;
}

.flot-x-axis > div:after {
  content: '';
  position: absolute;
  top: -12px;
  left: 50%;
  width: 1px;
  height: 6px;
  background: #eef0f1;
}

.flot-base,
.flot-overlay {
  z-index: 1;
}

.flot-tooltip {
  .popover {
    width: 130px;
    height: 54px;
    
    display: block;
    text-align: center;
    min-width: 0;
  }
}
