// list of filterable products (any entities — vehicles, loans, transactions...)

.products {
  position: relative;
  
  &_processing:after {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background: rgba(255,255,255,.5);
    
    content: '';
    z-index: @zindex-processing;
  }
}