body {
  padding-top: @headerHeight;
}

.header {
  padding-left: @margin-app;
  padding-right: @margin-app;
  min-width: 1000px;
  border-width: 0;
  border-radius: 0;

  &:not(.navbar-fixed-top) {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: @zindex-navbar-fixed;
  }

  & > .container {
    position: relative;
  }

  &.navbar-default > .container {
    .theme("default", {
      background-color: @themeBrand-color-corp-darkestBlue;
    });
    .theme("black", {
      background-color: @themeBlack-color-corp-darkestBlue;
    });
    .theme("classic", {
      background-color: @themeClassic-color-corp-darkestBlue;
    });
  }

  .container:before {
    display: block;
    border-top: 4px solid;
    margin-bottom: -4px;
    position: relative;
    top: @headerHeight;
    .theme("default", {
      border-top-color: @themeBrand-color-corp-skyBlue;
    });
    .theme("black", {
      border-top-color: @themeBlack-color-corp-skyBlue;
    });
    .theme("classic", {
      border-top-color: @themeClassic-color-corp-skyBlue;
    });
  }

.avatar {
    margin-right: 6px;
    vertical-align: middle;
  }

  // ----- Support notifications dropdown in container
  .header-support {

    .subContent {
      width: 325px;
    }

  }

  .navbar-nav {

    > li {
      margin-left: 0;

      &:not(.active) > a:active {
        line-height: 51px;

        > span.badge {
          margin-top: -5px;
        }
      }

      &.show > a {
        color: @white;
        .theme("default", {
          background: lighten(@themeBrand-color-corp-darkestBlue, 7%);
        });
        .theme("black", {
          background: lighten(@themeBlack-color-corp-darkestBlue, 7%);
        });
        .theme("classic", {
          background: lighten(@themeClassic-color-corp-darkestBlue, 7%);
        });
      }

      > a {
        padding: 0 15px;
        height: @headerHeight;
        line-height: @headerHeight;
        color: @color-status-available;
        font-weight: 500;
        .theme("default", {
          color: lighten(desaturate(@themeBrand-color-corp-skyBlue, 60%), 10%);
        });
        .theme("black", {
          color: lighten(desaturate(@themeBlack-color-corp-skyBlue, 60%), 10%);
        });
        .theme("classic", {
          color: lighten(desaturate(@themeClassic-color-corp-skyBlue, 60%), 10%);
        });

        > .fa {
          font-size: @fontSize-base + 5;
        }

        &:hover,
        &:focus {
          color: @white;
          .theme("default", {
            background: lighten(@themeBrand-color-corp-darkestBlue, 7%);
          });
          .theme("black", {
            background: lighten(@themeBlack-color-corp-darkestBlue, 7%);
          });
          .theme("classic", {
            background: lighten(@themeClassic-color-corp-darkestBlue, 7%);
          });
        }
      }

      &.active {

        > a,
        > a:hover {
          color: @white;
          font-weight: 500;
          .theme("default", {
            background: lighten(@themeBrand-color-corp-darkestBlue, 7%);
          });
          .theme("black", {
            background: lighten(@themeBlack-color-corp-darkestBlue, 7%);
          });
          .theme("classic", {
            background: lighten(@themeClassic-color-corp-darkestBlue, 7%);
          });
        }
      }
    }

    &.navbar-right {

      > li {

        &.show > a > i.fa {
          opacity: 1;
        }

        > a {
          .fa:not(.fa-angle-down) {
            color: #fff;
            opacity: .25;
          }

          .fa-angle-down {
            position: relative;
            bottom: -2px;
          }

          &:hover,
          &:focus {
            > .fa {
              opacity: 1;
            }
          }
        }

        &.dropdown {

          &.open > a {
            .theme("default", {
              background: lighten(@themeBrand-color-corp-darkestBlue, 7%);
            });
            .theme("black", {
              background: lighten(@themeBlack-color-corp-darkestBlue, 7%);
            });
            .theme("classic", {
              background: lighten(@themeClassic-color-corp-darkestBlue, 7%);
            });

            .fa {
              color: @white;
            }
          }
        }

        &:last-child > a {
          padding-right: 10px;
          margin-left: 8px;
        }

        &:not(:last-child) > a {

          padding: 0 18px;

          .badge {
            .vertical-align;
            position: absolute;
            left: 53%;
            top: 50%;
            margin-top: -6px;
          }
        }
      }
    }
  }

  .userNotifications {
    width: 300px;
  }

  .dropdown-menu {
    z-index: @zindex-navbar-fixed;
  }


  // old styles. Need to remove after VITU-6550 is resolved
  .header-notifications {

    .scroller {
      max-height: 331px;
      padding-right: 0;
    }

    .slimScrollDiv {
      //Mixin needs to create arrow for this wrapper because now it is has .dropdown-menu styles
      .dropdown-menu_arrow;

      //All importants in .slimScrollDiv rules needs to replace inline styles
      position: absolute !important;
      overflow: visible !important;
      display: none;
      right: 0;
      background: @white;
      border: 1px solid #ddd;
      box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
    }

    &.open .slimScrollDiv {
      display: block;
    }

    &.open .slimScrollDiv,
    &.open .slimScrollDiv .scroller {
      height: auto !important;
    }

    .slimScrollDiv {
      padding: 0;
    }
  }

  .header__alert {
    position: relative;
    display: inline-block;
    padding: 3px 11px 0 11px;
    height: 38px;
    top: 6px;
    margin-right: 4px;
    background: #14404E;
    color: @color-status-available;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
    border-radius: 5px;
    margin-left: 4px;
    float: right;
    .theme("default", {
      color: lighten(desaturate(@themeBrand-color-corp-skyBlue, 60%), 10%);
    });
    .theme("black", {
      color: lighten(desaturate(@themeBlack-color-corp-skyBlue, 60%), 10%);
    });
    .theme("classic", {
      color: lighten(desaturate(@themeClassic-color-corp-skyBlue, 60%), 10%);
    });

    a {
      display: block;
      color: @color-status-available;
      .theme("default", {
        color: lighten(desaturate(@themeBrand-color-corp-skyBlue, 60%), 10%);
      });
      .theme("black", {
        color: lighten(desaturate(@themeBlack-color-corp-skyBlue, 60%), 10%);
      });
      .theme("classic", {
        color: lighten(desaturate(@themeClassic-color-corp-skyBlue, 60%), 10%);
      });

      &:hover {
        text-decoration: none;
        color: #fff;
      }
    }

    &:hover {
      background: #1A4553;
      text-decoration: none;
    }

    i.fa {
      display: inline-block;
      width: 16px;
      font-size: 15px;
      margin-right: 3px;
    }
  }
}

.header-solutionsNav {

  > li > a {

    &:hover,
    &:focus {
      background-color: transparent;
    }

    .badge {
      position: absolute;
      right: 5px;
      left: auto;
      top: 12px;
      margin: 0;
    }
  }

  > .active > a {

    &,
    &:hover,
    &:focus {
      font-weight: bold;
      cursor: default;
    }

    &:before {
      position: absolute;
      left: 50%;
      bottom: 0;
      margin-left: -6px;
      display: inline-block;
      border-right: 6px solid transparent;
      border-bottom: 6px solid;
      border-left: 6px solid transparent;
      content: '';
      .theme("default", {
        border-bottom-color: @themeBrand-color-corp-skyBlue;
      });
      .theme("black", {
        border-bottom-color: @themeBlack-color-corp-skyBlue;
      });
      .theme("classic", {
        border-bottom-color: @themeClassic-color-corp-skyBlue;
      });
    }
  }
}

.dropdown-menu_wide {
  width: 325px;
}


.header-profile_small {
  .username {
    display: none;
  }
}
