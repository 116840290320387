.quick-preview {
  visibility: hidden;
  position: absolute;
  top: 61px;
  right: -400px;
  width: 400px;
  max-height: 800px;
  min-height: 400px;
  padding: 25px 10px 18px 30px;
  background: @white;
  box-shadow: -2px 0px 8px 0px rgba(0, 0, 0, 0.15);
  transition: right .2s, opacity .2s, max-height .5s;
  opacity: 0;
  z-index: @zindex-tooltip - 2;
  overflow: hidden;
  
  &.in {
    visibility: visible;
    right: 18px;
    opacity: 1;
    transition: right .2s, opacity .2s;
  }
  
  &.out {
    right: -400px;
    opacity: 0;
    transition: right .2s, opacity .2s;
  }
  
  .quick-preview__content {
    opacity: 1;
    transition: opacity .3s;
    
    &_hidden {
      visibility: hidden;
      opacity: 0;
    }
  }
  
   h1 {
    margin-top: 0px;
    margin-bottom: 20px;
    line-height: 1em;
    font-weight: 400;
    
    > a {
      text-decoration: underline;
      font-size: @font-size-base + 14;
      line-height: 1.1em;
    }
    
    small {
      margin-left: -4px;
      font-size: @font-size-base;
      line-height: normal;
      
      .label {
        margin-right: 5px;
        position: relative;
        top: -5px;
      } 
    }
  }
  
  .scroller {
    padding-right: 20px;
    display: flex;
    flex-direction: column;
  }
  
  .close {
    position: absolute;
    top: -4px;
    right: 6px;
    line-height: 1;
    font-size: 35px;
  }

  .quick-preview__actions {
    margin-bottom: 30px;
  }
  
  .quick-preview__sub-header {
    padding-top: 7px;
    padding-bottom: 10px;
    border-bottom: 1px solid @table-border-color;

    .btn.pull-right {
      margin-left: 5px; 
    }

    h4 {
      display: inline-block;
      margin: 0px;
      font-size: 14px;
      font-weight: 500;
    }
  }
  
  .quick-preview__docs {
    margin-top: 14px;
  }
  
  .quick-preview__docs__doc {
    margin-bottom: 7px;
    
    .checkbox {
      margin-top: 0;
      display: inline-block;
      vertical-align: top;
    }
  }
  
  .quick-preview__docs__doc__description {
    display: inline-block
  }
  
  
  
  .list-icons {
    margin-top: 19px;
    margin-bottom: 0;
  }
  
   .list-icons .fa {
     font-size: 28px;
     top: 10px;
     left: -4px;
  }
  
   .list-icons li {
     padding-left:  40px;
     margin-bottom: 17px;
  }
  
   .list-icons li a {
     display: inline-block;
     margin-bottom: 2px;
  }
  
  .table {
    margin-bottom: 30px;
  }
  
  .mvscSpinner-spinner {
    width: 100px;
    height: 100px;
    margin-left: 25px;
  }
    
  .emptyState {
    position: relative;
    flex-grow: 2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-height: 80px;
    
    .fa {
      font-size: @font-size-base + 15;
      color: #d8d8d8;
    }
    
    h2{
      font-weight: 400;
      font-size: @font-size-base;
      color: @color-muted;
    }
    
    .emptyState-wrapper {
      padding: 0;
    }
  }
}

.quick-preview-backdrop {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: @zindex-tooltip - 2;
  background-color: @color-white;
  opacity: .7;
}
  
  