.lightbox {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%; // need for .vertical-align;
  min-width: 1000px;
  text-align: center;
  background-color: rgba(0,0,0,.75);
  // ???
  z-index: @zindex-lightbox;

  .close {
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 47px;
    color: @dropdown-fallback-border;
    opacity: 1;
    z-index: 1;
    .text-shadow(none);

    &:hover,
    &:focus {
      color: @dropdown-fallback-border;
      opacity: .75 !important;
    }
  }

  &.mfp-gallery {

    .lightbox-pageNumbers {
      display: inline-block;
    }
  }
}

.lightbox-image {
  .vertical-align;
}

/* Controls */
.lightbox-buttonNext,
.lightbox-buttonPrev {
  .vertical-align;
  position: absolute;
  cursor: pointer;
  .user-select(none);

  &.fa {
    color: @dropdown-fallback-border;
    font-size: 50px;
  }

  &:hover {
    opacity: .75;
  }
}

.lightbox-buttonPrev {
  left: 60px;
}

.lightbox-buttonNext {
  right: 60px;
}

.lightbox-pageNumbers {
  display: none;
  position: absolute;
  bottom: 36px;
  left: 50%;
  margin-left: -40px;
  padding: 0 20px;
  height: 32px;
  line-height: 32px;
  color: @white;
  background: rgba(0,0,0,.6);
  border-radius: 16px;
}

.mfp-image-holder {
  height: 100%;

  > div {
    height: 100%;

    > div {
      height: 100%;
    }
  }
}

.mfp-preloader {
  display: none;
}

.mfp-hide {
  display: none;
}

.mfp-container {

  height: 100%;

  &:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
  }
}

.mfp-content {
  display: inline-block;
  vertical-align: middle;
}
