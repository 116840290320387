.complete-mannualy-fieldset {
  padding-bottom: 18px;
  
  &.fieldset.separated {
    padding-top: 15px;
    
    h4 {
      margin-top: 15px;
      margin-bottom: 12px;
      font-weight: 400;
      font-size: @fontSize-base + 5;
      
      .pseudoLink {
        line-height: 1.4em;
      }
    }
    
    &.complete-mannualy-fieldset_paddingTop {
      padding-top: 30px;
      
      .radio {
        margin-top: 0;
      }
    }
  }
  
  
}