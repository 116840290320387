.comment,
.comment-body {
  overflow: hidden;
  zoom: 1;
}

.comment,
.comment .comment {
  padding: 20px 10px 25px 10px;
  border-top: 1px solid @table-border-color;

  &:first-child {
    margin-top: 0;
  }
}

.comment-body-content {
  font-size: 14px;

  & > p:last-child {
    margin-bottom: 0;
  }
}

// For images and videos, set to block
.comment-object,
.comment-actions {
  display: block;
}

.comment-actions li {
  padding-right: 0;
}

// Reset margins on headings for tighter default spacing
.comment-heading {
  margin: 0 0 5px;

  strong,
  .text-muted {
    margin-right: .25em;
  }

  .label {
    position: relative;
    top: -1px;
    margin-right: 5px;
  }
}

.comment-shared,
.comment-dueDate {
  margin-left: 5px;
}

.comment-shared {
  .fa {
    display: inline;
  }
}

.comment-body-content {
  p {
    display: inline-block;
  }
}

.comment-body-attachments {
  margin-top: 9px;
  padding: 0;
  list-style-type: none;

  li {
    margin-bottom: 5px;
  }

  li:last-child {
    margin-bottom: 0;
  }
}

.comment_fresh {
  .theme("default", {
    background: @themeBrand-tabs-tab-yellow-bgcolor;
  });
  .theme("black", {
    background: @themeBlack-tabs-tab-yellow-bgcolor;
  });
}

.comment-body-edit {
  margin-top: 10px;
}

// Media image alignment
// -------------------------

.comment,
.comment-addCommentForm {
  .pull-left {
    margin-right: 10px;
  }
  .pull-right {
    margin-left: 10px;
  }
}

.comment_editing > .comment-actions {
  display: none;
}


// Undo default ul/ol styles
.comment-list {
  padding-left: 0;
  list-style: none;
}

.comment_task {
  .comment_task-label {
    position: relative;
    display: inline-block;
    padding-left: 22px;

    .comment_task-label-checkbox {
      position: absolute;
      left: 1px;
      top: 3px;
      display: inline-block;
      width: 13px;
      height: 13px;
      border: 1px solid lighten(@input-border-focus, 15%);
      box-sizing: border-box;
      background-color: @input-bg;
      box-shadow: 0 0 1px transparent; // fixing safari subpixel width rendering
      border-radius: 0;
    }

    .comment_task-label-checked {
      position: absolute;
      left: -1px;
      top: 3px;
    }

    .label {
      position: relative;
      top: -2px;
      font-size: 10px;
    }
  }

  .comment-body-content {
    margin-top: 10px;

    p {
      font-size: @fontSize-base + 7px;
      line-height: 24px;

      .text-muted {
        text-decoration: line-through;
      }
    }
  }

  &.comment_flowTask {

    .comment-actions {
      display: none;
    }

    .comment_task-label {
      padding-left: 0;
    }
  }

  &:not(.comment_flowTask) {
    .comment_task-label {
       a {
        color: @color-base;
        text-decoration: none;
      }
    }
  }

  .mvscSpinner {
    position: relative;
    bottom: -3px;
    margin-right: 7px;
  }

  .checkbox-inline {
    min-height: 20px;
  }

}

.comment_ticket {
  padding-top: 30px;

  .comment-body-content {
    padding: 7px 0 2px 0;
    font-size: @fontSize-base + 8;
  }
}

.comment-addCommentForm {
  padding: 20px 10px 25px 10px;
  display: flex;

  & > *:nth-child(2) {
    flex: 1;
  }
}

.comment-addCommentToggle {
  margin-bottom: 18px;
}
