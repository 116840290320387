.dropzone {
  position: relative;
  overflow: hidden;
  padding: 14px 15px 10px 15px;
  border: 1px dashed @input-border;

  .text-muted {
    a {
      .helper-link();
    }
  }

  .dropzone-uploadLink {
    display: inline-block;

    span {
      .helper-link();
    }

    .fa {
      position: relative;
      top: -1px;
      margin-right: .25em;
      vertical-align: middle;
      font-size: @fontSize-base + 9;

      opacity: .5;
      transition: opacity .5s;
    }
  }

  .dropzone-restrictionMessage {
    display: block;
    margin-top: 6px;
  }

  input[type="file"] {
    .inputTypeFile();

    &:focus + span > a {
      border-radius: 2px;
      .theme("default", {
        box-shadow: 0 0 0 2px #21BAE7;
      });
    }
  }

  &:hover {
    .dropzone-uploadLink {
      .fa {
        opacity: 1;
        transition: none;
      }
    }
  }

  &.active {
    .theme("default", {
      border-color: @themeBrand-color-corp-skyBlue;
      background-color: @themeBrand-tabs-tab-hover-bgcolor;
    });
    .theme("black", {
      border-color: @themeBlack-color-corp-skyBlue;
      background-color: @themeBlack-tabs-tab-hover-bgcolor;
    });
    .theme("classic", {
      border-color: @themeClassic-color-corp-skyBlue;
    });

    .fa {
      opacity: 1;
      .theme("default", {
        color: @themeBrand-color-corp-skyBlue;
      });
      .theme("black", {
        color: @themeBlack-color-corp-skyBlue;
      });
      .theme("classic", {
        color: @themeClassic-color-corp-skyBlue;
      });
    }
  }

  &_big {

    padding: 35px 35px 45px 35px;
    border-radius: 5px;
    border: 2px dashed @input-border;

    .dropzone-uploadLink {
      font-size: 18px;
      margin-bottom: 4px;

      display: block;
      .fa {
        margin: 30px 0 0 0;
        display: block;
        font-size: 50px;
      }
    }

    input[type="file"] {
      left: 0;
      bottom: 0;
      transform: none;
      width: 100%;
    }

  }

}
