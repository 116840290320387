.generateRS {
  border: 1px solid @well-border;
  background-color: #fff;
  
  border-radius: @border-radius-base;
  
  & > .row {
    margin: 0;
    width: 100%;
    
    display: table;
    table-layout: fixed;
    border-collapse: collapse;
    border-spacing: 0;
    
    &:before,
    &:after {
      display: none;
    }
    
    & > [class^="col-xs-"],
    & > [class*=" col-xs-"] {
      display: table-cell;
      float: none;
      padding: 0;
      
      vertical-align: top;
    } 
  }
  
  .generateRS-previewCol {
    padding: 26px 10px 26px 22px !important;
    
    h4 {
      margin-top: 0;
      margin-bottom: 1.2em;
      font-size: @fontSize-base + 5;
    }
  }
  
  .generateRS-previewList {
    margin-bottom: 1.5em;
    
    li {
      
      div.text-muted {
        word-break: normal;
        margin-top: 4px;
      }
    }
  }
  
  .generateRS-reportCol {
    border-left: 1px solid @well-border;
    background-color: @well-bg;
  }
  
  .gallery {
    position: relative;
    
    margin-bottom: 0;
    padding-top: 30px;
    padding-bottom: 30px;
    
    border: none;
    background: none;
  }
  
  .generateRS-watermark {
    position: absolute;
    top: 250px;
  }
  
  .generateRS-watermark .fa {
    line-height: 1.1;
    opacity: .4;
  }
  
  .generateRS-watermark_ready .fa {
    font-size: 25em;
  }
  
  .generateRS-watermark_copy,
  .generateRS-watermark_voided {
    top: 450px;
  }
  
  .generateRS-watermark_copy .fa,
  .generateRS-watermark_voided .fa {
    font-size: 35em;
    transform: rotate(-45deg);
  }

  .generateRS-actions {
    line-height: 2.85em;
    .btn {
      margin-left: 0;
      margin-bottom: .5em;
      
      &:not(:last-child) {
        margin-right: .5em;
      }
    }
  }
  
  .generateRS-additionalForms {
    margin-top: 48px;
    
    h4 {
      margin-bottom: 13px;
    }
    
    .mvscSpinner {
      margin-top: -5px;
      position: relative;
      bottom: -4px;
    }
    
    .generateRS-additionalForms-checkbox {
      margin-top: 0;
      margin-bottom: 8px;
      transition: margin-top ease-in-out .15s;
      
      label {
        position: relative;
        display: inline-block;
        margin-bottom: 0;
        margin-right: -5px;
        margin-left: -5px;
        padding: 0 5px 0 27px;
        background: @white;
        font-size: @fontSize-base;
        z-index: 1;
        
        &:before, &:after {
          margin-left: 5px;
        }
        
        .ellipsis {
          display: inline-block;
          margin-right: 6px;
          width: auto;
          max-width: 140px;
        }
      }
      
      .pseudoLink {
        display: inline-block;
        margin-right: 6px;
      }
    }
  }
   
  .generateRS-additionalForms-actions {
    margin-top: 18px;
  }

}

.generateRS-descWell {
  margin-bottom: 35px;
}