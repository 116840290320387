.fake-forms {

  .fake-forms__fieldset {
    margin-bottom: @efileForm-fieldset-margin-bottom - 15;
  }

  .fake-forms__fieldset__header:before, .fake-forms__label, .fake-forms__input, .fake-forms__radio {
    background-color: @color-fake-forms-default;
    border-radius: @border-radius-base;
  }

  hr {
    margin-top: 0;
    margin-bottom: 30px;
    border-color: @color-fake-forms-default;
  }

  .fake-forms__fieldset__header {
    position: relative;
    height: 27px;
    border-bottom: 1px solid @color-fake-forms-default;
    margin-bottom: @efileForm-fieldset-header-margin-bottom + 5;

    &:before {
      content: '';
      display: block;
      height: 10px;
      width: 150px;
    }
  }

  .fake-forms__label {
    display: inline-block;
    vertical-align: top;
    height: 10px;
    width: 60px;
    margin-bottom: 10px;
    margin-right: 15px;

    &_32 {
      width: 32px;
    }

    &_120 {
      width: 120px;
    }

    &_200 {
      width: 200px;
      height: 13px;
    }
  }

  .fake-forms__radio {
    display: inline-block;
    height: 16px;
    width: 16px;
    margin: 5px 6px 20px 0;
    position: relative;
    top: -3px;
    border-radius: 50%;

    & + .fake-forms__label {
      margin-top: 5px;
    }

    &_22 {
      top: -5px;
      width: 22px;
      height: 22px;
      margin-bottom: 10px;
    }
  }

  .fake-forms__input {
    height: 32px;
    margin-bottom: 20px;

    &_34 {
      width: 34px;
      float: left;
      &:first-child {
        margin-right: 8px;
      }
    }
  }


  &.well.well-sm.well_noBg {
    padding-bottom: 0;
    border-color: @color-fake-forms-darken;

    .fake-forms__label_32 {
      margin-bottom: 15px;
    }
    .fake-forms__label_120 {
      margin-bottom: 25px;
    }
  }
  
  
  &_importing {
    .fake-forms__fieldset__header:before, .fake-forms__label, .fake-forms__input, .fake-forms__radio {
      .animation(f-and-i-loading 1s infinite);
    }
  }
  
  .well_noBg {
    border: 1px solid @color-fake-forms-border;
  }
  
  
}