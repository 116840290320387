.well {
    box-shadow: none;

    &.well-sm {
        padding: 17px;
    }

    dl {
        margin-bottom: 0;

        & + dl {
            margin-top: @line-height-computed;
        }
    }

    .dl-horizontal {
        margin-bottom: -7px;
    }

    &_noBg {
        background: none;
    }

    &_danger {
        background: #F2DEDE;
        border-color: #EACCD1;
        color: #AB4B4B;
    }

    .readMore {
        p {
            margin-bottom: 2px;
        }
    }

    &_rounded {
        border-radius: 5px;
    }
}


.well-lien {
    border-radius: 2px;
    border: 1px solid #D4D9DD;
    display: flex;
    margin-bottom: 20px;

    &:last-of-type {
        margin-bottom: 37px;
    }
}

.well-lien-aside {
    background-color: #f9f9f9;
    border-right: 1px solid #D4D9DD;
    flex: 0;
    padding: 20px;
    position: relative;

    &__subtract {
        position: absolute;
        top: 4px;
        left: 4px;
        height: 15px;
        width: 15px;

        &-first {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNC4yNTQgMy40MTQ1QzE1LjUxNDMgMi4xNTQ2OSAxNC42MjIxIDAgMTIuODQwMSAwSDJDMC44OTU0MzEgMCAwIDAuODk1NDMxIDAgMlYxMi44MzU3QzAgMTQuNjE3MyAyLjE1Mzg4IDE1LjUwOTcgMy40MTM5MyAxNC4yNTAyTDE0LjI1NCAzLjQxNDVaIiBmaWxsPSIjMkE5NjQ3Ii8+Cjwvc3ZnPgo=");
        }

        &-second {
            background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTUiIGhlaWdodD0iMTUiIHZpZXdCb3g9IjAgMCAxNSAxNSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xNC40MTAyIDMuNDE0NTFDMTUuNjcwNSAyLjE1NDY5IDE0Ljc3ODMgMCAxMi45OTYzIDBIMkMwLjg5NTQzMSAwIDAgMC44OTU0MzIgMCAyVjEyLjk5MThDMCAxNC43NzM0IDIuMTUzODggMTUuNjY1OSAzLjQxMzkzIDE0LjQwNjNMMTQuNDEwMiAzLjQxNDUxWiIgZmlsbD0iI0YxNzAzQyIvPgo8L3N2Zz4K");
        }
    }

    h4 {
        color: #000;
        font-size: 14px;
        font-weight: 500;
        margin: 0;
        text-transform: uppercase;
    }

    .removeButton {
        color: #888888;
        position: absolute;
        left: 20px;
        bottom: 20px;
        margin: 0;
    }
}

.well-lien-content {
    flex: 1;
    padding: 26px 30px 15px 30px;
    position: relative;

    .clear-lien {
        position: absolute;
        top: 30px;
        right: 30px;
    }

    .col-xs-1_5 {
        min-width: 145px;
    }

    .col-xs-3 {
        min-width: 290px;
    }

    .col-xs-full {
        max-width: 40%;
        width: auto;
    }

    .row sub-fieldset {
        display: flex;
        flex-wrap: wrap;

        .col-xs-4 {
            flex: 1;
        }
    }

    &--autogrid {

        [class^='col-xs-'] {
            min-width: 0;
        }
    }
}

.lien-save-action {
    display: flex;
    align-items: baseline;
}

.save-status {
    display: flex;
    align-items: baseline;
    gap: 5px;
}
