.form-control.ng-select {
    padding: 0;
    border: none;

    // MULTIPLE OPTIONS
    &.ui-select-multiple {
        height: auto;

        .ng-value-container {
            .ng-value {
                padding: 2px 2px 3px;
            }

            .ng-value-label {
                padding-right: 1px;
            }
        }

        .ng-select-container {
            height: auto;
            align-items: stretch;

            .ng-clear-wrapper {
                margin-top: 5px;
            }

            .ng-arrow-wrapper {
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }

    .ng-select-container {
        height: 32px;
        min-height: 32px;
        border-radius: 2px;
        border-color: #d8d8d8;
        position: relative;

        &:after {
            content: '';
            width: 1px;
            background: #d8d8d8;
            position: absolute;
            right: 17px;
            top: 0;
            bottom: 0;
        }

        .ng-clear-wrapper {
            .ng-clear {
                font-size: 0;

                &:after {
                    font-family: "Font Awesome 5 Pro";
                    font-size: 14px;
                    content: '\f057';
                    color: #999;
                    opacity: .3;
                    position: relative;
                    top: 1px;
                    font-weight: 900;
                }
            }

            &:hover .ng-clear:after {
                opacity: 1;
            }
        }

        .ng-arrow-wrapper {
            width: 17px;
            padding-right: 0;

            .ng-arrow {
                border-color: #888 transparent transparent;
                border-style: solid;
                border-width: 5px 3px 2px;
            }
        }

    }

    .ng-dropdown-panel {
        border-top: none;
        border-color: #d8d8d8;

        .ng-dropdown-panel-items {

            .ng-option {
                &.ng-option-marked {
                    background: @gray-lighter;
                }

                &.ng-option-selected {
                    color: @dropdown-link-active-color;

                    .theme("default", {
                        background-color: @themeBrand-dropdown-link-active-bg;
                    });

                    .theme("black", {
                        background-color: @themeBlack-dropdown-link-active-bg;
                    });

                    .theme("classic", {
                        background-color: @themeClassic-dropdown-link-active-bg;
                    });
                }
            }
        }

        // MULTIPLE
        &.ng-select-multiple .ng-dropdown-panel-items {

            .ng-option {
                font-size: 14px;
                line-height: 16px;
                color: #444;
                position: relative;
                padding: 10px 10px 10px 32px;

                &.ng-option-disabled {
                    padding-left: 10px;
                }

                &:not(.ng-option-disabled)::before {
                    content: "";
                    position: absolute;
                    top: 11px;
                    left: 10px;
                    width: 14px;
                    height: 14px;
                    background: url('@{assets-path}img/icon-checkbox.svg') 0 0 no-repeat;
                }

                &.ng-option-marked {
                    background: #f2fbff;
                }

                &.ng-option-selected {
                    color: #444;

                    &::before {
                        background-position: 0 -14px;
                    }

                    .theme("default", {
                        background-color: #f2fbff;
                    });

                    .theme("black", {
                        background-color: #f2fbff;
                    });

                    .theme("classic", {
                        background-color: #f2fbff;
                    });
                }
            }
        }
    }

    .ng-clear-wrapper:hover .ng-clear {
        color: #333;
    }

    .ng-dropdown-header {
        padding-top: 2px;

        input {
            .form-control;
            background: #fff~"url('@{assets-path}img/select2/select2.png')" no-repeat 100% -22px;
            padding: 2px 20px 2px 7px;
            height: 30px;

        }
    }

    &-opened {

        .ng-select-container:after {
            content: none;
        }

        >.ng-select-container .ng-arrow-wrapper .ng-arrow {
            top: -3px;
            border-color: transparent transparent #888;
            border-width: 0 3px 5px;
        }
    }


    &.ng-select-unwrap-options {
        .ng-dropdown-panel {
            .ng-dropdown-panel-items {
                .ng-option {
                    white-space: normal;
                }
            }
        }
    }
}

ngb-typeahead-window {
    button {
        background: none;
        border: none;
        text-align: left;
        display: block;
        padding: 6px 13px 6px 13px;
        line-height: 18px;
        width: 100%;
        max-width: 600px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:hover,
        &.active {
            background: @color-status-info;
            color: #fff;
        }

        .ngb-highlight {
            font-weight: 500;
        }
    }
}

.has-error .ng-select-container {
    border-color: @state-danger-text !important;
    background-color: @state-danger-bg;

    &:after {
        background: @state-danger-text !important;
    }
}

.has-error .ng-select-focused .ng-select-container {

    .theme("default", {
        border-color: @state-danger-text;
        box-shadow: 0 0 0 3px lighten(@state-danger-text, 30%);
    });
}

.alert {
  &-success {
    .form-control.ng-select .ng-select-container,
    .form-control.ng-select .ng-dropdown-panel {
      border-color: @alert-control-border-success;
    }
  }
  &-warning {
    .form-control.ng-select .ng-select-container,
    .form-control.ng-select .ng-dropdown-panel {
      border-color: @alert-control-border-warning;
    }
  }
  &-info {
    .form-control.ng-select .ng-select-container,
    .form-control.ng-select .ng-dropdown-panel {
      border-color: @alert-control-border-info;
    }
  }
  &-danger {
    .form-control.ng-select .ng-select-container,
    .form-control.ng-select .ng-dropdown-panel {
      border-color: @alert-control-border-danger;
    }
  }
}

.customer-restricted {
    display: inline-block;
    white-space: normal;
    background: #fff;
    color: #666;
    padding: 6px 13px 6px 33px;
    margin: -6px -13px;
    position: relative;

    .fa {
        color: @color-status-danger;
        font-weight: 900;
        font-size: 13px;
        line-height: 13px;
        position: absolute;
        left: 13px;
        top: 8px;
    }
}

.ng-value-container {
    input {
        margin-left: -2px;
    }
}
