.efilePreviewEnvelope {

    .mvscModal-body {
        margin-top: 0;
        padding-top: 0;

        .container {
            height: 100%;
            padding-left: 0;
            padding-right: 0;
            border-left: 0;
            border-right: 0;
            background: none;
        }
    }

    .mvscModal-body-scroll .container:not(.container_sent) {
        min-width: 1040px;
        text-align: center;

        &:before {
            display: inline-block;
            height: 100%;
            vertical-align: middle;
            content: '';
        }

        .envelope {
            display: inline-block;
            vertical-align: middle;
            width: 1032px;

            *:not(.text-right) {
                text-align: left;
            }
        }

        .ng-select {

            .ng-clear-wrapper {
                margin-right: 4px;
            }

            .ng-arrow-wrapper {
                text-align: center !important;
            }
        }
    }

    .container.container_sent {
        max-width: none;
        min-height: 800px;
        background-color: @white;
    }

    .possibleForm-alert {
        font-size: 14px;
        margin-top: -15px;

        h4 {
            font-size: inherit;
            font-weight: 500;
            margin-top: 0;
            margin-bottom: 9px;
            text-transform: uppercase;
        }

        p {
            margin: 0 0 9px !important;
        }
    }
}

.efilePreviewEnvelope_renewalSent {

    .envelope {
        padding-bottom: 351px;

        &:after {
            content: none;
        }
    }

    .container:before {
        height: auto !important;
    }

    .envelope-envelope {
        display: none;
    }

    .envelope-address {
        display: block !important;
        z-index: 1000;
    }

    .envelope-paper {
        padding-bottom: 50px;
        margin-bottom: 94px;
    }

    .envelope-stamp {
        display: none;
    }

    .envelope-envelope {
        display: block;
        width: 1081px;
        height: 351px;
        left: -24px;
        .retina-img('@{assets-path}img/envelope/layer_sent.png', '@{assets-path}img/envelope/layer_sent_2x.png', 1081px, 351px);
        background-position: left bottom;
    }

    .envelope-sentSuccessfully {
        margin: 15px 0 -10px 0;

        h1 {
            animation: none;
        }
    }

    .envelop-check {
        margin-left: -32px;
        margin-right: -28px;
        display: inline-block;
        vertical-align: middle;
        transform: scale(.5, .5);

        &:after {
            background: #f5f5f5;
        }
    }

    .envelop-check + h1 {
        display: inline-block;
        vertical-align: middle;
    }
}
