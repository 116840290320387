// userNotifications v2 styles
.userNotifications_v2 {
  width: 378px;
  margin-bottom: 0;
  padding: 44px 0 0 0;
  
  .scroller {
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(224, 227, 230);
      border-radius: 5px;
    }
  }
  
  &_compact {
    width: 315px;
  }
  
  .userNotifications_v2__header {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 43px;
    width: 378px;
    padding: 0 16px;
    
    border-bottom: 1px solid #e0e3e6;
    
    h4 {
      margin: 0;
      display: inline-block;
      font-size: @font-size-base;
      font-weight: 500;
      line-height: 43px;
      
    }
    
    a {
      color: #666;
      line-height: 43px;
    }
  }
  
  ul {
    margin-bottom: 0;
  }
  
  
  ul li {
  
    position: relative;
    display: block;
  
    a:not(.pseudoLink) {
      cursor: pointer;
      position: relative;
      left: 0;
      display: block;
      padding: 12px 40px 14px 65px !important;
      color: @color-base;
      transition: background 0.2s linear, left 0.2s ease-out; 
      
      &.disabled {
        left: -380px;
        transition: left 0.2s ease-in;
      }
    }
    
    a > span {
      .ellipsis;
    }
  
    a .fa {
      position: absolute;
      left: 16px;
      top: 14px;
      width: 33px !important;
      height: 33px;
      line-height: 33px;
      text-align: center;
      border-radius: 50%;
      text-align: center;
      font-size: @fontSize-base + 1;
      background: @color-status-available;
    
    
      &:before {
        color: #fff;
      }
      
      &.fa-info {
        background: @color-status-available;
      }
    
      &.fa-exclamation-triangle {
        background-color: @color-status-danger;
      }
      
      &.fa-dollar {
        background-color: @color-status-init;
      }
      
      &.fa-location-arrow {
        background-color: @color-status-info;
      }
      
      &.fa-envelope {
        background-color: @color-status-info;
      }
    }
    
    .pseudoLink.text-danger {
      padding: 0;
      color: @color-status-danger;
    
      &:hover {
        background: none;
      }
    }
    
    .pseudoLink {
      position: absolute;
      top: 24px;
      right: 14px;
    }
    
    .btn-fa {
      position: absolute;
      top: 16px;
      right: 6px;
      padding: 8px;
      width: auto;
    
      color: #ccc;
      &:hover {
        color: #999;
      }
    
      .fa {
        font-size: 14px;
      }
    }
    
    &:hover a:not(.pseudoLink) {
      color: inherit !important;
      background-color: @dropdown-link-hover-bg !important;
      text-decoration: none;
    }
  }

  li:not(:last-child) {
    border-bottom: 1px solid @dropdown-divider-bg;
  }
  
  small {
    margin-top: 1px;
    display: block;
  }
  
  .userNotifications_v2__empty-state {
    height: 320px;
    
    .fa.fa-bell {
      display: inline-block;
      margin-bottom: 25px;
      font-size: 72px;
      line-height: 1em;
      color: #d2d6db;
    }
  }
}

.userNotifications_v2_empty {
  padding: 0;
  
  li {
    padding: 32px 25px 25px 25px;
  }
  
  .fa {
    font-size: 24px;
    .text-muted() !important;
  }
  
  span {
    margin-top: 8px;
    display: block;
  }
}