.issue-speciality-plate {
  padding-bottom: 13px;
  
  &.fieldset.separated {
    padding-top: 33px;
  }
  
  .form-group {
    margin-bottom: 24px;
    label {
      display: block; 
    }
  }
  
  .issue-speciality-plate__check-button {
    height: 66px;
    width: 170px;
    padding-left: 0;
    padding-right: 0;
    text-align: center;
    position: relative;
    left: -8px;
    margin-right: -8px;
    z-index: 1;
  }
  
  .issue-speciality-plate__plate-label {
    font-weight: 500;
    text-transform: uppercase;
    color: darken(@color-muted, 10%); 
  }
  
  .alert {
    margin-bottom: 24px;
  }
}