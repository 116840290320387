/* Presets */

.presets {
  padding: 0;
  
  .presets-deleteBtn,
  .presets-undo {
    position: absolute;
    display: none;
  }
  
  .checkboxRadio {
    .ellipsis {
      padding-right: 23px;
    }
      
    .badge {
      height: auto;
      padding: 3px 4px;
      line-height: 7px;
    }
  }
  
  .badge, .presets-item-undo {
    display: none;
  }

  &.presets_edit {
  
    .presets-item {
      position: relative;
      margin-bottom: -1px; //as a checkboxRadio
      padding: 0 40px 0 6px;
      border-top: 3px solid transparent; //as a checkboxRadio
      border-bottom: 3px solid transparent;
       
      &.deleted {
      
        .checkboxRadio {
          display: none;
        }
      
        .presets-item-undo {
          position: absolute;
          right: 0;
          top: 0;
          display: inline-block;
        }
        
        .presets-item-deleteBtn {
          display: none;
        }
        
        .presets-item-label {
          text-decoration: line-through;
          color: @color-muted;
        }
      }
      
      .checkboxRadio {
        padding: 0;
        position: absolute;
        right: 25px;
        top: 0;
        
        &:hover {
          background: none;
        }
        
        label {
          width: 15px; // for click & hover space
          height: 15px;
          border: none;
          position: relative;
          cursor: pointer;
          padding: 0;
          
          &:before {
            position: absolute;
            top: 6px;
            right: 5px;
            left: auto;
            cursor: pointer;
            opacity: 1;
          }
          
          &:after {
            content: none;
          }
        }
        
       input:not(:checked) + label .badge {
          display: inline-block;
          background-color: darken(@input-border, 10%);
        }
      
        input:checked + label .badge {
          display: inline-block;
          background-color: @color-status-success;
        }
      }
    }
    
    .presets-item-deleteBtn {
      position: absolute;
      top: 2px;
      right: -1px;
    }
  }
    
  .presets-checkboxRadio_default {
    
    .badge {
      position: absolute;
      left: auto;
      right: 3px;
      top: 2px;
      display: inline-block;
      background-color: darken(@input-border, 10%);
    }
  }
}

