// ----- Footer

.footer {
  padding: 10px 20px 0 20px;
  line-height: 14px;
  // background: @color-corp-darkBlue;

  .theme("default", {
    background: @themeBrand-color-corp-darkBlue;
  });
  .theme("black", {
    background: @themeBlack-color-corp-darkBlue;
  });
  .theme("classic", {
    background: @themeClassic-color-corp-darkBlue;
  });

  .container {
    min-width: 0;
  }

  p {
    margin-bottom: 0;
    padding-top: 5px;
    opacity: .4;
    line-height: 14px;
  }

  &, & a, & a:hover {
    color: #fff;
  }

  .footer-phone {
    .fa {
      position: relative;
      bottom: -1px;
      margin-top: 0;
      margin-right: 2px;
      font-size: @font-size-base + 3;
    }
  }

  .footer-blogLink {
    margin-top: 13px;

    a {
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
