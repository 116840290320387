/*
  Set MVSC spinner class and child components
  Set X,Y centred position modifier
  Set sizes modifiers
  Set colors modifiers
  Set colors mixins for buttons context
*/

/* Set MVSC spinner class and child components */
.mvscSpinner {
  display: inline-block;
  text-align: center;
  &-spinner {
    display: inline-block;
    width: 150px;
    height: 150px;
    border-radius: 50%;
    border: solid 3px rgba(0, 0, 0, 0.05);
    .animation(spinnerRotate 1.5s infinite linear);
    .theme("default", {
      border-top-color: lighten(@themeBrand-color-corp-skyBlue, 9%);
    });
    .theme("black", {
      border-top-color: lighten(@themeBlack-color-corp-skyBlue, 9%);
    });
    .theme("classic", {
      border-top-color: lighten(@themeClassic-color-corp-skyBlue, 9%);
    });
  }
  &_sm {
    width: 16px;
    height: 16px;
    vertical-align: middle;
    .mvscSpinner-spinner {
      width: 16px;
      height: 16px;
      border-width: 2px;
      border-top-color: #02B3F2;
      border-left-color: #02B3F2;
    }
  }
}

.mvscSpinner-title {
  margin-top: 0;
  margin-bottom: 25px;
  font-size: 20px;
  font-weight: 300;

  .mvscSpinner-title-details {
    display: block;
    margin: 6px 0 -5px 0;
  }
}
/* end of Set MVSC spinner class and child components */

/* Set X,Y centred position modifier */
.mvscSpinner_aligned {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}
/* end of Set X,Y centred position modifier */


/* Set sizes modifiers */
.mvscSpinner {
  &_m,
  &_s,
  &_xs,
  &_xxs,
  &_xxxs {
    .mvscSpinner-spinner {
      border-width: 2px;
      .animation-duration(1.1s);
    }
  }

  &_m {
    .mvscSpinner-spinner {
      width: 80px;
      height: 80px;
    }
  }

  // used in System alerts
  &_s {
    .mvscSpinner-spinner {
      width: 18px;
      height: 18px;
    }
  }

  // used in Toothy show more component
  &_xs {
    .mvscSpinner-spinner {
      width: 15px;
      height: 15px;
    }
  }

  // used in default size buttons
  &_xxs {
    .mvscSpinner-spinner {
      width: 14px;
      height: 14px;
    }
  }

  // used in extra small buttons
  &_xxxs {
    .mvscSpinner-spinner {
      width: 13px;
      height: 13px;
    }
  }
}
/* end of Set sizes modifiers */


/* Set colors modifiers */
// darken both original colors on 10%
.mvscSpinner_darken {
  .mvscSpinner-spinner {
    border-color: rgba(0, 0, 0, 0.15);
    .theme("default", {
      border-top-color: darken( lighten(@themeBrand-color-corp-skyBlue, 9%), 10% );
    });
    .theme("black", {
      border-top-color: darken( lighten(@themeBlack-color-corp-skyBlue, 9%), 10% );
    });
    .theme("classic", {
      border-top-color: darken( lighten(@themeClassic-color-corp-skyBlue, 9%), 10% );
    });
  }
}

.mvscSpinner_desaturated {
  .mvscSpinner-spinner {
    border-color: rgba(255, 255, 255, 0.3);
    border-top-color: #fff;
  }
}
/* end of Set colors modifiers */

/* Set colors mixins for buttons context */
.btn-default,
.btn-white {
  .mvscSpinner {
    .mvscSpinner_darken();
  }
}

&.btn-success,
&.btn-primary,
&.btn-teal,
&.btn-info,
&.btn-warning {
  .mvscSpinner {
    .mvscSpinner_desaturated();
  }
}
/* end of Set colors mixins for buttons context */

.mvscSpinner-dim {
  position: fixed;
  min-height: 300px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255,255,255, .9);
  z-index: @zindex-navbar-fixed + 1;
  text-align: center;

  &_overModal {
    // position: fixed;
    background: @white;
    z-index: @zindex-modal-background + 1;
  }

  .mvscSpinner {
    position: fixed;
    top: 50vh;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.mvscSpinner-wrapper {
  height: 450px;
}
