.spotlight {

    .mvscModal-header {

        .input-group_search {
            margin: 0 123px 0 105px;
            padding: 15px 0;
            display: block;
            height: 100%;
        }

        .close {
            position: absolute;
            top: 17px;
            right: 85px;
            font-size: @fontSize-base + 24;
        }
    }

    .mvscModal-body {
        .mvscModal-body-scroll {
            & > .container {
                padding: 2px 115px 14px;
                background: transparent;
            }
        }
    }

    .emptyState {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);

        .emptyState-wrapper {
            padding: 0;
            color: @color-muted;
        }
    }

    .spotlight-results {

        .spotlight-results-item {
            position: relative;
            margin-bottom: 0;
            display: table;
            width: 100%;
            min-height: 71px;
            border-bottom: 1px solid @color-border_light;
            background: @white;
            border-radius: @border-radius-base;

            table-layout: fixed;

            > div {
                padding: 22px 15px;
                display: table-cell;
                float: none;
                vertical-align: middle;
                word-break: break-word;

                &:first-child {
                    padding-left: 37px;
                }
            }

            a {
                font-size: @fontSize-base + 2;

                &:focus {
                    .theme("default", {
                        color: @color-link;
                        border-radius: 2px;
                        box-shadow: 0 0 0 2px #21BAE7;
                        text-decoration: none;
                    });
                }
            }

            .fa {
                position: relative;
                width: 21px;
                left: -21px;
                margin-right: -21px;
                color: @pre-border-color;

                &.fa:not(.fa-automobile) {
                    font-size: @fontSize-base + 1;
                }
            }

            .fa-automobile {
                font-size: @fontSize-base;
            }

            .spotlight-results-item-id {
                display: inline-block;
                position: relative;
                width: 16px;
                left: -16px;
                margin-right: -16px;
                font-size: @fontSize-base + 2;
                color: @pre-border-color;
            }

            .fa-clock-o {
                width: 18px;
                left: -18px;
                margin-right: -18px;
            }

            .fa-user {
                width: 18px;
                left: -18px;
                margin-right: -18px;
            }

            &.spotlight-results-item_selected {
                .spotlight-results-item-title:after {
                    content: '\f061';
                    position: absolute;
                    top: 19px;
                    left: 16px;
                    font-size: @fontSize-base;
                    font-family: "Font Awesome 5 Pro";
                    .theme("default", {
                        color: @themeBrand-color-corp-skyBlue;
                    });
                    .theme("black", {
                        color: @themeBlack-color-corp-skyBlue;
                    });
                    .theme("classic", {
                        color: @themeClassic-color-corp-skyBlue;
                    });
                }
            }
        }

        .shotlight-results-item-highlighted {
            box-shadow: 0 0 0 2px #21BAE7;
            border-bottom-color: transparent;
            z-index: 5;
        }
    }
}
