// lookups
.lookups-nav {
    & > .row {
        text-align: center;
    }

    .form-inline {
        position: relative;
        display: inline-block;
        text-align: left;
    }

    .form-group:last-child {
        margin-right: 0;
    }

    .ng-select {

        .ng-clear-wrapper {
            margin-right: 4px;
        }

        &.bold-value .ng-value {
            font-weight: 700;
        }
    }

    .lookups-favorited {
        position: absolute;
        right: -23px;
        top: 8px;
    }

    .lookups-nav-favorites {
        margin: 8px 50px 0 50px;
        padding: 0;

        li {
            margin: 0 10px;
            display: inline;
            list-style-type: none;
        }
    }

    &.well {
        margin-bottom: 40px;
        line-height: 18px;
    }

    .select2-chosen {
        color: @text-color;
    }
}

.lookups-form {
    .agreement {
        margin-top: 30px;
    }
}

.lookups-lookupFileIco {
    width: 150px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 100px;
    padding-bottom: 100px;
    text-align: center;

    .lookups-lookupFileIco-ico {
        margin-right: 0;
        font-size: 45px;
    }

    .lookups-lookupFileIco-title {
        display: block;
        margin-top: 10px;
    }
}
