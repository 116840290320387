.onoff {
    display: inline-block;
    position: relative;
    width: 81px;

    .onoff-checkbox {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        left: -100px;
    }

    &.onoff_empty {
        width: 50px;

        .onoff-switch {
            right: 28px;
        }
    }

    label {
        display: block;
        margin-bottom: 0;
    }

    .onoff-label {
        background-color: @color-border;
        border: 1px solid transparent;
        border-radius: 12px;
        cursor: pointer;
        display: block;
        font-weight: 700;
        overflow: hidden;
        margin-bottom: 0;
        transition: background 0.3s ease-in 0s;
    }

    .onoff-inner {
        display: block;
        width: 200%;
        margin-left: -100%;
        transition: margin 0.3s ease-in 0s;

        .onoff-inner-on,
        .onoff-inner-off {
            color: @white;
            display: block;
            font-size: @fontSize-base - 2;
            float: left;
            height: 22px;
            line-height: 22px;
            padding: 1px 0 0 0;
            width: 50%;
        }

        .onoff-inner-on {
            padding-left: 15px;
            color: @white;
            text-align: left;
        }

        .onoff-inner-off {
            padding-left: 30px;
            text-align: left;
            color: lighten(@color-base, 40%);
        }
    }

    .onoff-switch {
        display: block;
        width: 20px;
        height: 20px;
        background: @white;
        border-radius: 50%;
        position: absolute;
        top: 2px;
        right: 59px;
        transition: all 0.3s ease-in 0s;

        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, .2);
    }

    .onoff-checkbox:checked + .onoff-label {
        background-color: @color-status-success;

        .onoff-inner {
            margin-left: 0;
        }

        .onoff-switch {
            right: 2px;
        }
    }

    .onoff-checkbox:disabled + .onoff-label {
        cursor: default;

        .onoff-switch {
            opacity: 0.5;
        }
    }

    .onoff-checkbox:checked:disabled + .onoff-label {
        opacity: 0.5;
    }

    .onoff-checkbox:focus + .onoff-label {
        box-shadow: 0 0 0 3px #BDEBF8;
        border-color: #21BAE7;
    }

    // For roles
    &.onoff_danger {

        .onoff-checkbox:checked+.onoff-label {
            background-color: @color-status-danger;
        }
    }

    // Only status mode
    .onoff-status {
        display: block;
        height: 24px;
        text-align: center;
        line-height: 23px;
        padding: 1px 0 0 0;
        color: @white;
        border-radius: 12px;

        .fa {
            position: relative;
            top: 1px;
            font-size: @fontSize-base + 1;
        }
    }

    &.onoff_active {
        .onoff-status {
            background-color: @color-status-success;
        }
    }

    &.onoff_pending {
        .onoff-status {
            background-color: @color-status-warning;
        }
    }
}

.switch-control {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    label[for] {
        color: #000;
        font-size: 18px;
        font-weight: 400;
        margin-left: 12px;
        text-transform: none;
    }
}
