.preferences.table {

  tr {
  
    td {
      vertical-align: middle;
      
      label {
        margin-bottom: 0;
        font-size: @fontSize-base + 1;
      }
      
      .schemePicker {
        margin-bottom: 0;
      }
    }
  }
}

.preferences mvsc-form-group .form-group {
  margin-bottom: 0;
}