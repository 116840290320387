.forecastContainer {
  @tabs-hovercolor: lighten(#000, 95.5%);
  @tabs-bordercolor: @input-border;

  position: relative;
  // in order to position .period's :before & :after elements properly
  z-index: 2;

  .forecastContainer-wrapper {
    height: 46px;
    overflow-x: hidden;
    white-space: nowrap;
    //margin-bottom: 12px;
  }

  .forecastContainer-control {
    display: none;
    position: absolute;
    z-index: 3;
    top: 8px;
    width: 25px;
    height: 32px;

    color: @pagination-color;

    cursor: pointer;
    line-height: 30px;
    text-align: center;


    border: 1px solid @btn-default-border;
    background-color: @pagination-bg;

    border-radius: @border-radius-base;

    i {
      margin-top: 0;
    }

    &.forecastContainer-control_prev {
      left: 0;

      i {
        margin-left: -1px;
      }
    }

    &.forecastContainer-control_next {
      right: 0;

      i {
        margin-right: -1px;
      }
    }

    &:hover {
      color: @pagination-hover-color;
      background-color: @pagination-hover-bg;
      border-color: @pagination-hover-border;
    }

    &.forecastContainer-control_disabled,
    &.forecastContainer-control_disabled:hover {
      color: @pagination-disabled-color;
      background-color: @pagination-disabled-bg;
      border-color: @pagination-disabled-border;
      cursor: not-allowed;
    }
  }

  .nav-pills_forecast {
    position: relative;
    white-space: nowrap;
    margin-top: 8px;

    li + li {
      margin-left: 9px;
    }

    li {
      float: none;
      display: inline-block;
      min-width: 115px;

      @badge-mr: 10px;
      @badge-mt: 32px;

      .badge {
        float: right;
        margin-right: -(@badge-mr + 3px);
        margin-top: -(@badge-mt + 3px);
      }

      a {
        padding: 6px 15px;
        text-align: center;
        border: 1px solid @table-border-color;
        background-color: @tabs-hovercolor;
        border-radius: @border-radius-base;
        color: @color-muted;
        .standard-transition(background-color);
        line-height: 18px;

        // need it to hide :before & :after elements for 'period' elements
        //z-index: 2;

        &:hover {
          background-color: @color-corp-lightGray;
        }

        span {
          font-weight: 500;
        }

        span:not(.subInfo) {
          font-weight: 700;
        }

        .subInfo {
          display: none;
        }

      }

      &.disabled {
        min-width: 55px;

        a {
          padding: 6px 8px;
          cursor: default;
          background-color: #fff;

          &:hover {
            background-color: #fff;
          }
        }

        span.text-muted {
          opacity: .5;
        }

        &.active {
          a {
            border-color: transparent;
            .theme("default", {
              border-color: transparent;
              background: lighten(@themeBrand-color-corp-skyBlue, 36%);
            });
            .theme("black", {
              border-color: transparent;
              background: lighten(@themeBlack-color-corp-skyBlue, 36%);
            });
            .theme("classic", {
              border-color: transparent;
              background: lighten(@themeClassic-color-corp-skyBlue, 36%);
            });
          }

          span.text-muted {
            opacity: 1;
          }
        }
      }

      &.today:not(.active) {
        a {
          border-color: @color-status-warning;
          background: #fff;

          &:hover {
            background: lighten(@color-status-warning, 33%);
          }
        }
      }

      &.overdue:not(.active) {
        a {
          border-color: @color-status-danger;
          background: #fff;

          &:hover {
            background: lighten(@color-status-danger, 47%);
          }
        }
      }

      &.active {
        a {
          color: #fff;
          .theme("default", {
            border-color: @themeBrand-color-corp-skyBlue;
            background: @themeBrand-color-corp-skyBlue;
          });
          .theme("black", {
            border-color: @themeBlack-color-corp-skyBlue;
            background: @themeBlack-color-corp-skyBlue;
          });
          .theme("classic", {
            border-color: @themeClassic-color-corp-skyBlue;
            background: @themeClassic-color-corp-skyBlue;
          });

          span {
            color: #fff;
          }
        }

        &:not(.active_plus) {
          a {
            cursor: default;
          }

          &:before,
          &:after {
            position: absolute;
            left: 50%;
            bottom: -5px;
            margin-left: -6px;
            display: inline-block;
            border-right: 6px solid transparent;
            border-top: 6px solid;
            border-left: 6px solid transparent;
            content: '';
            .theme("default", {
              border-top-color: @themeBrand-color-corp-skyBlue;
            });
            .theme("black", {
              border-top-color: @themeBlack-color-corp-skyBlue;
            });
            .theme("classic", {
              border-top-color: @themeClassic-color-corp-skyBlue;
            });
          }

          &:after {
            .theme("default", {
              border-top-color: @themeBrand-color-corp-skyBlue;
            });
            .theme("black", {
              border-top-color: @themeBlack-color-corp-skyBlue;
            });
            .theme("classic", {
              border-top-color: @themeClassic-color-corp-skyBlue;
            });
          }
        }

        &.active_plus {
          a {
            &:before,
            &:after {
              display: none;
            }
          }
        }

        &.active_plus:not(.disabled) {
          a:hover {
            .theme("default", {
              border-color: lighten(@themeBrand-color-corp-skyBlue, 3%);
              background: darken(@themeBrand-color-corp-skyBlue, 3.5%);
            });
            .theme("black", {
              border-color: lighten(@themeBlack-color-corp-skyBlue, 3%);
              background: darken(@themeBlack-color-corp-skyBlue, 3.5%);
            });
            .theme("classic", {
              border-color: lighten(@themeClassic-color-corp-skyBlue, 3%);
              background: darken(@themeClassic-color-corp-skyBlue, 3.5%);
            });
          }
        }

        &.disabled {
          a {
            &:hover {
              //background-color: @tabs-bgcolor_active;
            }
          }
          &:not(.active_plus) {
            opacity: 1;
          }
        }
      }

      &.period {
        &.active_plus {
          a:before {
            .theme("default", {
              border-color: fade(lighten(@themeBrand-color-corp-skyBlue, 36%), 60%);
            });
            .theme("black", {
              border-color: fade(lighten(@themeBlack-color-corp-skyBlue, 36%), 60%);
            });
            .theme("classic", {
              border-color: fade(lighten(@themeClassic-color-corp-skyBlue, 36%), 60%);
            });
          }

          a:after {
            .theme("default", {
              border-color: fade(lighten(@themeBrand-color-corp-skyBlue, 36%), 60%);
            });
            .theme("black", {
              border-color: fade(lighten(@themeBlack-color-corp-skyBlue, 36%), 60%);
            });
            .theme("classic", {
              border-color: fade(lighten(@themeClassic-color-corp-skyBlue, 36%), 60%);
            });
          }
        }

        a:after,
        a:before {
          display: block !important;
          content: '';

          position: absolute;
          left: 1px;
          right: 1px;
          top: -1px;
          bottom: -1px;
          z-index: -1;

          border: 1px solid fade(@table-border-color, 60%);
          border-radius: @border-radius-base;
          background-color: #fff;

          transform: translate(0, 2px)
        }

        a:after {
          left: 3px;
          right: 3px;
          z-index: -2;

          border-color: fade(@table-border-color, 40%);

          transform: translate(0, 4px)
        }
      }


    }

    &.nav-pills_forecast_draggable {
      /* autoprefixer: off */
      transition-property: transform, width;
      transition-timing-function: cubic-bezier(0.1, 0, .25, 1);
      transition-duration: 0ms;
    }

    &.nav-pills_forecast_draggable {
      cursor: move;
      cursor: -webkit-grab;
      cursor: -o-grab;
      cursor: -ms-grab;
      cursor: grab;
    }
  }

  &.forecastContainer_overflown {
    .forecastContainer-wrapper {
      margin-left: 30px;
      margin-right: 30px;
    }

    .forecastContainer-control {
      display: block;
    }
  }
}
