.serviceWindow {
  padding: 30px 0;
  background-color: @pre-bg;

  .checkbox label,
  p {
    font-size: 20px;
    line-height: 28px;
    color: #333333;
    text-align: left;
  }

  .checkbox {
    p {
      margin-top: 33px;
    }

    label {
      padding-left: 32px;

      &:before {
        transform: scale(1.25);
      }
    }

    .fa.fa-external-link-square-alt {
      font-size: 17px;
    }

    a {
      font-weight: 500;
      text-decoration: underline;

      &:hover {
        opacity: .75;
      }

      .fa {
        margin-left: 7px;
      }
    }

  }

  .form-actions {
    text-align: left;
    padding: 40px 0 60px 0;
    margin-top: 30px;

    .btn-wide {
      padding-left: 32px;
      padding-right: 32px;
    }
  }

  .interstate-agreement {
    padding-left: 77px;
    padding-right: 77px;

    h1.text-left {
      margin-bottom: 29px;
      margin-top: 55px;
    }
  }
}

.serviceWindow-brand {
  display: inline-block;
  margin-bottom: 40px;
  width: 108px;
  height: 47px;
  background: url('@{assets-path}img/svg/service-window-logo.svg');
}
