.mvsc-autoAbbr {
  position: relative;
  
  
  // we need this wrapper to let icon to be positioned correctly
  .mvsc-autoAbbr-inputContainer {
    position: relative;
  }
  
  .mvsc-autoAbbr-link {
    display: none;
    
    position: absolute;
    right: -3px;
    top: -6px;
    padding: 0 3px;
    line-height: 1.35;
    font-size: 10px;
    font-weight: 700;
    color: #fff;
    border-radius: @border-radius-base;
    z-index: 10;
    transition: background .25s;
    
    &:hover,
    &:focus {
      text-decoration: none;
    }

    span {
      color: #fff;
      background-color: #013140;
      font-size: 12px;
      font-weight: 400;
      position: absolute;
      top: -30px;
      left: 50%;
      transform: translateX(-50%);
      padding: 3px 8px;
      max-width: 200px;
      border-radius: 2px;
      opacity: 0;
      pointer-events: none;
      transition: opacity .1s;
      &:after {
        content: '';
        position: absolute;
        bottom: -5px;
        margin-left: -5px;
        left: 50%;
        height: 0;
        width: 0;
        border-style: solid;
        border-color: transparent;
        border-width: 5px 5px 0;
        border-top-color: #013140;
      }
      &.visible {
        opacity: .95;
      }
    }
  }
  
  .mvsc-autoAbbr-link_done {
    display: block;
    background-color: @color-status-success;
  }
  
  .mvsc-autoAbbr-link_able {
    display: block;
    background-color: @color-status-init;
  }
  
  .tooltip {
    white-space: nowrap;
  }
}