//
// Breadcrumbs
// --------------------------------------------------

// variables are here temporarily
@breadcrumb-btn-bg: #e1e4ea;
@breadcrumb-bg: #e1e4ea;

@hover-bg: darken(@breadcrumb-btn-bg, 5%);
@page-bg: #ffffff;

@breadcrumb-font-size: 12px;
@breadcrumb-line-height: @breadcrumb-font-size * 1.42857143;

@breadcrumb-color: #696b6c;

@breadcrumb-padding-vertical: 8px;
@breadcrumb-padding-horizontal: 20px;

@arrow-ratio: 1.5;
@arrow-size: 1px;
@arrow-height: @breadcrumb-padding-vertical * 2 + @breadcrumb-line-height;
@arrow-width: @arrow-height/2;


.breadcrumb {
  padding: 0px; //@breadcrumb-padding-vertical @breadcrumb-padding-horizontal;
  margin-bottom: @line-height-computed;
  list-style: none;
  background-color: @breadcrumb-bg;
  border-radius: @border-radius-base;
  overflow: hidden;
  line-height: @breadcrumb-line-height;
  font-size: @breadcrumb-font-size;
  position: relative;

  > li {
    display: inline-block;
    + li:before {
      content: ""; // Unicode space added since inline-block means non-collapsing white-space
      padding: 0;
      color: @breadcrumb-color;
    }

    a {
      color: @breadcrumb-color;
      text-decoration: none;
      display: block;
      position: relative;
      padding: @breadcrumb-padding-vertical (@arrow-width + @breadcrumb-padding-horizontal - (@arrow-width/@arrow-ratio));
      //padding: @breadcrumb-padding-vertical @breadcrumb-padding-horizontal - @arrow-width @breadcrumb-padding-vertical @breadcrumb-padding-horizontal;
      //background-color: @breadcrumb-btn-bg;
      &:hover{
        background-color: @hover-bg;
        color: @breadcrumb-color;
        &:after {
          border-left-color: @hover-bg;
          background-color:  @breadcrumb-btn-bg;
        }
        &:before {
          border-left-color: transparent;
          background-color:  @breadcrumb-btn-bg;
        }

      }
      &:after {
        display: block;
        border-top: @arrow-height solid transparent;
        border-bottom: @arrow-height solid transparent;
        border-left: @arrow-width solid @breadcrumb-btn-bg;
        z-index: 2;
        position: absolute;
        //left: 100%;
        right: 0;
        top: 50%;
        margin-right: 1px;
        margin-top: -@arrow-height;
        content: " ";
      }
      &:before {
        display: block;
        border-top: @arrow-height solid transparent;
        border-bottom: @arrow-height solid transparent;
        border-left: @arrow-width solid @page-bg;
        z-index: 1;
        position: absolute;
        //left: 100%;
        right: 0px;
        top: 50%;
        margin-top: -@arrow-height;
        margin-left: @arrow-size;
        content: " ";
      }
    }

    // hide arrow in last item
    &:last-child {
      // padding-left: @arrow-width + @breadcrumb-padding-horizontal - @arrow-width/@arrow-ratio;
      a{
        padding-right: @breadcrumb-padding-horizontal;
        &:before, &:after{
          border-width: 0;
        }
      }

    }
  }

  > .active {
    color: @breadcrumb-active-color;
  }

   & > li + li > a {
      // beautifully hide the part under previous one
      margin-left: (-@arrow-width + @breadcrumb-padding-horizontal - (@arrow-width/@arrow-ratio) - 4);
  }
}
