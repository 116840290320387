@assets-path: '/Scripts/assets/';
/* Variables ---------------- */
@margin-app: 15px;

@fontFamily-base: 'Roboto', sans-serif;
//@fontFamily-base: 'Open Sans', sans-serif;
@fontSize-base: 13px;

@color-base: #000;
@color-white: #fff;

@color-link: @color-corp-grayBlue;
@color-link_hover: darken(@color-corp-grayBlue, 15%);

@color-title: #444;
@color-muted: #999;
@color-muted_light: #808080;
@color-pale: #adb6c1;

@color-border_light: darken(@color-white, 10%);
@color-border_dark: darken(@color-white, 40%);
@color-border: darken(@color-white, 13%);

// statuses are independent and have same colors for every color scheme
// that is why we don't use @color-corp.. variables over here
@color-status-success: #2A9647;
@color-status-warning: #ffb848;
@color-status-danger: #D7262D;
@color-status-info: darken(#4FC8EC, 10%); //#21bae7
@color-status-available: #7fa1ba;
@color-status-voided: @color-border_dark; //#999
@color-status-sold: lighten(#013D4F,24%); //#039ac8
@color-status-init: lighten(#EF5F24,5%); //#f1703c
@color-status-pending: #26BBB3;
@color-status-failed: @color-status-danger; //#D7262D
@color-status-rdf: darken(#4FC8EC, 10%);
@color-status-cleared: @color-status-success;
@color-status-unwind: desaturate(#61C2B6, 20%);
@color-status-rdf: darken(#4FC8EC, 10%); //@color-corp-skyBlue
@color-status-billed: @state-danger-text;

// CORPORATE COLORS THEME
@themeBrand-color-corp-darkBlue: #026481;
@themeBlack-color-corp-darkBlue: desaturate(#004272, 100%);
@themeClassic-color-corp-darkBlue: #004272;

@themeBrand-color-corp-darkestBlue: darken(#013d4f, 3%);
@themeBlack-color-corp-darkestBlue: desaturate(#00243f, 100%);
@themeClassic-color-corp-darkestBlue: #00243f;

@themeBrand-color-corp-skyBlue: darken(#4FC8EC, 10%);
@themeBlack-color-corp-skyBlue: desaturate(#00adef, 100%);
@themeClassic-color-corp-skyBlue: #00adef;

@themeBrand-color-corp-skyBlue_active: lighten(@themeBrand-color-corp-darkestBlue, 10);
@themeBlack-color-corp-skyBlue_active: desaturate(#006297, 100%);
@themeClassic-color-corp-skyBlue_active: #006297;

@color-corp-grayBlue: #296189;

@themeBrand-color-corp-lightBlue: @themeBrand-color-corp-skyBlue;
@themeBlack-color-corp-lightBlue: @themeBlack-color-corp-skyBlue;
@themeClassic-color-corp-lightBlue: @themeClassic-color-corp-skyBlue;

@color-corp-lightGray: #ebedef;

// SOLUTION TABS THEME
@themeBrand-tabs-bgcolor: #e5f6ff;
@themeBlack-tabs-bgcolor: desaturate(#e5f6ff, 100%);

@themeBrand-tabs-borderColor: #c3e0f3;
@themeBlack-tabs-borderColor: desaturate(#c3e0f3, 100%);

@themeBrand-tabs-close-color: #8db5d0;
@themeBlack-tabs-close-color: desaturate(#8db5d0, 100%);

@themeBrand-tabs-tab-hover-bgcolor: #f2fbff;
@themeBlack-tabs-tab-hover-bgcolor: desaturate(#f2fbff, 100%);

@themeBrand-tabs-tab-error-color: #a94442;
@themeBlack-tabs-tab-error-color: desaturate(#a94442, 100%);

@themeBrand-tabs-tab-error-bgcolor: #f2dede;
@themeBlack-tabs-tab-error-bgcolor: desaturate(#f2dede, 100%);

@themeBrand-tabs-tab-error-hover-bgcolor: #f9efef;
@themeBlack-tabs-tab-error-hover-bgcolor: desaturate(#f9efef, 100%);

@themeBrand-tabs-tab-error-borderColor: #ebccd1;
@themeBlack-tabs-tab-error-borderColor: desaturate(#ebccd1, 100%);

@themeBrand-tabs-tab-yellow-bgcolor: #fcf8e3;
@themeBlack-tabs-tab-yellow-bgcolor: desaturate(#fcf8e3, 100%);

@themeBrand-tabs-tab-yellow-borderColor: #e0dfcf;
@themeBlack-tabs-tab-yellow-borderColor: desaturate(#e0dfcf, 100%);

//  Solution tabs
@tabs-tab_active-bgcolor: @color-white;
@tabs-tab_hover-color: @color-title;
@tabs-tab_active-color: @color-base;
@tabs-tab-yellow-color: #828178;
@tabs-tab-yellow-hover-bgcolor: #fdfdf9;
@tabs-tab-width: 160px;
@tabs-tab-homeWidth: 54px;
@tabs-tab-controlWidth: 24px;

// Header height
@headerHeight: 50px;
// Solution
@solutionNavHeight: 44px;
@solutionTabHeight: 42px;
// Footer
@footerHeight: 60px;

// Pastel palette
@color-pastel_blue: #bad4f5;
@color-pastel-border_blue: darken(@color-pastel_blue, 27%);

@color-pastel_green: #dbf0a1;
@color-pastel-border_green: darken(@color-pastel_green, 27%);

@color-pastel_violet: #f1d5fe;
@color-pastel-border_violet: darken(@color-pastel_violet, 27%);

@color-pastel_turquoise: #d5fef2;
@color-pastel-border_turquoise: darken(@color-pastel_turquoise, 27%);

@color-pastel_yellow: #fef9d5;
@color-pastel-border_yellow: darken(@color-pastel_yellow, 27%);

@color-pastel_orange: #fee3d5;
@color-pastel-border_orange: darken(@color-pastel_orange, 27%);

@color-pastel_red: #fec1c1;
@color-pastel-border_red: darken(@color-pastel_red, 27%);

// Fake forms colors
@color-fake-forms-default: #f8f8f8;
@color-fake-forms-darken: #f6f6f6;
@color-fake-forms-importing: #f3f3f3;
@color-fake-forms-border: #f3f4f5;
@color-fake-forms-bg: #fbfbfb;

// E-file form
@efileForm-fieldset-margin-bottom: 60px;
@efileForm-fieldset-header-margin-bottom: 15px;
@efileForm-fieldset-header-padding-bottom: 12px;

// Alerts
@alert-control-border-success: #8eb38a;
@alert-control-border-warning: #c3b28f;
@alert-control-border-info: #85afc3;
@alert-control-border-danger: #cd9190;

// MIXIN THEME
.theme(@mode, @rules) when (default()) {
  @rules();
  .theme_brand & {
    @rules();
  }
}

.theme(@mode, @rules) when (@mode = "black") {
  .theme_black & {
    @rules();
  }
}

.theme(@mode, @rules) when (@mode = "classic") {
  .theme_classic & {
    @rules();
  }
}
