.envelope {
    position: relative;
    padding-top: 40px;
    padding-bottom: 574px;

    &:after {
        position: absolute;
        z-index: 1;
        left: 0;
        right: 0;
        bottom: 521px;
        height: 86px;
        display: block;
        content: '';
        .retina-img('@{assets-path}img/envelope/layer01.png', '@{assets-path}img/envelope/layer01_2x.png', 1032px, 86px);
        background-repeat: no-repeat;
        background-position: center top;
    }

    h1 {
        margin: 0 0 5px 0;
        line-height: 36px;

        .badge {
            top: -11px;
        }
    }

    .envelope-sentSuccessfully,
    .envelope-address {
        display: none;
    }

    .checkbox {
        label {
            white-space: normal;
        }
    }

}

.envelop__selection {
    font-weight: 500;
    margin-bottom: 10px;
    text-transform: uppercase;

    .badge {
        text-transform: none;
        position: relative;
        top: -2px;
    }
}

.envelope-paper {
    position: relative;
    z-index: 2;
    width: 900px;
    margin: 0 auto -280px auto;
    padding: 35px 40px 280px 40px;
    min-height: 350px;
    background: #fff;
    box-shadow: 0px 0px 3px 0px rgba(0, 0, 0, 0.2);

    .form-group {
        .checkbox {
            label {
                &:after {
                    margin-top: -5px !important;
                }

                &:before {
                    margin-top: -8px !important;
                }
            }
        }
    }


    .alert {
        .checkbox {
            margin: 0;

            label {
                font-size: @fontSize-base;
                font-weight: 400;
                //white-space: pre-wrap;
                text-transform: none;
            }
        }
    }

    .alert-danger {
        .checkbox {

            label {
                color: #a94442;
                line-height: 1.6;
                text-transform: uppercase;
            }
        }
    }
}

.envelope-envelope {
    position: absolute;
    z-index: 3;
    left: 0;
    right: 0;
    bottom: 0;
    height: 570px;

    .retina-img('@{assets-path}img/envelope/layer02.png', '@{assets-path}img/envelope/layer02_2x.png', 1032px, 570px);
    background-repeat: no-repeat;
    background-position: center top;
}

.envelope_sent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 370px;
    height: 100%;
    min-height: 800px;

    .envelope-envelope {
        height: 351px;
        .retina-img('@{assets-path}img/envelope/layer_sent.png', '@{assets-path}img/envelope/layer_sent_2x.png', 1081px, 351px);
        background-position: center bottom;
        z-index: 0;
    }

    &:after {
        content: none;
    }

    .envelope-paper,
    .envelope-stamp {
        display: none;
    }

    .envelope-sentSuccessfully,
    .envelope-address {
        display: block;
    }
}

.envelope-sentSuccessfully {

    h1 {
        margin-top: 35px;
        margin-bottom: 40px;
        line-height: 1.4em;

        small {
            font-size: @font-size-base + 4;
        }
    }

    h1,
    .btn,
    .cancel {
        animation: envelop-text-fade 1.2s ease-in-out 1 forwards;
    }

    .fa-mvsc-check {
        font-size: 11.6em;
        line-height: 1;
    }

    .cancel:before {
        margin-right: 10px;
    }
}

.envelope-address {
    position: absolute;
    bottom: 169px;
    left: 50%;
    margin-left: -396px;
    font-family: 'Courier New';
    font-weight: bold;
    font-size: @fontSize-base + 5;
    color: rgba(0, 0, 0, .25);

    small {
        font-size: @fontSize-base + 5;
        text-transform: uppercase;
        letter-spacing: .1em;
    }
}


.envelope-stamp {
    position: absolute;
    z-index: 4;
    right: 292px;
    bottom: 326px;
    width: 192px;
    height: 172px;

    .retina-img('@{assets-path}img/envelope/stamp.png', '@{assets-path}img/envelope/stamp_2x.png', 192px, 172px);
    background-repeat: no-repeat;
    background-position: center top;
}


.envelope {
    .fieldset {
        margin-bottom: 35px;

        &.separated {
            border-bottom-color: #eaeaea;
        }
    }

    .form-group {
        margin-bottom: 32px;

        p {
            margin: 5px 0 20px 0;
            line-height: 23px;

            .badge {
                position: relative;
                top: -3px;
            }
        }
    }

    label {
        display: block;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 14px;
    }

    .entity-value {
        margin-top: 1px;
        font-size: 18px;
        padding: 3px 0 2px 0;
        line-height: 26px;

        .table,
        p {
            margin: -3px 0 -2px 0;
        }

        p+p {
            margin-top: 22px;
        }

        .table {
            td {
                position: relative;
                padding-top: 4px;
                padding-bottom: 4px;
                vertical-align: bottom;
                border: none;
                line-height: 25px;

                span,
                strong {
                    position: relative;
                    background: @color-white;
                    z-index: 1;
                }

                &:after {
                    content: '';
                    height: 1px;
                    position: absolute;
                    right: 0;
                    left: 0;
                    bottom: .6em;
                    border-bottom: 1px dotted #d8d8d8;
                }
            }

            tfoot {
                .text-biggest {
                    font-size: @fontSize-base + 20;
                    line-height: 1.2em;
                }
            }
        }

        .pseudoLink {
            line-height: 1.4em;
        }

        .fa {
            font-size: 16px;
        }

        .btn-xs {
            position: relative;
            top: -2px;
        }

        &.text-normal {
            padding-top: 4px;
            line-height: 1.4em;
            font-size: @fontSize-base + 1;

            .fa {
                font-size: 13px;
            }

        }
    }

    p.entity-value.text-normal {
        padding: 0;
        margin: 19px 0 15px 0;
        line-height: 1.45em;
    }

    .form-actions {
        margin-top: 40px;
    }

    &-signature-header {
        margin-bottom: 32px !important;
        .form-group {
            margin-bottom: 0 !important;
        }
    }

    &-signature {
        border-top: 1px solid #eaeaea;
        padding-top: 35px;
        padding-bottom: 19px;

        .radio-inline {
            margin-right: 10px;

            .checkbox input+label,
            .checkbox input~label {
                font-size: 15px;
                line-height: 20px;
            }
        }

        .form-group {
            &.initial {
                margin-bottom: 15px !important;
            }

            label {
                font-weight: normal;
                color: @color-base;
                text-transform: none;
                &:not(.switch-text) {
                    font-size: 15px;
                    line-height: 20px;
                }
            }

            &-wrap {
                display: flex;
                align-items: center;

                &-content {
                    margin-left: 20px;
                }
            }

            p {
                margin: 5px 0 20px 0;
                line-height: 1.3em;
                line-height: 23px;

                .badge {
                    position: relative;
                    top: -3px;
                }
            }
        }

        &-group {
            .form-group {
                margin-bottom: 19px !important;
            }
        }
    }

}

.envelop-check {
    position: relative;
    display: inline-block;
    overflow: hidden;
    width: 156px;
    height: 156px;
    border-radius: 50%;
    animation: envelop-check-background .4s ease-in-out 1 forwards;

    .envelop-check-circle {
        height: 150px;
        width: 150px;
        position: absolute;
        top: 3px;
        left: 3px;
        background: @white;
        border-radius: 50%;
        animation: envelop-check-circle 1s ease-in-out 1 forwards;
    }

    &:after {
        content: '';
        display: block;
        height: 150px;
        width: 150px;
        position: absolute;
        top: 3px;
        left: 3px;
        background: @white;
        border-radius: 50%;
        box-shadow: 75px -75px 0 2px @white, -75px -75px 0 2px @white, -75px 75px 0 2px @white, 75px 75px 0 2px @white, 0 0 0 2px @white;
        animation: envelop-check-border .4s ease-in-out 1 forwards;
    }

    .envelop-check-icon {
        position: absolute;
        top: 63px;
        left: 50px;
        width: 9px;
        height: 0; //47
        background: @white;
        transform: rotate(-45deg);
        animation: envelop-check-line1 1s ease-in-out 1 forwards;

        &:after {
            position: absolute;
            bottom: 0;
            left: 0;
            content: '';
            display: block;
            width: 0; //75
            height: 9px;
            background: @white;
            transform: rotate(180deg);
            animation: envelop-check-line2 1.2s ease-in-out 1 forwards;
        }
    }
}
