/* Solution ---------------- */
.solution {
    @solutionNav-hoverBg: #1790c9;
    @solutionNav-hoverBg: rgba(0,0,0,.17);

    .solution-solutionNav {
        position: relative;
        margin: 0;
        padding: 7px 8px 7px 8px;
        color: @color-white;
        .clearfix();

        .theme("default", {
            background-color: @themeBrand-color-corp-skyBlue;
        });
        .theme("black", {
            background-color: @themeBlack-color-corp-skyBlue;
        });
        .theme("classic", {
            background-color: @themeClassic-color-corp-skyBlue;
        });

        > li {
            float: left;
            margin-right: 8px;
            list-style-type: none;
            border-radius: @border-radius-base;

            &.solution-solutionNav-active {

                .theme("default", {
                    background-color: @themeBrand-color-corp-skyBlue_active;
                });
                .theme("black", {
                    background-color: @themeBlack-color-corp-skyBlue_active;
                });
                .theme("classic", {
                    background-color: @themeClassic-color-corp-skyBlue_active;
                });

                > a {
                    cursor: default;
                    text-decoration: none;
                }
            }

            &.open,
            &:not(.solution-solutionNav-active):hover {
                background-color: @solutionNav-hoverBg;
            }

            >  a {
                display: block;
                padding: 5px 12px;
                color: @color-white;
                text-decoration: none;
                line-height: 18px;
                font-weight: 500;

                .theme("default", {
                    .text-shadow(0 0 1px, darken(@themeBrand-color-corp-skyBlue, 8%)) !important;
                });
                .theme("black", {
                    .text-shadow(0 0 1px, darken(@themeBlack-color-corp-skyBlue, 8%)) !important;
                });
                .theme("classic", {
                    .text-shadow(0 0 1px, darken(@themeClassic-color-corp-skyBlue, 8%)) !important;
                });
            }
        }

        li.solution__help {
            position: absolute;
            right: 2px;
            top: 7px;

            &:hover {
                background: none;
            }

            font-size: 12px;

            .solution__help__phone {
                margin-right: 8px;
                font-weight: 400;
                strong {
                    font-weight: 500;
                }

                .fa {
                    font-size: 15px;
                    margin-right: 4px;
                    position: relative;
                    bottom: -1px;
                }
            }

            .solution__help__chat {
                display: inline-block;
                height: 28px;
                line-height: 28px;
                border-radius: 3px;
                background: rgba(16, 113, 142, .3);
                padding: 0 10px 0 12px;

                &:hover {
                    background: rgba(11, 76, 96, .3);
                }
            }
        }

        .fa {
            width: auto;
        }

        .dropdown-menu {
            .border-top-radius(0);
        }
    }

    .solution-solutionNav-ico {
        margin-right: 2px !important;
        margin-left: 2px;

        & > a {
            padding: 6px 6px 4px 6px !important;
        }

        & > a > .fa {
            margin-right: 0;
            font-size: @fontSize-base + 5;

            &.fa-mvsc-autoefile {
                font-size: @fontSize-base + 2;
            }
        }
    }

    .solution-solutionNav-ico_inactive {

        .fa {
            .theme("default", {
                color: lighten(@themeBrand-color-corp-skyBlue, 30%);
            });
            .theme("black", {
                color: lighten(@themeBlack-color-corp-skyBlue, 30%);
            });
            .theme("classic", {
                color: lighten(@themeClassic-color-corp-skyBlue, 30%);
            });
        }
    }

    .solution-tabs {
        position: relative;
        padding-left: 54px;
        border-bottom: 1px solid;

        .theme("default", {
            background-color: @themeBrand-tabs-bgcolor;
            border-bottom-color: @themeBrand-tabs-borderColor;
        });
        .theme("black", {
            background-color: @themeBlack-tabs-bgcolor;
            border-bottom-color: @themeBlack-tabs-borderColor;
        });

        > * {
            margin-bottom: -1px;
        }

        .solution-tabs-wrapper {
            overflow: hidden;
        }

        ul {
            position: relative;
            margin: 0;
            padding: 0;
            .clearfix();
        }

        .solution-tabs-tab {
            position: relative;
            float: left;
            width: @tabs-tab-width;
            list-style-type: none;
            z-index: 1;

            &:not(.solution-tabs-active) a:hover {
                transition: none;

                .theme("default", {
                    background: @themeBrand-tabs-tab-hover-bgcolor;
                });
                .theme("black", {
                    background: @themeBlack-tabs-tab-hover-bgcolor;
                });
            }

            a {
                display: block;
                height: @solutionTabHeight;
                padding: 12px 25px 0 19px;
                border: 1px solid;
                border-right: none;
                border-top: none;
                text-decoration: none;

                .theme("default", {
                    @tabs-bgcolor: @themeBrand-color-corp-darkBlue;
                    color: @tabs-bgcolor;
                    background-color: @themeBrand-tabs-bgcolor;
                    border-color: @themeBrand-tabs-borderColor;
                });
                .theme("black", {
                    color: @themeBlack-color-corp-darkBlue;
                    background-color: @themeBlack-tabs-bgcolor;
                    border-color: @themeBlack-tabs-borderColor;
                });
                .theme("classic", {
                    color: @themeClassic-color-corp-darkBlue;
                });
                .standard-transition(background-color);

                .solution-tabs-overflow {
                    .text-overflow(100%);
                }
            }

            &.solution-tabs-active {
                a {
                    cursor: default;
                    color: @tabs-tab_active-color;
                    border-bottom: 1px solid @tabs-tab_active-bgcolor;
                    background: @tabs-tab_active-bgcolor;
                }

                .close:hover {
                    background: none;
                }
            }

            &.solution-tabs-tab_home {
                margin-left: -@tabs-tab-homeWidth;
                width: @tabs-tab-homeWidth;
                min-width: 0;

                a {
                    text-align: center;
                    padding-left: 0;
                    padding-right: 0;
                    border-right: 1px solid;
                    border-left: 0;
                    margin-right: -1px;

                    .theme("default", {
                        border-right-color: @themeBrand-tabs-borderColor;
                    });
                    .theme("black", {
                        border-right-color: @themeBlack-tabs-borderColor;
                    });
                }
                a, i {
                    width: auto;
                }

                i {
                    margin-right: 0;
                    font-size: 1.2em;
                }
            }

            &.solution-tabs-tab_inProgress {
                a span {
                    opacity: .15;
                }
            }

            &:last-child {
                a {
                    border-right: 1px solid;

                    .theme("default", {
                        border-right-color: @themeBrand-tabs-borderColor;
                    });
                    .theme("black", {
                        border-right-color: @themeBlack-tabs-borderColor;
                    });
                }
            }

            &.solution-tabs-tab_error {
                a {
                    .theme("default", {
                        color: @themeBrand-tabs-tab-error-color;
                        background: @themeBrand-tabs-tab-error-bgcolor;
                        border-color: @themeBrand-tabs-tab-error-borderColor;
                    });
                    .theme("black", {
                        color: @themeBlack-tabs-tab-error-color;
                        background: @themeBlack-tabs-tab-error-bgcolor;
                        border-color: @themeBlack-tabs-tab-error-borderColor;
                    });

                    &:hover {
                        .theme("default", {
                            background: @themeBrand-tabs-tab-error-hover-bgcolor;
                        });
                        .theme("black", {
                            background: @themeBlack-tabs-tab-error-hover-bgcolor;
                        });
                    }
                }
                .close {
                    .theme("default", {
                        color: @themeBrand-tabs-tab-error-color;
                    });
                    .theme("black", {
                        color: @themeBlack-tabs-tab-error-color;
                    });

                    &:hover {
                        .theme("default", {
                            background: @themeBrand-tabs-tab-error-borderColor;
                        });
                        .theme("black", {
                            background: @themeBlack-tabs-tab-error-borderColor;
                        });
                    }
                }
            }

            &.solution-tabs-tab_control {
                display: none;
                position: absolute;
                z-index: 2;
                min-width: 0;
                width: @tabs-tab-controlWidth;

                a {
                    padding-left: 3px;
                    padding-right: 0;
                    text-align: center;
                }

                &.solution-tabs-tab_control_prev {
                    left: @tabs-tab-homeWidth;

                    a {
                        border-right: 1px solid;
                        .theme("default", {
                            border-right-color: @themeBrand-tabs-borderColor;
                        });
                        .theme("black", {
                            border-right-color: @themeBlack-tabs-borderColor;
                        });
                    }
                }

                &.solution-tabs-tab_control_next {
                    right: 0;

                    a {
                        border-right: none;
                    }
                }

                &.solution-tabs-tab_control_disabled {
                    a {
                        color: lighten(@color-base, 75%);
                        cursor: default;
                    }
                }
            }
        }

        li, a {
            font-weight: 500;
            .theme("default", {
                @tabs-bgcolor: @themeBrand-color-corp-darkBlue;
                color: @tabs-bgcolor;
            });
            .theme("black", {
                color: @themeBlack-color-corp-darkBlue;
            });
            .theme("classic", {
                color: @themeClassic-color-corp-darkBlue;
            });
        }

        .close {
            padding: 0 3px;
            position: absolute;
            top: 0;
            right: 0;
            font-size: 14px;
            font-weight: 300;
            .text-shadow (none);
            opacity: 1;
            .theme("default", {
                color: @themeBrand-tabs-close-color;
            });
            .theme("black", {
                color: @themeBlack-tabs-close-color;
            });

            &:hover {
                .theme("default", {
                    background: @themeBrand-tabs-borderColor;
                });
                .theme("black", {
                    background: @themeBlack-tabs-borderColor;
                });
            }
        }

        .mvscSpinner {
            position: absolute;
            left: 50%;
            top: 50%;
            margin-top: -9px;
            margin-left: -8px;
        }

        &.solution-tabs_overflown {
            padding-left: @tabs-tab-homeWidth + @tabs-tab-controlWidth - 1;
            padding-right: @tabs-tab-controlWidth;

            .solution-tabs-tab_home {
                margin-left: -(@tabs-tab-homeWidth + @tabs-tab-controlWidth - 1);
            }

            .solution-tabs-tab_control {
                display: block;
            }
        }

        &.solution-tabs_yellow {
            border-bottom: 1px solid;

            .theme("default", {
                background: @themeBrand-tabs-tab-yellow-bgcolor;
                border-bottom-color: @themeBrand-tabs-tab-yellow-borderColor;
            });
            .theme("black", {
                background: @themeBlack-tabs-tab-yellow-bgcolor;
                border-bottom-color: @themeBlack-tabs-tab-yellow-borderColor;
            });

            .solution-tabs-tab {
                .theme("default", {
                    border-color: @themeBrand-tabs-tab-yellow-borderColor;
                });
                .theme("black", {
                    border-color: @themeBlack-tabs-tab-yellow-borderColor;
                });

                a {
                    color: @tabs-tab-yellow-color;

                    .theme("default", {
                        background: @themeBrand-tabs-tab-yellow-bgcolor;
                        border-color: @themeBrand-tabs-tab-yellow-borderColor;
                    });
                    .theme("black", {
                        background: @themeBlack-tabs-tab-yellow-bgcolor;
                        border-color: @themeBlack-tabs-tab-yellow-borderColor;
                    });

                    &:hover {
                        background: @tabs-tab-yellow-hover-bgcolor;
                    }
                }

                .close {
                    color: @tabs-tab-yellow-color;

                    &:hover{
                        .theme("default", {
                            background-color: @themeBrand-tabs-tab-yellow-borderColor;
                        });
                        .theme("black", {
                            background-color: @themeBlack-tabs-tab-yellow-borderColor;
                        });
                    }
                }

                .faa-spin {
                    color: @tabs-tab-yellow-color !important;
                }
            }
            .solution-tabs-active {
                a {
                    cursor: default;
                    color: @tabs-tab_active-color;
                    border-bottom: 1px solid @tabs-tab_active-bgcolor;
                    background: @tabs-tab_active-bgcolor;
                }
                .close:hover {
                    background: none;
                }
            }
        }
    }

    .container {
        padding-top: 20px;
        padding-bottom: 20px;
    }
}
