.upload{
  padding: 10px 20px;
  border: 1px solid @input-border;
  
  & + & {
    margin-top: -1px;
  }

  .upload-filename {
    min-width: 200px;
    margin-right: 20px;
    display: inline-block;
  }

  .upload-status {
    display: inline;
  }

  .upload-progress {
    
  }

  .upload-action {
    float: right;
    
    .fa:not(.fa-check) {
      cursor: pointer;
    }
    
    .fa:not(.text-success) {
      color: lighten(@color-base, 40%);
    }
    
    .fa:hover {
      color: lighten(@color-base, 30%);
    }
  }
  
  .upload__file-icon {
    margin-top: 25px;
    margin-bottom: 18px;
    
    .fa {
      font-size: 77px;
    }
    
    .fa:not(.fa-warning):not(.fa-mvsc-iconFailed) {
      opacity: .5;
    }
  }
  
  .upload__actions {
    margin-top: 15px;
  }
  
  &_big {
    margin-bottom: 50px;
    padding: 50px 20px 37px 20px;
    font-size: 18px;
    
    .upload-progress {
      margin: 15px 0 20px 0;
    }
    
    .envelop-check {
      transform: scale(.5);
      margin: -70px 0 -44px 0;
    }
  }
  
  &_rounded {
    border-radius: 2px;
  }
  
  
  /* mini upload control */
  &_mini {
    position: relative;
    border: 0;
    
    .progress {
      height: 9px;
    }
    
    .upload-progress {
      margin: 0;
      margin-right: 18px;
    }
    
    .upload-action {
      float: none;
      position: absolute;
      right: 15px;
      top: 4px;
      margin: 0;
      
      .fa-fw {
        width: auto;
      }
    }
  }
}