.inventory-return {
  .table {
    margin-bottom: 46px;
  }

  fieldset .table {
    margin-bottom: 15px;
  }

  .form-actions {
    margin-top: 34px;
  }

  .inventory-return__tr {
    td {

      > * {
        vertical-align: middle;
      }

      .pseudoLink {
        margin-right: 4px;
      }

      a, .label {
        margin-right: 3px;
      }

      i.fa {
        margin-right: 2px;
        margin-left: 3px;
      }
    }
  }
}

//Modal lives in inventory logbook
.modal.inventory-order {
  .modal-dialog {
    width: 900px;
  }
  .modal-header {
    padding: 0;
    min-height: 0;
  }
  .modal-body {
    padding: 10px 50px 50px 50px;
    max-height: 85vh;
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(224, 227, 230);
      border-radius: 5px;
    }
  }

  &--xs {
    .modal-dialog {
      width: 650px;
    }
  }

  .well.well-sm {
    margin-top: 15px;
    padding: 14px 17px;
    line-height: 1.55;

    .form-group {
      margin-bottom: 14px;
    }

    .row:last-child {
      .form-group {
        margin-bottom: 0;
      }
    }

    hr {
      margin: 0 0 14px 0;
      border-color: @table-border-color;
    }

    label, small {
      color: @color-muted_light;
    }

    label {
      margin-bottom: 0;
      font-size: 11px;
    }
  }

  .table {

    &:last-child {
      margin-bottom: 0;
    }
  }

  .table + .form-actions {
    margin-top: 40px;
  }
}
