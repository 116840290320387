.token {
  @label-link-hover-color: @link-hover-color;
  .label;
  line-height: 15px;
  background-color: @color-corp-lightGray;
  color: darken(@input-color, 5%);
  font-size: 12px !important;
  font-weight: normal;
  float: left;
  margin-bottom: 5px;
  margin-right: 4px;
  &[href] {
    color: @link-color;
    &:hover,
    &:focus {
      color: @label-link-hover-color;
      text-decoration: underline;
    }
  }
}