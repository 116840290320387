@import "../@{thirdparty-path}bower_components/fontawesome-pro/less/_variables.less";

// Mix-ins
.borderRadius(@radius) {
    border-radius: @radius;
}

.boxShadow(@boxShadow) {
    box-shadow: @boxShadow;
}

.opacity(@opacity) {
    @opacityPercent: @opacity * 100;
    opacity: @opacity;
    -ms-filter: ~"progid:DXImageTransform.Microsoft.Alpha(Opacity=@{opacityPercent})";
    filter: ~"alpha(opacity=@{opacityPercent})";
}

.wordWrap(@wordWrap: break-word) {
    word-wrap: @wordWrap;
}

// Variables
@black: #000000;
@grey: #999999;
@light-grey: #CCCCCC;
@white: #FFFFFF;
@near-black: #030303;
@green: @state-success-bg;
@red: @state-danger-bg;
@blue: @state-info-bg;
@orange: @state-warning-bg;

.toast {
    padding-left: 50px !important;
}


.toast-top-full-width {
    top: 0;
    right: 0;
    width: 100%;
}

.toast-bottom-full-width {
    bottom: 0;
    right: 0;
    width: 100%;
}

.toast-top-left {
    top: 12px;
    left: 12px;
}

.toast-top-right {
    top: 12px;
    right: 12px;
}

.toast-bottom-right {
    right: 12px;
    bottom: 12px;
}

.toast-bottom-left {
    bottom: 12px;
    left: 12px;
}

.mvscModal-open,
.modal-open {
    .toast-top-right {
        top: 72px;
    }
}

#toast-container {
    position: fixed;
    z-index: @zindex-toastr;

    &:not(.no-enter) > div.ng-enter,
    &:not(.no-leave) > div.ng-leave {
        transition: 300ms cubic-bezier(0.250, 0.250, 0.750, 0.750) all;
    }

    &:not(.no-enter) > div.ng-enter.ng-enter-active,
    &:not(.no-leave) > div.ng-leave {
        opacity: 0.8;
    }

    &:not(.no-leave) > div.ng-leave.ng-leave-active,
    &:not(.no-enter) > div.ng-enter {
        opacity: 0;
    }

    * {
        box-sizing: border-box;
    }

    /*overrides*/
    &.toast-top-full-width > div,
    &.toast-bottom-full-width > div {
        width: 100%;
        margin: auto;
    }
}

.toast {
    @padding: 15px;
    @iconsize: 20px;
    @percentage: 20%;

    position: relative;
    padding: @padding @padding + 15px @padding @padding + @iconsize + 5px; //override .alert
    min-height: 1px;
    color: #333;
    width: 300px;
    margin: 0 0 12px;
    @shadow: 0 0 12px rgba(0, 0, 0, .2),  0 0 1px rgba(0, 0, 0, .3);
    .boxShadow(@shadow);
    opacity: .97;
    line-height: 18px;

    // Improve alignment and spacing of inner content
    > p,
    > ul {
        margin-bottom: 0;
    }
    > p + p {
        margin-top: 5px;
    }

    .close {
        position: absolute;
        top: 0;
        right: (@padding - 8px);
        font-size: 26px;
        font-weight: 300;
        color: inherit;
    }

    &:before {
        width: @iconsize;
        height: @iconsize;
        position: absolute;
        top: @padding - 1px;
        left: @padding;
        font: @iconsize/@iconsize 'Font Awesome 5 Pro';
        font-weight: 900;
    }

    &.toast-success {
        background-color: @alert-success-bg;
        color: @alert-success-text;
        .toast-title {
            color: @alert-success-text;
            .fa {
                background-color: @alert-success-bg;
            }
        }
        &:before {
            content: '\f00c';
            color: @alert-success-text;
            font-weight: 900;
        }
    }

    &.toast-error {
        background-color: @alert-danger-bg;
        color: @alert-danger-text;
        .toast-title {
            color: @alert-danger-text;
            .fa {
                background-color: @alert-danger-bg;
            }
        }
        &:before {
            content: '\f071';
            color: @alert-danger-text;
            font-weight: 900;
        }
    }

    &.toast-info {
        background-color: @alert-info-bg; //@color-corp-lightGray;
        color: @alert-info-text;
        .toast-title {
            color: @alert-info-text;
            .fa {
                background-color: @alert-info-bg; //@color-corp-lightGray;
            }
        }
        &:before {
            content: '\f05a';
            color: @alert-info-text;
            font-weight: 900;
        }
    }

    &.toast-warning {
        background-color: @alert-warning-bg;
        color: @alert-warning-text;
        .toast-title {
            color: @alert-warning-text;
            .fa {
                background-color: @alert-warning-bg;
            }
        }
        &:before {
            content: '\f071';
            color: @alert-warning-text;
            font-weight: 900;
        }
    }

    // Styles

    .toast-title,
    .toast-message {
        .wordWrap();
    }

    .toast-title {
        font-weight: 900;
        font-size: @font-size-base;
        // use the icon from the title
        .fa {
            width: @iconsize;
            height: @iconsize;
            position: absolute;
            top: @padding - 1px;
            left: @padding - 2px;
            font-size: @iconsize;
            line-height: @iconsize;
            font-weight: 900;
        }
    }

    .toast-message {
        font-size: @font-size-small;
        line-height: 17px;
    }

    .toast-traces {
        display: inline-block;
        margin: 5px 0 0;

        a {
            color: #A94442;
            font-weight: 700;
            text-decoration: underline;

            &:focus {
                color: inherit;
            }
        }
    }
}

/*Responsive Design*/

@media all and (max-width: 240px) {
    #toast-container {
        > div {
            padding: 8px 8px 8px 50px;
            width: 11em;
        }

        & .toast-close-button {
            right: -0.2em;
            top: -0.2em;
        }
    }
}

@media all and (min-width: 241px) and (max-width: 480px) {
    #toast-container {
        > div {
            padding: 8px 8px 8px 50px;
            width: 18em;
        }

        & .toast-close-button {
            right: -0.2em;
            top: -0.2em;
        }
    }
}

@media all and (min-width: 481px) and (max-width: 768px) {
    #toast-container {
        > div {
            padding: 15px 15px 15px 50px;
            width: 25em;
        }
    }
}
