@wide-padding: 30px;

.btn-wide {
  padding-left: @wide-padding;
  padding-right: @wide-padding;
  line-height: 18px;
}

.btn-wide {
  .mvscSpinner.mvscSpinner_xxs {
    top: 2px;
  }
}

// Specificity overrides
input[type="submit"],
input[type="reset"],
input[type="button"] {
  &.btn-wide {
    padding-left: @wide-padding;
    padding-right: @wide-padding;
  }
}

.btn-link {
  font-weight: 400 !important;

  a& {
    text-decoration: none;
    .btn-link_Nohref {
      text-decoration: none;
    }
  }
  .btn-link_Nohref {
    color: @color-base;
  }
}

.cancel {
  text-decoration: none;
  position: relative;
  margin-left: 15px;

  &:not(.cancel_noOr):before {
    content: 'or ';
    color: @text-muted;
    cursor: default;
    vertical-align: middle;
  }

  &.cancel-xs {
    margin-left: 6px;
  }

  &.cancel-xs:before {
    vertical-align: baseline;
  }

  > .btn-link {
    padding-left: 0;
    padding-right: 0;
    padding: 0;

    &:focus {

      .theme("default", {
        box-shadow: 0 0 0 2px #21BAE7;
      });
    }
  }
}

.btn.switchButton,
.btn.removeButton {

  &:focus {
    .theme("default", {
      box-shadow: 0 0 0 2px #21BAE7;
    });
  }
}

.btn-xs + .cancel {
  margin-left: 5px;
}

.btn {
  font-weight: 500;
  line-height: 18px;

  &:focus,
  &.active,
  &:active {
    outline: none !important; // Important for IE
    box-shadow: none;
  }

  &:focus {
    .theme("default", {
      box-shadow: 0 0 0 3px #BDEBF8;
    });
  }

  .fa {
    margin-top: 0;
    margin-right: .25em;

    &:only-child {
      margin-right: 0;
    }

    &:only-child.fa-mvsc-plus {
      position: relative;
      top: 1px;
      margin-left: -2px !important;
      margin-right: -2px !important;
    }
  }

  span + .fa {
    margin-left: .75em;
  }

  .mvscSpinner {
    position: relative;
    top: 0;
    margin-right: 2px;

    &_xs {
      top: 3px;
      height: 15px;
      width: 15px;
    }
  }

  .badge {
    top: 0;
    margin: 0 -5px 0 9px;
    padding-left: 9px;
    padding-right: 9px;
    background: fade(darken(@color-pale, 10%), 35%);
    color: @btn-default-color;

  }

  &-white {
    .badge {
      background: fade(darken(@color-pale, 10%), 25%);
      color: #666;
    }

    &:disabled {
      color: #aaa;
    }
  }

  &-primary {
    .badge {
      background: fade(@black, 50%);
      color: @white;
    }
  }

  &.daterangepicker-button {
    &:hover {
      text-decoration: none;
    }
  }

  &--warning {
    background-color: #8a6d3b;
    color: #fcf8e3;

    &:hover {
      background-color: darken(#8a6d3b, 5%);
      color: #fcf8e3;
    }
  }

}

.btnSideLabel {
  padding-left: 7px;
  color: #333;
}

.btn-xs {
  font-weight: 400;
  vertical-align: baseline;

  .mvscSpinner {
    top: 2px;
    margin-right: 5px;
  }

  &_form-control {
    margin-top: 6px;
  }
}

.btn-lg {
  height: 45px;
}

.btn-stroked {
  .btn-link();
  border: 1px dotted darken(@input-border, 10%) !important;
  transition: border-color ease-in-out .15s;

  .fa {
    color: darken(@input-border, 10%);
    transition: color ease-in-out .15s;
  }

  &:hover {
    border-color: @input-border-focus !important;

    .fa {
      color: @color-status-success;
    }
  }
}

.btn-pseudo {
  text-decoration: none !important;
  -webkit-font-smoothing: antialiased;

  &:hover {
    .pseudoLink {
      &:after {
        border-bottom: none;
      }
    }
  }
}

.col-xs-12 {
  .btn-pseudo {
    padding-left: 0;
    padding-right: 0;
  }
}

// space between buttons and btn-groups
.btn + .btn,
.btn + .btn-group,
.btn + span[data-toggle="popover"],
span[data-toggle="popover"] + span[data-toggle="popover"],
.btn + mvsc-action-dropdown,
.btn-group + .btn,
.btn-group + .btn-group,
.btn-group + mvsc-action-dropdown,
mvsc-action-dropdown + .btn,
mvsc-action-dropdown + .btn-group,
mvsc-action-dropdown + mvsc-action-dropdown {
  margin-left: .5em;
}

.btn-group > .btn + .dropdown-toggle {
  padding-left: @padding-base-horizontal;
  padding-right: @padding-base-horizontal;
}

.span-first-dropdown {
  position: relative;
  float: left;
  z-index: 2;
  .btn {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
}

.wrap-span-btn {
  display: block;
}

.btn-group {
  &>.btn:first-child {
    border-radius: 2px;
  }
  .btn-add + .btn-import {
    margin-left: .5em;
  }
}

.btn-group>.dropdown-toggle {
  border-bottom-left-radius: 0;
  border-top-left-radius: 0;
  margin-left: -1px;
}

document-upload-section,
mvsc-fleet-renewal-documents-tab,
minnesota-add-documents-form,
documents-section,
required-forms-uploaded-section,
mvsc-retail-md-evr-submit-documents-documents-section,
mvsc-interstate-documents-tab,
documents-section {
  .btn-file {
    line-height: 18px;
    height: 32px;
    span {
      display: block;
    }
  }
}

.btn-file {
  display: inline-block;
  position: relative;
  overflow: hidden;
  margin-right: 4px;

  input {
    .inputTypeFile();
  }
}

.btn-fa {
  background: transparent;
  width: 14px;
  line-height: 14px;
  padding: 0;
  border: none !important;
  font-size: 14px;

  .fa {
    margin: 0;
  }

  &.text-danger:hover {
    .fa {
      color: darken(@btn-danger-bg, 8%);
    }
  }
}

.btn-arrow {
  @button-corner-square-size: 22px; // for default size buttons
  position: relative;

  &-overlay {
    position: relative;
    z-index: 2;
  }

  &:after {
    content:"";
    z-index: 1;
    position: absolute;
    top: 4px;
    width: @button-corner-square-size;
    height: @button-corner-square-size;
    background: inherit;
    border: inherit;
    border-left-color: transparent;
    border-bottom-color: transparent;
    border-radius: 0px @border-radius-base 0px 0px;
  }

  &.btn-arrow_2left {
    margin-left: @button-corner-square-size - 7px;
    padding-left: @padding-base-horizontal * .5;

    &:after {
      left: -12px;
      transform: rotate(225deg);
    }
  }

  &.btn-arrow_2right {
    margin-right: @button-corner-square-size - 7px;
    padding-right: @padding-base-horizontal * .5;

    &:after {
      right: -12px;
      transform: rotate(45deg);
    }
  }

  &.btn-wide {
    &.btn-arrow_2left {
      padding-left: @wide-padding - @padding-base-horizontal * .75;
      padding-right: @wide-padding - @padding-base-horizontal * .25;
    }

    &.btn-arrow_2right {
      padding-left: @wide-padding;
      padding-right: @wide-padding - @padding-base-horizontal * .5;
    }

    .fa-angle-left {
      position: relative;
      left: -20px;
    }

    .fa-angle-right {
      position: relative;
      right: -20px;
    }
  }
}

.btn-add {
  .btn-add-icon {
    position: relative;
    display: inline-block;

    margin-top: -2px;
    margin-right: 7px;
    margin-bottom: -5px;

    width: 20px;
    height: 20px;

    border-radius: 50%;
    border: 2px solid #fff;

    &:before,
    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      display: block;
      width: 8px;
      height: 2px;
      content: '';
      background: #fff;
    }

    &:before {
      margin-left: -4px;
      margin-top: -1px;
    }

    &:after {
      margin-left: -1px;
      margin-top: -4px;
      width: 2px;
      height: 8px;
    }

    &.btn-add-icon_isntrounded {
      border-color: transparent !important;
    }
  }

  &.btn-xs .btn-add-icon {
    width: 14px;
    height: 14px;
    margin-bottom: -2px;
    margin-right: 3px;

    &:before {
      width: 6px;
      margin-left: -3px;
    }

    &:after {
      height: 6px;
      margin-top: -3px;
    }

    &.btn-add-icon_isntrounded {
      margin-right: 2px;
      margin-left: -2px;

      &:before {
        width: 8px;
        margin-left: -4px;
      }

      &:after {
        height: 8px;
        margin-top: -4px;
      }
    }
  }

  &.btn-default {
    .btn-add-icon {
      border-color: @btn-default-color;

      &:before,
      &:after {
        background: @btn-default-color;
      }
    }
  }

  &.btn-white {
    .btn-add-icon {
      border-color: @btn-default-border;

      &:before,
      &:after {
        background: @btn-default-border;
      }
    }
  }
}

.btn-import {
  .fa.fa-upload {
    margin-right: 7px;
  }
}


// btn teal
// we use it only on login page
// todo: move it to bootstrap less?
.btn-teal {
  @btn-teal-color:              #fff;
  @btn-teal-bg:                 @color-status-pending;
  @btn-teal-border:             darken(@color-status-pending, 5%);

  .button-variant(@btn-teal-color; @btn-teal-bg; @btn-teal-border);
}
