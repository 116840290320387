// extend bootstrap with custom classes
@bs: '../@{bs-path}less/';

// original bootstrap variables
@import "variables.less"; // overrides bootstrap variables

//@icon-font-path: "@{bs}../fonts/";

@import "@{bs}mixins.less";

// Reset
@import "@{bs}normalize.less";
@import "@{bs}print.less";

// Core CSS
@import "@{bs}scaffolding.less";
@import "@{bs}type.less";
@import "@{bs}code.less";
@import "@{bs}grid.less";
@import "@{bs}tables.less";
@import "@{bs}forms.less";
@import "@{bs}buttons.less";

// Components
@import "@{bs}component-animations.less";
//@import "@{bs}glyphicons.less";
@import "@{bs}dropdowns.less";
@import "@{bs}button-groups.less";
@import "@{bs}input-groups.less";
@import "@{bs}navs.less";
@import "@{bs}navbar.less";
@import "@{bs}breadcrumbs.less";

@import "@{bs}pagination.less";
@import "@{bs}pager.less";
@import "@{bs}labels.less";
@import "@{bs}badges.less";

@import "@{bs}jumbotron.less"; // do not need this

@import "@{bs}thumbnails.less";
@import "@{bs}alerts.less";
@import "@{bs}progress-bars.less";
@import "@{bs}media.less";
@import "@{bs}list-group.less";
@import "@{bs}panels.less";
@import "@{bs}wells.less";
@import "@{bs}close.less";

// Components w/ JavaScript
@import "@{bs}modals.less";
@import "@{bs}tooltip.less";
@import "@{bs}popovers.less";
@import "@{bs}carousel.less";

// Utility classes
@import "@{bs}utilities.less";
@import "@{bs}responsive-utilities.less";

@import "bootstrap5.less";

.tooltip.show {
    opacity: .9;
}
