.f-and-i-desk {
  
  .fixed {
    position: fixed;
    top: 73px;
  }

  .efileForm h3 span.text-muted {
    text-transform: none;
    font-weight: 200;
  }

  .efileForm .form-actions {
    margin-bottom: 65px;
  }
  
  .well_noBg {
    background: none;
  }
  
  .f-and-i-desk__form {
    width: 62%;
  }
  
  .f-and-i-desk__actions-col {
    width: 13%;
  }
  
  .f-and-i-desk__import-form,
  .f-and-i-desk__forms {
    margin: 17px;
  }
  
  .f-and-i-desk__import-form {
    border-bottom: 1px solid @table-border-color;
  }
  
  .emptyState-wrapper {
    padding-top: 0;
  }
}

.f-and-i-desk__get-deal-preview {
  
  .form-actions {
    margin-bottom: 20px;
  }

  h4 {
    margin-top: 3px;
    margin-bottom: 7px;
    font-size: 14px;
    font-weight: 500;
  }

  .f-and-i-desk__get-deal-preview-wrapper > .well:last-child {
    margin-bottom: 0;
  }

  .well {
    padding: 0;

    &_nobg {
      padding: 24px 28px 28px 28px;
    }

    .f-and-i-desk__get-deal-preview__link-return {
      display: inline-block;
      margin: -6px 0 15px 0;

      .fa {
        margin-right: 6px;
        color: @color-muted;
      }
    }
  }

  .well_noBg {
    padding: 24px 28px 28px 28px;

    .badge.badge_inline {
      top: -2px;
    }

    label {
      font-size: 12px;
      font-weight: 500;
      text-transform: uppercase;
    }

    .entity-value {
      font-size: 14px;
    }

    .list-icons {
      padding-bottom: 10px;
      margin-bottom: 20px;
      border-bottom: 1px solid @table-border-color;

      li {
        margin-bottom: 17px;
        .word-break;
      }
    }
  }
}

.f-and-i-desk__forms {
  
  &.fake-forms {
    margin: -18px -1px -1px -1px;
    padding: 17px;
    border-left: 1px solid @color-fake-forms-border;
    border-right: 1px solid @color-fake-forms-border;
    border-bottom: 1px solid @color-fake-forms-border;
    border-top: 1px solid @table-border-color;
    
    background: @color-fake-forms-bg;

    .fake-forms__label, .fake-forms__input, .fake-forms__radio {
      background-color: @color-fake-forms-darken;
    }

    .fake-forms__radio {
      margin-top: 0;
      margin-bottom: 0;

      & + .fake-forms__label {
        margin-top: 0;
      }
    }

    .col-xs-12 .fake-forms__label_120 {
      margin-top: 15px;
    }
  }
}

.f-and-i-desk__indicia,
.f-and-i-desk__transactions {
 display: none;
  margin: 6px 0 0 0;

  .list-icons {
    margin-bottom: 20px;

    .fa:not(.fa-check-circle) {
      font-size: @font-size-base;
      color: darken(@input-border, 10%);
    }
  }

  h4 {
    font-size: 13px;
    font-weight: 700;
  }

  ul li {
    font-size: 12px;
  }
  
  &.fake-forms {
    
    .fake-forms__radio {
      margin-bottom: 0;
    }
    
    .fake-forms__label {
      &.fake-forms__label_120 {
        width: 100px;
      }
    }
  }
}

.f-and-i-desk__processing {
  display: none;
  
  .close {
    display: none;
    position: absolute;
    top: -4px;
    right: 6px;
    line-height: 1;
    font-size: 35px;
  }
  
  h4 {
    margin: 0;
    font-size: 14px;
    font-weight: 700;
  }
  
  .f-and-i-desk__processing__actions {
    .btn {
      margin-bottom: 0;
    }
  }
  
  .table {
    
    &.table_vertCentered {
      td {
        padding: 9px 0;
        
        .checkbox:not(.form-group) {
          margin-bottom: -2px;
        }

        label {
          max-width: 210px;
        }

        .mvscSpinner {
          position: relative;
          right: -12px;
          bottom: -5px;
          margin-bottom: -5px;
          margin-top: -5px;
        }
      }
    }
    
    &.table_marginTop {
      margin-top: 9px;
      margin-bottom: 31px;
    }
    
    + .form-actions {
      margin-top: -12px;
    }
  } 
  
  
  .checkbox label {
    font-size: 13px;
    //.ellipsis;
  }
  
  &.fake-forms {
    
    padding-top: 10px;
    
    .fake-forms__radio {
      margin-bottom: 0;
    }
        
    .fake-forms__label_120 {
      margin-bottom: 10px !important;      
    }
    
    .fake-forms__label_200 {
      margin-bottom: 20px;
      margin-top: 15px;
    }
    
    .col-xs-12 .fake-forms__label_120 {
      margin-top: 10px;
      margin-bottom: 20px !important;
    }
  }
  
  .row .col-xs-5 .fake-forms__input {
    height: 40px;
    margin-top: 20px;
  }
}

.f-and-i-desk__fly-content {
  position: relative;
  
  .f-and-i-desk__indicia {
    margin-top: 20px;
  }

  .list-icons li {
    .mvscSpinner {
      position: absolute;
      left: 0;
      top: 2px;
    }
  }
}

// responsive
@media screen and (max-width: 1680px) {
  .responsive {

    .f-and-i-desk__get-deal-preview {
      width: 100%;

      .well, .list-unstyled {
        .clearfix();
      }

      .well.well-sm.well_noBg {

        .list-unstyled li {
          float: left;
          width: 50%;
        }

        .list-unstyled li:nth-child(even) {
          position: relative;
          right: -15px;
        }
      }

      .f-and-i-desk__indicia,
      .f-and-i-desk__transactions {
        position: relative;
        width: 25%;
        float: left;
        
        &.fake-forms {
          border-bottom: 1px solid @color-fake-forms-border;  
          border-right: none;
          border-left: none;
          margin: 0 0 -1px 0;
          
          &:after {
            content: '';
            position: absolute;
            top: 0;
            bottom: 0;
            display: block;
            width: 1px;
            background: @color-fake-forms-border;
            z-index: 1;
          }
        }
      }
      
      .f-and-i-desk__indicia {
        &.fake-forms {
          padding-right: 0;
          &:after {
            left: -1px;
          }
        }
      }
      
      .f-and-i-desk__transactions {
        
        padding-left: 30px;
        
        &.fake-forms {
          
          width: 75%;
          
          &:after {
            right: -1px;
          }
        }
      }
    }
    
    .f-and-i-desk__get-deal-preview-wrapper > .well:last-child {
      margin-bottom: 20px !important;
    }

    .f-and-i-desk__form {
      width: 85% !important;      
    }

    .f-and-i-desk__actions-col {
      width: 15% !important;
    }

    .f-and-i-desk__get-deal-preview  .form-actions {
      position: absolute;
      top: 42px;
      left: 50%;
      margin-left: 15px;
    }

    .f-and-i-desk__import-form .col-xs-6 {
      width: 25%;
      padding: 0 15px;
    }

    .f-and-i-desk__forms .col-xs-8 {
      width: 50%;
    }
  }
}

@media screen and (min-width: 1680px) {
  
  .f-and-i-desk__get-deal-preview {
    
    .row_tight {
      margin: 0 -5px;

      [class^="col-"] {
        padding: 0 5px;
      }

      .col-xs-8 {
        width: 75%;
      }

      .col-xs-4 {
        width: 25%;
        padding-right: 0;
      }
    }
  }
}

@media screen and (min-width: 1680px) and (max-width: 1919px) {
  .responsive {
    .pageWrapper,
    .container {
      max-width: 1633px !important;
    }
  }
}

@media screen and (min-width: 1920px) {
  
  .responsive {
      
    .f-and-i-desk__actions,
    .f-and-i-desk__transactions,
    .f-and-i-desk__indicia,
    .f-and-i-desk__actions .f-and-i-desk__actions__content,
    .f-and-i-desk__processing .cancel {
      display: none;
    }

    .f-and-i-desk__processing {
      display: block;
      
      .table.table_vertCentered .mvscSpinner {
        right: 0;
      }
    }

    .f-and-i-desk__actions-col {
      width: 18.3% !important;
    } 

    .f-and-i-desk__form {
      width: 60% !important;
    }

    .f-and-i-desk__get-deal-preview {
      width: 21.7% !important;
    }
  }
}

@media screen and (max-width: 1919px) {
  .responsive {
    
    .f-and-i-desk__transactions,
    .f-and-i-desk__indicia {
      display: block;
    }

    .f-and-i-desk__fly-content.fixed {
      z-index: 2;
    }

    .f-and-i-desk__processing_fixed {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      width: 388px;
      padding: 24px 28px;
      border: none;
      box-shadow: -2px 0px 8px 0px rgba(0, 0, 0, 0.15);
      background: #fff !important;
      
      .table_vertCentered td {
        padding: 10px 0;
      }
      
      .alert {
        margin-top: 15px;
      }

      .close {
        display: block;
      }
    }
  }
}