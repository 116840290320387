/* Widgets ---------------- */
// we use them on different types of dashboards
// e.g. retail dashboard, autoefile dashboard
.widget {
    margin-bottom: 20px;
    border: 1px solid @color-border_light;
    border-radius: @border-radius-base;
    background-color: #fff;


    // different sizes
    &.widget_xl {
        .scroller {
            height: 516px;
        }
    }

    &.widget_l {
        @totalHeight: 460px;
        @borderHeight: 2px;
        @headerHeight: 55px;

        .scroller {
            height: @totalHeight - @borderHeight - @headerHeight;
        }

        &.widget_noheader {
            .scroller {
                height: @totalHeight - @borderHeight;
            }
        }

        &.widget_noborder {
            .scroller {
                height: @totalHeight - @headerHeight;
            }
        }

        &.widget_noheader.widget_noborder {
            .scroller {
                height: @totalHeight;
            }
        }
    }

    &.widget_m {
        @totalHeight: 220px;
        @borderHeight: 2px;
        @headerHeight: 49px;

        .scroller {
            height: @totalHeight - @borderHeight - @headerHeight;
        }

        &.widget_noheader {
            .scroller {
                height: @totalHeight - @borderHeight;
            }
        }

        &.widget_noborder {
            .scroller {
                height: @totalHeight - @headerHeight;
            }
        }

        &.widget_noheader.widget_noborder {
            .scroller {
                height: @totalHeight;
            }
        }

        &.widget_dashboard.widget_notifications {
            .widget-body {
                height: @totalHeight;
            }
        }
    }

    &.widget_l, &.widget_m {
        .emptyState {

            .emptyState-wrapper {
                padding: 0;
            }

            .fa {
                font-size: 40px;
            }

            p {
                margin-top: 9px;
                font-size: 18px;
                font-weight: 300;
            }
        }

        .emptyState_getStarted {
            background: none;
        }
    }

    &.widget_noborder {
        border: none;
    }

    // small modifications
    &.widget_noheader {
        .widget-header {
            display: none;
        }
    }

    // elements in context
    .slimScrollBar {
        right: 6px !important;
    }

    // need to delete after VITU-6554 is resolved
    .userNotifications {
        //padding: 0 20px;

        li {
            list-style: none;

            &:not(:last-child) {
                border-bottom: 1px solid @pagination-disabled-border;
            }
        }

        a {
            padding: 13px 110px 16px 32px !important;

            .fa {
                top: 15px !important;
                left: 8px !important;
            }

            small {
                position: absolute;
                right: 10px;
                top: 16px;
            }
        }
    }
    // need to delete after VITU-6554 is resolved
}

.widget-header {
    border-bottom: 1px solid @color-border_light;
    padding: 7px 10px 8px 10px;

    .btn-group {
        top: 8px;
    }

    .btn {
        padding-left: 10px;
        padding-right: 10px;
    }

    h5 {
        font-size: @font-size-base + 3;
    }

    h5 > .fa {
        margin-right: 8px;
    }
}

.widget_notifications {
    .widget-header {
        position: relative;
        height: 55px !important;

        &:after {
            position: absolute;
            left: 20px;
            right: 20px;
            bottom: 0;
            height: 1px;
            background-color: @color-border_light;
            content: '';
        }
    }

    .widget-body {
        padding-top: 0 !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .slimScrollBar {
        right: 1px !important;
    }
}

// widget notification v2 styles
.widget_notifications_v2 {

    .widget-header {
        position: relative;
        height: 55px !important;

        &:after {
            position: absolute;
            left: 20px;
            right: 20px;
            bottom: 0;
            height: 1px;
            background-color: @color-border_light;
            content: '';
        }
    }

    .widget-body {
        position: relative;
        padding-top: 0 !important;
        padding-left: 20px !important;
        padding-right: 20px !important;
    }

    .slimScrollBar {
        right: 1px !important;
    }

    .userNotifications_v2 {
        padding-top: 0;
    }

    .widget-header {
        a {
            padding-top: 5px;
            color: #666;
        }

        h5 {
            display: inline-block;
        }
    }
    .userNotifications_v2__empty-state {
        height: 405px !important;
    }

    .userNotifications_v2 .scroller {
        max-height: 405px;
    }
}
// /widget notification v2 styles

.widget-body {
    .scroller {
        padding-right: 0;
    }

    .carousel-caption {
        .ellipsis;
    }
}


// specific widgets
.widget_history {
    .subContent {
        margin-bottom: 0;
        max-height: none;

        .table_vertCentered tr:first-child td {
            padding-top: 20px;
        }
    }
}

.widget_dashboard {
    .widget-header {
        height: 52px;
        padding: 20px 30px 0 30px;
        border-bottom: 0;

        h5 {
            margin: 6px 0 0 0;
            color: @gray;
        }
    }

    &.widget_m {
        .widget-body {
            height: 168px;

            .userNotifications_v2__empty-state {
                height: 160px !important;

                .fa.fa-bell {
                    font-size: 50px;
                    margin-bottom: 14px;
                }
            }
            .list-unstyled {
                margin-bottom: 20px;
            }
        }
    }

    .widget-body {
        padding: 16px 30px 0 30px;
    }

    .widget_dashboard-viewAll {
        .fa {
            margin-left: 7px;
            margin-top: 0;
            margin-right: 0;

            position: relative;
            top: 1px;
        }
    }

    .list-tiles {
        margin-bottom: 17px;
    }

    .emptyState_getStarted {
        margin-top: 18px;
    }
}

.widget_announcements {
    position: relative;
    overflow: hidden;

    .widget-header {
        padding-left: 20px;
        padding-right: 15px;
        float: left;
        border-bottom: none;

        h5 {
            font-size: @font-size-base + 1;
            font-weight: 500;
        }
    }

    .widget-body {
        overflow: hidden;
    }

    .carousel {
        padding-right: 79px;
        height: 50px;

        .item {
            height: 50px;
        }

        .carousel-caption {
            position: static;
            padding-top: 16px;
            padding-bottom: 0;
            text-align: left;

            color: @color-base;
            .text-shadow(none);
        }

        .carousel-control {
            width: auto;
            opacity: 1;
            font-size: inherit;
            background: none;
            color: @pre-border-color;
            .text-shadow(none);
            top: 16px;
            bottom: auto;
            left: auto;

            &.left {
                right: 44px;
            }

            &.right {
                right: 17px;
            }

            &:hover {
                color: @color-link;
            }
        }
    }

    .carousel, .carousel-inner {
        outline: none;
    }
    .carousel-item {
        position: relative;
        display: none;
        transition: 0.6s ease-in-out left;
        &.active {
            left: 0;
            display: block;
            &.left {
                left: -100%;
            }
            &.right {
                left: 100%;
            }
        }
    }
    .carousel-control-prev,
    .carousel-control-next {
        color: @pre-border-color;
        display: inline;
        background: transparent;
        border: none;
        padding: 0;
        position: absolute;
        outline: 0;
        top: calc(50% + 1px);
        right: 44px;
        transform: translateY(-50%);
        &::after {
            content: "\f060";
            font-family: "Font Awesome 5 Pro";
            font-size: 14px;
            font-weight: 900;
            transform: translateY(-50%);
        }
        span {
            display: none;
        }
        &:hover,
        &:focus {
            color: @color-link;
        }
    }
    .carousel-control-next {
        right: 17px;
        &::after {
            content: "\f061";
        }
    }
    .carousel-indicators {
        display: none;
    }
}

.widget_statistics-wrapper {

    // todo: go through the app and unify all .nav-tabs_mvsc + fullScreenModal-tabsm
    // make all the parameteres available using modificators, not context
    // now we have such tabs in admin + helpcenter + dashboard (maybe somewhere else)
    .nav-tabs_mvsc-wrapper {
        .tab-pane {
            padding-top: 0;
        }

        & > ul.nav-tabs {
            li {
                a {
                    border-top: 1px solid @color-border_light !important;
                    border-bottom: 1px solid @color-border_light !important;
                    border-left: 1px solid @color-border_light !important;
                    background-color: #fff;

                    &.active {
                        padding-top: 11px;
                        padding-bottom: 11px;
                        border-bottom: none !important;
                    }

                    &:last-child {
                        border-right: 1px solid @color-border_light !important;
                    }
                }

                &:first-child a {
                    border-left: none !important;

                    &.active::before {
                        left: 0;
                        width: calc(100% + 1px);
                    }
                }

                &.active {
                    a {
                        padding-top: 11px;
                        padding-bottom: 11px;

                        border-top: none !important;
                        border-bottom: none !important;
                    }
                }

                &.active:first-child {
                    a {
                        border-left: none !important;
                    }
                }

                &:last-child a {
                    border-right: 1px solid @color-border_light !important;
                }

                &:not(:first-child) {
                    // 4 + 1
                    margin-left: -1px;
                }
            }
        }
    }
}

.widget_statistics {
    border-radius: 0 0 @border-radius-base @border-radius-base;

    .widget-body {
        padding: 20px 30px;
    }

    .widget_statistics-tilesWrapper {
        margin: 20px -30px -20px -30px;
        padding: 10px;
        border-top: 1px solid lighten(@table-border-color, 5%);
    }

    .list-tiles {
        margin-bottom: 0;
    }

    .emptyState_getStarted {
        padding-top: 127px;
        padding-bottom: 132px;

        background-position-y: 42px;
    }
}
