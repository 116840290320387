.mvscModal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    min-height: 252px;
    min-width: 1000px;
    opacity: 0;
    background-color: @pre-bg;
    transition: opacity 750ms ease-in-out;
    z-index: @zindex-modal-background;

    h1 {
        margin-top: 59px;
        margin-bottom: 59px;

        &.vertical-align_absolute {
            margin: 0;
            left: 20px;
            right: 20px;
        }
    }

    .mvscModal-header {
        border-bottom: 1px solid @color-border_light;

        .container {
            position: relative;
            height: 70px;
        }

        .input-group_search {
            .form-control-reset {
                right: 5px;
            }
        }

        .mvscModal-logo {
            position: absolute;
            top: 20px;
            left: 0;
            width: 59px;
            height: 26px;
            background: url('@{assets-path}img/vitu-logo.svg') no-repeat;
        }

        .close {
            .vertical-align;
            position: absolute;
            right: 0;
            font-size: @fontSize-base + 24;
            font-weight: 300;
        }
    }

    .mvscModal-body {
        height: 100%;
        margin-top: -71px;
        padding-top: 71px;
    }

    .mvscModal-body-scroll {
        height: 100%;
        overflow: auto;

        & > .container {
            position: relative;
            height: auto;
            min-height: 100%;
            border-left: 1px solid @color-border_light;
            border-right: 1px solid @color-border_light;
            background: #fff;
        }
    }

    .nav.nav-tabs {
        margin: 0 -20px;
        padding: 0 20px;
        padding-top: 3px;
        text-align: center;
    }

    &.mvscModal_open {
        display: block;
    }

    &.mvscModal_shown {
        opacity: 1;
    }

    &.mvscModal_overModal {
        z-index: @zindex-modal-background + 1;
    }
}

@media screen and (min-width: 1600px) {
    .mvscModal .container {
        max-width: 1600px;
    }
}


/*

.mvscModal_noHeader {
  .mvscModal-body {
    margin-top: 0;
    padding-top: 0;
  }
}
*/

ngb-modal-window.mvscModal {
    height: 100%;
    z-index: 1055 !important;

    .modal-dialog {
        max-width: none;
        height: 100%;
        margin: 0;

        .modal-content {
            height: 100%;
        }
    }
}

ngb-modal-window {
    &.fullScreenModal {
        overflow: hidden;
    }

    &.modal_centered.show {
        display: grid !important;
        place-items: center;
        .modal-dialog {
            transition: none !important;
        }
    }
}
