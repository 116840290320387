// used in infobar -- shaking fiedset
@keyframes shake2 {
  0% {
    transform:  translate(0px,0px)  ;
  }
  10% {
    transform:  translate(-3px,0px)  ;
  }
  20% {
    transform:  translate(3px,0px)  ;
  }
  30% {
    transform:  translate(-3px,0px)  ;
  }
  40% {
    transform:  translate(3px,0px)  ;
  }
  50% {
    transform:  translate(-3px,0px)  ;
  }
  60% {
    transform:  translate(0px,0px)  ;
  }
}

#shake {
  @keyframes shake { 0%{#0100} 10%{#odd} 20%{#even} 30%{#odd} 40%{#even} 50%{#odd} 60%{#even} 70%{#odd} 80%{#even} 90%{#odd} 100%{#0100} }

  #0100() {
    transform: translate3d(0, 0, 0);
  }
  
  #odd() {
    transform: translate3d(-10px, 0, 0);
  };
  
  #even() {
    transform: translate3d(10px, 0, 0);
  };
}

#fadeIn {
  @keyframes fadeIn { 0%{#0} 100%{#100} }

  #0() { opacity: 0;}
  #100() { opacity: 1;};
}

#fadeInDown {
  @keyframes fadeInDown { 0%{#0} 100%{#100} }

  #0() {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }
  #100() {
    opacity: 1;
    transform: none;
  };
}

#fadeOut {
   @keyframes fadeOut { 0%{#0} 100%{#100} }

   #0() { opacity: 1; }
   #100() { opacity: 0; };
}

#fadeOutLeft {
  @keyframes fadeOutLeft { 0%{#0} 100%{#100} }

  #0() { opacity: 1; }
  #100() { opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }
}

#makeGreen {
  @keyframes makeGreen { 0%{#0} 5%{#10} 74%{#89} 75%{#90} 100%{#100} }

  #0() {
    color: @color-status-success;
  }
  
  #10() {
    color: @color-status-success;
    border-color: @color-status-success;
    box-shadow: 0 0 10px 0 fadeout(@color-status-success, 75%);
  }
  
  #89() {
    color: @color-status-success;
    border-color: @color-status-success;
    box-shadow: 0 0 10px 0 fadeout(@color-status-success, 75%);
  }
  
  #90() {
    color: @color-status-success;
  }
  
  #100() {
    
  }
}


#makeYellow {
  @keyframes makeYellow { 0%{#1} /*5%{#10}*/ 74%{#2} 75%{#3} 100%{#4} }

  /*#0() {
    color: @color-status-warning;
  }*/
  
  #1() {
    border-color: @color-status-warning;
    box-shadow: 0 0 10px 0 fadeout(@color-status-warning, 75%);
  }
  
  #2() {
    border-color: @color-status-warning;
    box-shadow: 0 0 10px 0 fadeout(@color-status-warning, 75%);
  }
  
  #3() {
  }
  
  #4() {
    
  }
}


#pulse {
  @keyframes pulse { 0%{#0} 50%{#50} 100%{#100} }

  #0() {
    opacity: 0;
    transform: scale(.75);
  }
  #50() {
    opacity: .8;
    transform: scale(1.02);
  }
  #100() {
    opacity: 1;
    transform: scale(1);
  }
}

#pulse_withoutFadeIn {
  @keyframes pulse_withoutFadeIn { 0%{#0} 50%{#50} 100%{#100} }

  #0() {
    transform: scale(.75);
  }
  #50() {
    transform: scale(1.03);
  }
  #100() {
    transform: scale(1);
  }
}


#zoomInDown {
  @keyframes zoomInDown { 0%{#0} 60%{#60} }
  
  #0() {
    opacity: 0;
    transform: scale3d(.1, .1, .1) translate3d(0, -1000px, 0);
    animation-timing-function: cubic-bezier(0.550, 0.055, 0.675, 0.190);
  }

  #60() {
    opacity: 1;
    transform: scale3d(.475, .475, .475) translate3d(0, 60px, 0);
    animation-timing-function: cubic-bezier(0.175, 0.885, 0.320, 1);
  }
}


#zoomOutRight {
  @keyframes zoomOutRight { 50%{#50} 100%{#100} }

  #50() {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    opacity: 1;
    transform: scale3d(.475, .475, .475) translate3d(-42px, 0, 0);
  }

  #100() {
    opacity: 0;
    transform: scale(.1) translate3d(500px, 0, 0);
    transform-origin: right center;
  }
}


#zoomOut {
  @keyframes zoomOut { 0%{#0} 50%{#50} 100%{#100} }

  #0() {
    opacity: 1;
  }

  #50() {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }

  #100() {
    opacity: 0;
  }
}


#spin {
  @keyframes spin { 0%{#0} 100%{#100} }

  #0() {
    transform: rotate(0deg);
  }

  #100() {
    transform: rotate(359deg);
  }
}

#rotateIn {
  @keyframes rotateIn { 0%{#0} 100%{#100} }

  #0() {
    transform: rotate(0deg);
  }

  #100() {
    transform: rotate(90deg);
  }
}

#rotateOut {
  @keyframes rotateOut { 0%{#0} 100%{#100} }

  #0() {
    transform: rotate(0deg);
  }

  #100() {
    transform: rotate(-360deg);
  }
}

#spinnerRotate {
  @keyframes spinnerRotate { 100% {transform:rotate(360deg)} }
}

#checkboxRadioAnimation {
  @keyframes checkboxRadioAnimation { 0%{#0} 100%{#100} }
  
  #0() {
    transform: scale(0.2);
  }
  #100() {
    transform: scale(1);
  }
}

@keyframes climb_1 {
  0%   {transform: translateY(15px); opacity: 0;}
  100% {transform: translateY(0); opacity: 1; transition-timing-function: ease-out;}
}

@keyframes climb_2 {
  0%   {transform: translateY(5px); opacity: 0;}
  25%  {transform: translateY(5px); opacity: 0;}
  100% {transform: translateY(0); opacity: 1; transition-timing-function: ease-out;}
}

@keyframes downhill_1 {
  0%   {transform: translateY(-15px); opacity: 0;}
  100% {transform: translateY(0); opacity: 1; transition-timing-function: ease-out;}
}

@keyframes envelop-text-fade {
  0%   {opacity: 0;}
  50%  {opacity: 0;}
  100% {opacity: 1;}
}

@keyframes envelop-check-background {
  99% {
    background: none;
  }
  100% {
    background: @color-status-success;
  }
}

@keyframes envelop-check-border{
  0% {
    //opacity: 0;
    box-shadow: 75px -75px 0 2px @white, -75px -75px 0 2px @white, -75px 75px 0 2px @white, 75px 75px 0 2px @white, 0 0 0 2px @color-status-success;
  }
  25% {
    box-shadow: 0 -155px 0 2px @white, -75px -75px 0 2px @white, -75px 75px 0 2px @white, 75px 75px 0 2px @white, 0 0 0 2px @color-status-success;
  }
  50% {
    box-shadow: 0 -155px 0 2px @white, -155px 0px 0 2px @white, -75px 75px 0 2px @white, 75px 75px 0 2px @white, 0 0 0 2px @color-status-success;
  }
  75% {
    box-shadow: 0 -155px 0 2px @white, -155px 0px 0 2px @white, 0px 155px 0 2px @white, 75px 75px 0 2px @white, 0 0 0 2px @color-status-success;
  }
  99% {
    //opacity: 1;
    box-shadow: 0 -155px 0 2px @white, -155px 0px 0 2px @white, 0px 155px 0 2px @white, 150px 40px 0 2px @white, 0 0 0 2px @color-status-success;
  }
  100% {
    box-shadow: 0 -155px 0 2px @white, -155px 0px 0 2px @white, 0px 155px 0 2px @white, 150px 40px 0 2px @white, 0 0 0 2px @color-status-success;
   visibility: hidden;
  } 
}

@keyframes envelop-check-circle {

  40% {
    top: 3px;
    left: 3px;
    width: 150px;
    height: 150px;
  }
  
  100% {
    top: 77px;
    left: 77px;
    width: 0;
    height: 0;
  }
}

@keyframes envelop-check-line1 {
  90% {
    top: 70px;
    left: 34px;
    height: 0;
  }
  100% {
    top: 63px;
    left: 50px;
    height: 47px;
  }
}

@keyframes envelop-check-line2 {
  0% {
    width: 0;
  }
  80% {
    width: 0;
  }
  100% {
    width: 75px;
  }
}

@keyframes options-appear {
  from { height: 0; padding-top: 0; padding-bottom: 0; margin-bottom: 0;}
  to { height: 42px; padding-top: 9px; padding-bottom: 4px; margin-bottom: 10px;}
}
    
@keyframes options-disappear {
  from { height: 42px; padding-top: 9px; padding-bottom: 4px; margin-bottom: 10px;}
  to { height: 0; padding-top: 0; padding-bottom: 0; margin-bottom: 0;}
}



@keyframes f-and-i-loading {
  0% {
    background: @color-fake-forms-default;
  }
  50% {
    background: @color-fake-forms-importing;
  }
  
  99% {
    background: @color-fake-forms-default;
  }
}