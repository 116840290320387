.helpCenter {

  h1 {
    margin-bottom: 24px;
    color: @black;
  }

  .mvscModal-body {
    .container {
      padding-top: 41px;
      //padding-bottom: 50px;
      background: @white;
    }
  }

  .helpCenter-header {
    padding-top: 36px;
    clear-link .form-control-reset .fa {
      font-size: 1.08em;
    }
    .input-group_search.hasReset .form-control-reset {
      top: 3px;
      right: 6px !important;
    }
  }

  .input-group_search {
    margin: 0 95px 35px 95px;
    display: block;
    height: 42px;
  }

  .table.table_vertSpaced td a.table-clickable__entityLink {
    word-break: break-word;
  }
}

.helpCenter-knowledgeBase {
  padding-top: 20px !important;


  .tile {
    margin: 0 -5px 20px -5px;
  }


  > .row > [class^="col"]:first-child .tile {
    margin-left: 0;
  }

  > .row > [class^="col"]:last-child:not(:first-child) .tile {
    margin-right: 0;
  }


}

.helpCenter-knowledgeBase-navCol {
  border-right: 1px solid @color-border_light;

  &.col-xs-3 {
    padding: 0;
  }

  > .nav-pills {
    margin: 20px -1px 26px 15px;

    li {
      margin: 0;
    }
  }
}

.helpCenter-knowledgeBase-contentCol {

  &.col-xs-9 {
    padding-left: 40px;
  }

  h3 {
    margin-bottom: 23px;
  }

  .helpCenter-knowledgeBase-articlePreview {
    margin-bottom: 33px;
  }
}


// helpCenter-tickets
.helpCenter-tickets-add {
  margin-bottom: 10px;
}

.helpCenter-stickyFooter {
  overflow: hidden;
  margin: 0 -20px;
  border-top: 1px solid @color-border_light;

  h3 {
    margin: 35px 0 90px 0;
  }
}

.helpCenter-knowledgeBase-faq {

  h2 {
    margin-bottom: 21px;
  }
}

.helpCenter-knowledgeBase-faq-section {
  margin-bottom: 50px;
}

.helpCenter-knowledgeBase-faq-item {
  margin-bottom: -1px;
  padding: 16px 0 18px 0;
  border-top: 1px solid @color-border_light;
  border-bottom: 1px solid @color-border_light;

  > a {
    position: relative;
    margin-bottom: 13px;
    padding-right: 35px;
    display: block;
    font-size: @fontSize-base + 5;
    text-decoration: none;
    transition: margin .3s;

    &:not(.collapsed) {
      color: @color-base;
    }

    &.collapsed {
      margin-bottom: 0;
    }
  }

  > a:after {
    position: absolute;
    right: 0;
    top: -2px;
    content: '\f106';
    font-family: "Font Awesome 5 Pro";
    font-size: @fontSize-base + 7;
    color: @color-link;
  }

  .collapsed:after {
    content: '\f107';
  }
}

.helpCenter-searchResults {
  display: none;
  margin: 0 95px 77px 95px;

  .nav-pills_categories {
    margin-top: -15px;
    margin-bottom: 20px;
  }
}

.helpCenter-searchResult-item {
  position: relative;
  margin-bottom: -1px;
  padding: 18px 20px 18px 40px;
  border-top: 1px solid @color-border_light;
  border-bottom: 1px solid @color-border_light;

  h4 {
    margin-bottom: 7px;
    font-size: @fontSize-base + 2;
    font-weight: normal;
  }
}

&.helpCenter-searchResult-item_selected {
  &:after {
    content: '\f061';
    position: absolute;
    top: 26px;
    left: 18px;
    font-size: @fontSize-base;
    font-family: "Font Awesome 5 Pro";
    .theme("default", {
      color: @themeBrand-color-corp-skyBlue;
    });
    .theme("black", {
      color: @themeBlack-color-corp-skyBlue;
    });
    .theme("classic", {
      color: @themeClassic-color-corp-skyBlue;
    });
  }

  h4 a {
    .helper-link-hover();
  }
}

.helpCenter-tickets_ticket {
  padding-left: 84px;
  padding-right: 84px;

  .backLink {
    margin-left: 2.05em;
    margin-bottom: 7px;
  }

  .comment-addCommentForm {
    padding-top: 30px;
    border-top: 1px solid @table-border-color;
  }
}

.helpCenter-tickets_submitTicket {
  padding-top: 0;
  padding-left: 114px;
  padding-right: 114px;

  .backLink {
    margin-left: 1.25em;
    margin-bottom: 23px;
  }
}

.helpCenter-tickets-submitTicketForm {
  padding-bottom: 25px;
  .form-control.ng-select .ng-select-container .ng-arrow-wrapper {
    margin-left: 4px;
  }
}

.helpCenter-showSearchResults {
  .list-favorites,
  .nav-tabs_mvsc-wrapper,
  .helpCenter-content,
  .helpCenter-stickyFooter {
    display: none;
  }

  .helpCenter-searchResults {
    display: block;
  }
}

.helpCenter-resources-selectState {
  margin: 20px 0;
}
